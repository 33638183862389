import React from 'react';
import { Link } from 'react-router-dom';

import { ForgeDivider, ForgeExpansionPanel, ForgeIcon, ForgeList, ForgeListItem, ForgeOpenIcon } from '@tylertech/forge-react';

import { handleSideNavLinkClick } from '../utility/Util';

const ServiceLink = ({ link, activeSideNavbarLabel, closeDrawer, serviceName }) => (
  <Link
    to={`/msp-admin/services/${serviceName}/${link.path}`}
    id="top"
    onClick={() => handleSideNavLinkClick(closeDrawer)}
  >
    <ForgeListItem active={link.label === activeSideNavbarLabel} selected={link.label === activeSideNavbarLabel}>
      {
        link.icon && <ForgeIcon name={link.icon} slot="leading"></ForgeIcon>
      }
      {`${link.label}`}
    </ForgeListItem>
  </Link>
)

const SideNavbarLink = ({
  sideNavbarLink,
  activeSideNavbarLabel,
  isLastItem,
  closeDrawer,
  serviceName
}) => {
  return (
    <>
      {
        sideNavbarLink.sublinks ? (<>
          <ForgeExpansionPanel open={sideNavbarLink.sublinks.find(x => x.label === activeSideNavbarLabel)}>
            <ForgeListItem slot="header">
              <span>{sideNavbarLink.label}</span>
              <ForgeOpenIcon slot="trailing" />
            </ForgeListItem>
            <ForgeList>
              {sideNavbarLink.sublinks.map((sublink) => (
                <ServiceLink key={sublink.label} link={sublink} activeSideNavbarLabel={activeSideNavbarLabel} closeDrawer={closeDrawer} serviceName={serviceName} />
              ))}
            </ForgeList>
          </ForgeExpansionPanel>
          {!isLastItem && <ForgeDivider />}
        </>) : (<ServiceLink link={sideNavbarLink} activeSideNavbarLabel={activeSideNavbarLabel} closeDrawer={closeDrawer} serviceName={serviceName} />)
      }
    </>
  );
};

export default SideNavbarLink;
