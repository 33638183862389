import {Buffer} from 'buffer';
/* eslint-disable no-undef */

export const getUserContext = () => {
  const userContext = localStorage.getItem('userContext');
  if (userContext) {
    const buff = Buffer.from(userContext, 'base64');
    return JSON.parse(buff);
  }
};

export const setUserContext = (userContext) => {
  localStorage.setItem('userContext', Buffer.from(JSON.stringify(userContext)).toString('base64'));
};

export const getBase64EncodedUserContext = () => {
  return localStorage.getItem('userContext');
};
