import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';
import { Link, useOutletContext, useParams } from 'react-router-dom';

import {
    ForgeButton,
    ForgeDivider,
    ForgeIcon,
    ForgeList,
    ForgeListItem,
    ForgeCircularProgress,
    ForgeSkeleton,
    ForgeToolbar,
    ForgeTooltip
} from '@tylertech/forge-react';

import ConsolePageLayout from '../ConsolePageLayout';
import ProductButton from '../ProductButton';
import { ProductsConfig } from '../../../config/ProductsConfig';
import { ProductTagBadgeLinks } from '../ProductTags';

const LoadingProductHeader = () => (
    <div className="nic-console-product-loading-header" slot="body-header">
        <ForgeSkeleton text style={{ "width": "15rem", "marginRight": "1rem" }} />
        <ForgeDivider vertical />
        <ForgeSkeleton chip style={{ "width": "6rem" }} />
    </div>
);

const ProductHeader = ({ product = {}, isLoading }) => (
    <>
        {
            isLoading ? (
                <LoadingProductHeader />
            ) : (
                <>
                    <ForgeToolbar slot="body-header" className="nic-console-service-header-pull">
                        <h1 slot="start" className="forge-typography--title nic-console-push-right">{product.name || "Product not found"}</h1>
                        {
                            product.tags && (
                                <>
                                    <ForgeDivider slot="start" vertical />
                                    <div slot="start" className="nic-console-product-tags-container nic-console-push-right">
                                        <ProductTagBadgeLinks tags={product.tags} />
                                    </div>
                                </>
                            )
                        }
                    </ForgeToolbar>
                </>
            )
        }
    </>
);

const SkeletonParagraph = () => [...Array(3)].map((x, i) => <ForgeSkeleton key={`product-loading-text-${i}`} text style={{ "width": "65%" }} />);

const ProductImage = ({ product }) => (
    <img src={`/images/products/${product.catalogProfile?.productLogo || "nic.png"}`} alt={`${product.name} logo`} />
)

const ProductPage = () => {

    const { setBreadcrumbs, setBodyTitle, setHideBackground, userFavoriteProducts } = useOutletContext();
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState();
    const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);

    useEffect(() => {
        const loadProductPage = async () => {
            setIsLoading(true);
            const filteredProduct = ProductsConfig.filter(x => x.id === productId).at(0);
            if (filteredProduct) {
                setProduct(filteredProduct);
            }

            setIsFavorite(userFavoriteProducts.find(x => x === filteredProduct.id) !== undefined);
            setIsLoading(false);
            setBreadcrumbs([
                { link: "/home", label: "Home" },
                { link: "/product-catalog", label: "Product Catalog" },
                { label: filteredProduct.name },
            ]);
            setBodyTitle(false);
            setHideBackground(false);
        }

        loadProductPage();
    }, [setBodyTitle, setBreadcrumbs, setHideBackground, productId, userFavoriteProducts]);

    const handleUpdateFavorite = async (e) => {
        e.preventDefault();
        setIsUpdatingFavorite(true);
        const isCurrentlyFavorite = isFavorite;
        try {
            Hub.dispatch('nic-console:user-favorites', {
                event: isFavorite ? 'deleteFavorite' : 'addFavorite',
                data: {
                    favorite: productId
                }
            })

            setIsFavorite(!isCurrentlyFavorite);
        } catch (error) {
            console.error("Error updating favorites", error);
            setIsFavorite(isCurrentlyFavorite);
        }
        setIsUpdatingFavorite(false);
    }


    return (
        <>
            <ProductHeader product={product} isLoading={isLoading} />
            <div className="nic-console-spacer"></div>
            <ConsolePageLayout>
                <div className="nic-console-product-page-container-right">
                    {
                        isLoading ? (
                            <>
                                <ForgeSkeleton avatar style={{ "width": "10rem", "height": "10rem" }} />
                                <ForgeSkeleton style={{ "width": "12rem", "height": "2rem" }} />
                                <ForgeSkeleton button />
                            </>
                        ) : (
                            <>
                                <ProductImage product={product} />
                                <h3 className="forge-typography--headline4">{product.name}</h3>
                                <ForgeButton type="flat">
                                    <button type="button" aria-label="Toggle favorite" disabled={isUpdatingFavorite} onClick={handleUpdateFavorite}>
                                        {
                                            isUpdatingFavorite ? (
                                                <>
                                                    <ForgeCircularProgress diameter="25" strokeWidth="8" slot="icon" style={{ "--forge-theme-tertiary": "#ba000d" }} />
                                                    <span className="nic-console-product-favorite">Updating</span>
                                                </>
                                            ) : (
                                                isFavorite ? (
                                                    <>
                                                        <ForgeIcon name="favorite" className="tyler-icons nic-console-product-favorite" slot="icon" aria-label="Service is favorited"></ForgeIcon>
                                                        <span className="nic-console-product-favorite">Favorite</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ForgeIcon name="favorite_border" className="tyler-icons nic-console-product-favorite" aria-label="Service is not favorited" slot="icon"></ForgeIcon>
                                                        <span className="nic-console-product-favorite">Add as Favorite</span>
                                                    </>
                                                )
                                            )
                                        }
                                    </button>
                                </ForgeButton>
                                <ForgeTooltip>
                                    <span>Add this product as a favorite, which adds the product to the sidebar</span>
                                </ForgeTooltip>
                            </>
                        )
                    }
                </div>
                <div className="nic-console-product-page-container-left">
                    <div>
                        <h3 className="forge-typography--headline5">Description</h3>
                        {
                            isLoading ? (
                                <SkeletonParagraph />
                            ) : (
                                <p className="forge-typography--body1">
                                    {product.catalogProfile?.description || "No description"}
                                </p>
                            )
                        }
                        <div className="nic-console-spacer"></div>
                    </div>
                    <div className="nic-console-product-page-support">
                        <h3 className="forge-typography--headline5">Support</h3>
                        {
                            isLoading ? (
                                <SkeletonParagraph />
                            ) : (
                                <div className="nic-console-product-page-support-items">
                                    {
                                        product.catalogProfile.slackChannel && (
                                            <ForgeButton type="outlined">
                                                <a target="_blank" rel="noopener noreferrer" href={`https://nic-inc.slack.com/archives/${product.catalogProfile.slackChannel.channelId}`}>
                                                    <button>
                                                        <ForgeIcon name="slack" slot="icon"></ForgeIcon>
                                                        Slack: #{product.catalogProfile.slackChannel.channel}
                                                    </button>
                                                </a>
                                            </ForgeButton>
                                        )
                                    }
                                    {
                                        product.catalogProfile.supportContact && (
                                            <>
                                                {
                                                    [].concat(product.catalogProfile.supportContact).map((x, i) => (
                                                        <ForgeButton type="outlined" key={`support-button-${i}`}>
                                                            <a href={`mailto:${x.email}`}>
                                                                <button>
                                                                    <ForgeIcon name="mail" className="tyler-icons" slot="icon"></ForgeIcon> Email
                                                                </button>
                                                            </a>
                                                        </ForgeButton>)
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            )
                        }
                        <div className="nic-console-spacer"></div>
                    </div>
                    <div>
                        <h3 className="forge-typography--headline5">Product Actions</h3>
                        {
                            isLoading ? (
                                <ForgeSkeleton button />
                            ) : (
                                <>
                                    {
                                        product.productType ? (
                                            <ProductButton product={product} buttonType="use" />
                                        ) : (
                                            <p className="forge-typography--body1">No product actions found</p>
                                        )
                                    }
                                </>
                            )
                        }
                        <div className="nic-console-spacer"></div>
                    </div>
                    <div>
                        <ForgeDivider />
                        <h3 className="forge-typography--headline5">Related Products</h3>
                        <ForgeList className="nic-console-related-products-list">
                            {
                                isLoading ? (
                                    [...Array(3)].map((x, i) => (
                                        <ForgeListItem key={`related-product-skeleton-${i}`}>
                                            <ForgeSkeleton text style={{ "width": "25%" }} />
                                        </ForgeListItem>
                                    ))
                                ) : (
                                    <>
                                        {
                                            [].concat(product.catalogProfile?.relatedProducts).length > 0 ? (
                                                product.catalogProfile.relatedProducts.map((x, i) => (
                                                    <ForgeListItem key={`related-product-link-${i}`}>
                                                        <Link to={`/product-catalog/${x.id}`}>
                                                            <span>{x.label}</span>
                                                        </Link>
                                                    </ForgeListItem>
                                                ))
                                            ) : (
                                                <p className="forge-typography--body1">No related products</p>
                                            )
                                        }
                                    </>
                                )
                            }
                        </ForgeList>
                    </div>
                </div>
            </ConsolePageLayout>
        </>
    )
};

export default ProductPage;
