/* eslint-disable no-undef */
/* eslint-disable react/display-name */
import { getUserContext, setUserContext } from '../../utility/LocalStorage';

const extractEmail = (authData) => {
  const { signInUserSession: session } = authData;
  const { idToken } = session;
  const { payload } = idToken;
  return payload.email;
}

export const getAccountContext = async (authData) => {
  /*
    If authData contains the attribute ['custom:mspTenantId'] returned from cognito,
    then user has logged in with account-service credentials
  */
  if (authData.attributes && authData.attributes['custom:mspTenantId']) {
    const tenantContext = {
      userContext: {
        tenantId: authData.attributes['custom:mspTenantId'],
        accountId: authData.attributes['custom:mspAccountId'],
        username: authData.username,
        email: authData.attributes['email'],
        isFederated: false,
      },
    };
    setUserContext(tenantContext.userContext);
    return tenantContext;
  } else {
    // User has logged in through NIC AD
    try {
      /*
          If localStorage contains federatedLogin context return it
          else initialize federatedLogin context and add to localStorage
        */
      let federatedLogin = getUserContext();
      if (!federatedLogin || !federatedLogin.isFederated) {
        const parsedEmail = extractEmail(authData);
        federatedLogin = {};
        federatedLogin['username'] = parsedEmail;
        federatedLogin['email'] = parsedEmail;
        federatedLogin['isFederated'] = true;
        setUserContext(federatedLogin);
      }

      return {
        userContext: federatedLogin,
      };
    } catch (error) {
      console.error(`Error retrieving tenant info of the User: ${error}`);
      throw error;
    }
  }
};
