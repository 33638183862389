import { Link } from "react-router-dom";

import { ForgeButton, ForgeAppBarProfileButton } from '@tylertech/forge-react';

const AppBarAuthButtons = ({ authState, userContext, signOut }) => (
    <>
        {
            authState === 'signedIn' ? (
                <ForgeAppBarProfileButton avatarText={userContext.email} email={userContext.email} on-forge-profile-card-sign-out={signOut} slot="end" />
            ) : (
                <ForgeButton slot="end">
                    <Link to="/home">
                        <button id="sign-in" type="button">Sign in</button>
                    </Link>
                </ForgeButton>
            )
        }
    </>
);

export default AppBarAuthButtons;
