import { Link } from "react-router-dom";

import { ForgeButton, ForgeIcon } from "@tylertech/forge-react";

const ProductButton = ({ product = {}, buttonType = "use" }) => (
    <>
        {
            (buttonType === "view") && <Link to={`/product-catalog/${product.id}`}>
                <ForgeButton type="outlined">
                    <button className="nic-console-product-learn-more-button">Learn More</button>
                </ForgeButton>
            </Link>
        }
        {
            (buttonType === "use" && <>
                {
                    product.productType === "internal" &&
                    <Link to={product.internalUrl}>
                        <ForgeButton type="raised">
                            <button className="nic-console-product-use-button" type="button">Use</button>
                        </ForgeButton>
                    </Link>
                }

                {
                    product.productType === "external" &&
                    <a target="_blank" rel="noopener noreferrer" href={product.externalUrl}>
                        <ForgeButton className="nic-console-product-use-button" type="raised">
                            <button type="button">Use <ForgeIcon name="arrow_top_right"></ForgeIcon></button>
                        </ForgeButton>
                    </a>
                }
            </>)
        }
    </>
)

export default ProductButton;
