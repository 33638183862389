import { DateTime } from 'luxon';

export const groupArray = (arrayToGroup = [], chunkSize = 3) => {
    return arrayToGroup.reduce((result, x, i) => {
        const chunkIndex = Math.floor(i / chunkSize);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(x)

        return result
    }, []);
};

export const handleSideNavLinkClick = (closeDrawer) => {
    const body = document.querySelector('.App');
    if (body) {
        body.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (window.innerWidth <= 1260) {
        closeDrawer();
    }
};

export const padGroup = (items, padSize = 3) => (
    [].concat(items).concat([...Array(padSize - items.length)].map(x => undefined))
)

export const formatLocalDateToUtc = (date, time = "") => {
    if (date && time) {
        const [datePart] = date.split("T");
        const dateTime = DateTime.fromFormat(`${datePart} ${time}`, 'yyyy-MM-dd hh:mm');
        return dateTime.toUTC().toISO();
      } else if (date) {
        const [datePart] = date.split("T");
        const dateTime = DateTime.fromFormat(`${datePart}}`, 'yyyy-MM-dd');
        return dateTime.toUTC().toISO();
      } else {
        return null;
      }
}

export const parseBadRequestViolations = (error, formatMessage) => {
    if(error.violations) {
        return error.violations.reduce(
            (accumulator, violation) => {
                if(formatMessage) {
                    accumulator[violation.propertyPath] = formatMessage(violation);                    
                } else {
                    accumulator[violation.propertyPath] = violation.message;
                }
                return accumulator;
        }, {})
    } else {
        return {};
    }
}

export const sleep = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

export const parseColor = (color, defaultColor = {rgba: {r: 0, g: 0, b: 0}}) => {
    const regex = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
    const match = regex.exec(color);
    if(match) {
      const [ , red, green, blue ] = match;
      return {
        hex: `${toHex(red)}${toHex(green)}${toHex(blue)}`,
        rgba: {
            r: red,
            g: green,
            b: blue
        }
      };
    } else {
      return defaultColor;
    }
  }

  const toHex = (value) => {
    const numericValue = Number.parseInt(value);
    const hexValue = numericValue.toString(16);
    return hexValue.length === 1 ? `0${hexValue}`: hexValue;
  }

  export const parseHexColor = (color, defaultColor = {rgba: {r: 0, g: 0, b: 0}}) => {
    const regex = /#([A-za-z0-9]{2})([A-za-z0-9]{2})([A-za-z0-9]{2})/;
    const match = regex.exec(color);
    if(match) {
      const [ , red, green, blue ] = match;
      return {
        hex: color.replace("#", ""),
        rgba: {
            r: parseInt(red, 16),
            g: parseInt(green, 16),
            b: parseInt(blue, 16)
        }
      };
    } else {
      return defaultColor;
    }
  }

  export const getNoneEmpty = (...values) => {
    for(let value of values) {
        if(value && value.length > 0) {
            return value;
        }
    }
    return "";
  }

  export const getValueOrDefault = (value, defaultValue = undefined) => {
    if(value && value.length > 0) {
        return value;
    } else {
        return defaultValue;
    }
  }