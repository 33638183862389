import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';

import { ForgeButton, ForgeDivider, ForgePageState, ForgeSkeleton } from '@tylertech/forge-react';

import { Articles } from '../content/Articles';
import ConsolePageLayout from '../ConsolePageLayout';
import MarkdownContent from '../MarkdownContent';

const MarkdownSkeleton = () => (
    <div>
        {
            [...Array(3)].map((x, i) => (
                <div key={`markdown-skeleton-${i}`}>
                    <ForgeSkeleton text style={{ "width": "25%" }}></ForgeSkeleton>
                    <ForgeSkeleton text></ForgeSkeleton>
                    <ForgeSkeleton text></ForgeSkeleton>
                    <div className="nic-console-spacer"></div>
                </div>
            ))
        }
    </div>
)

const ViewArticle = () => {

    const { setBodyTitle, setHideBackground, setBreadcrumbs } = useOutletContext();
    const { articleId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [articleInfo, setArticleInfo] = useState({});
    const [markdownContent, setMarkdownContent] = useState(undefined);

    useEffect(() => {
        setBodyTitle("Console Articles");
        setBreadcrumbs(false);
        setHideBackground(false);

        const fetchArticle = async () => {
            setIsLoading(true);
            const fetchedArticle = Articles[articleId];
            if (fetchedArticle) {
                const markdownFile = require('../../nic-console/content/articles/' + fetchedArticle.markdownFile);
                await fetch(markdownFile)
                    .then(res => res.text())
                    .then(markdown => setMarkdownContent(markdown));
                setArticleInfo(fetchedArticle);
            }

            setIsLoading(false);
        }

        fetchArticle();

    }, [articleId, setBodyTitle, setBreadcrumbs, setHideBackground]);

    return (
        <ConsolePageLayout>
            <div className="nic-console-article-container">
                <div>
                    {
                        isLoading ? (
                            <div>
                                <ForgeSkeleton text style={{ "width": "25%" }}></ForgeSkeleton>
                                <ForgeSkeleton text style={{ "width": "25%" }}></ForgeSkeleton>
                                <ForgeSkeleton text style={{ "width": "25%" }}></ForgeSkeleton>
                            </div>
                        ) : (
                            <div>
                                {
                                    articleInfo && (
                                        <>
                                            <h1 className="forge-typography--headline5">{articleInfo.headline}</h1>
                                            {
                                                articleInfo.publishedDate && <p className="forge-typography-body2">Published: {articleInfo.publishedDate}</p>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                <div>
                    {
                        isLoading ? (
                            <MarkdownSkeleton />
                        ) : (
                            <>
                                {
                                    markdownContent ? (
                                        <>
                                            <ForgeDivider />
                                            <div className="nic-console-spacer"></div>
                                            <MarkdownContent markdown={markdownContent} />
                                        </>
                                    ) : (
                                        <ForgePageState>
                                            <img src="https://cdn.forge.tylertech.com/v1/images/spot-hero/404-error-spot-hero.svg" alt="Article not found" slot="graphic" />
                                            <div slot="title">No article found</div>
                                            <div slot="message">The article you are trying to access could not be found</div>
                                        </ForgePageState>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <ForgeDivider />
                <div className="nic-console-spacer"></div>
                <div className="nic-console-keep-right">
                    {
                        isLoading ? (
                            <ForgeSkeleton button style={{ "width": "15%" }} />
                        ) : (
                            <Link to="/home">
                                <ForgeButton type="outlined">
                                    <button>Back to Home</button>
                                </ForgeButton>
                            </Link>
                        )
                    }
                </div>
            </div>
        </ConsolePageLayout>
    );
}

export default ViewArticle;
