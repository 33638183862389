import React, { useState } from 'react';

import amplifyConfig from '../../../config/AmplifyConfig';
import LoadingButton from '../LoadingButton';

function OAuthButton() {
  const { oauth: oauthConfig, userPoolWebClientId } = amplifyConfig.Auth;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const routeChange = () => {
    // eslint-disable-next-line no-undef
    window.location.href = `https://${oauthConfig.domain}/oauth2/authorize?identity_provider=${oauthConfig.federationProviderName}&redirect_uri=${oauthConfig.redirectSignIn}&response_type=CODE&client_id=${userPoolWebClientId}&scope=email%20openid`;
  };
  const handleClick = () => {
    setIsSubmitting(true);
    routeChange();
  };

  return (
    <LoadingButton
      className="nic-console-login-button"
      onClick={handleClick}
      isLoading={isSubmitting}
      text="Login with Tyler ID"
      loadingText="Logging In..."
    />
  );
}
export default OAuthButton;
