import { Link } from 'react-router-dom';

import { ForgeButton, ForgeCard, ForgeScaffold, ForgeSkeleton, ForgeToolbar } from '@tylertech/forge-react';

import { padGroup } from "../utility/Util";

const ServiceCard = ({ service }) => (
    <div>
        {
            service && (
                <ForgeCard className="msp-admin-service-card">
                    <ForgeScaffold>
                        <ForgeToolbar slot="header">
                            <h3 slot="start" className="forge-typography--title">
                                {service.serviceLabel}
                            </h3>
                        </ForgeToolbar>
                        <p className="forge-typography--body2" tabIndex="0" slot="body">
                            {service.serviceDescription}
                        </p>
                        <ForgeToolbar slot="footer">
                            <Link to={service.serviceLandingPage} slot="end">
                                <ForgeButton type="raised" slot="end">
                                    <button type="button">Use</button>
                                </ForgeButton>
                            </Link>
                        </ForgeToolbar>
                    </ForgeScaffold>
                </ForgeCard>
            )
        }
    </div>
);

const ServiceCardGroup = ({ services = [] }) => {
    const updatedServicesList = padGroup(services);

    return (
        <>
            <div className="msp-admin-service-card-group">
                {
                    updatedServicesList.map((x, i) => (
                        <ServiceCard key={`msp-service-card-${i}`} service={x} />
                    ))
                }
            </div>
            <div className="msp-admin-spacer"></div>
        </>
    );
};

const ServiceCardGroupSkeleton = ({ numberInGroup: cardsInGroup = 3 }) => (
    <div className="msp-loading-service-group">
        {
            [...Array(cardsInGroup)].map((x, i) => (
                <ForgeCard key={`loading-service-card-${i}`}>
                    <ForgeSkeleton text style={{ "width": "20%" }}></ForgeSkeleton>
                    <ForgeSkeleton text style={{ "width": "80%" }}></ForgeSkeleton>
                    <ForgeSkeleton text style={{ "width": "80%" }}></ForgeSkeleton>
                    <div className="msp-admin-spacer"></div>
                        <ForgeSkeleton className="msp-admin-keep-right" button style={{ "margin": "0.5rem" }}></ForgeSkeleton>
                </ForgeCard>
            ))
        }
    </div>
);

export { ServiceCard, ServiceCardGroup, ServiceCardGroupSkeleton };
