import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ForgeChip, ForgeChipSet, ForgePageState } from '@tylertech/forge-react';

import ConsolePageLayout from '../ConsolePageLayout';
import { groupArray } from '../../utility/Util';
import { ProductCardGroup, ProductCardGroupSkeleton } from '../ProductCard';
import { ProductsConfig } from '../../../config/ProductsConfig';
import { productTags } from '../ProductTags';

const ProductCatalogPage = () => {

    const { setBodyTitle, setBreadcrumbs, setHideBackground } = useOutletContext();
    const [isLoading, setIsLoading] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedTag, setSelectedTag] = useState("all");
    const [searchParams] = useSearchParams();
    const [selectedTagDescription, setSelectedTagDescription] = useState();
    const [filterTagBadges, setFilterTagBadges] = useState([]);

    const productsByTag = useCallback(() => {
        return ProductsConfig.reduce((previous, current) => {
            [].concat(current.tags).forEach(x => {
                if (previous[x]) {
                    previous[x].push(current);
                } else {
                    previous[x] = [].concat(current);
                }
            });
            previous["all"].push(current);
            return previous;
        }, { all: [] })
    }, []);

    const filterProducts = useCallback((tag) => {
        let taggedProducts = productsByTag();
        if (tag) {
            let productTag = productTags.find(x => x.id === tag);
            if (productTag) {
                setSelectedTagDescription(productTag.description);
            }
        }

        return [].concat(taggedProducts[tag || "all"])
            .filter(x => x)
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0
                }
            });
    }, [productsByTag]);

    useEffect(() => {
        const filterOnTag = searchParams.get('tag');
        if (filterOnTag) {
            setSelectedTag(filterOnTag);
        }
        setFilterTagBadges([].concat(productTags).filter(x => x.id !== "all").map(x => {
            if (x.id === filterOnTag) {
                x.selected = true;
            }
            return x;
        }));

        const fetchProducts = () => {
            setIsLoading(true);
            setProducts(groupArray(filterProducts(filterOnTag)));
            setIsLoading(false);
        };

        setBodyTitle(false);
        setBreadcrumbs(false);
        setHideBackground(false);
        fetchProducts();

    }, [searchParams, filterProducts, setBodyTitle, setBreadcrumbs, setHideBackground]);

    const handleProductTagSelect = (event) => {
        setIsLoading(true);
        const tagFromEvent = event.detail.value;
        if (selectedTag !== tagFromEvent) {
            setSelectedTag(tagFromEvent);
            setProducts(groupArray(filterProducts(tagFromEvent)));
        } else {
            setProducts(groupArray(filterProducts().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0
                }
            })));
            setSelectedTagDescription();
            setSelectedTag("all");
        }
        setIsLoading(false);
        event.preventDefault();
    }

    return (
        <ConsolePageLayout>
            <>
                <div>
                    <h2 className="forge-typography--headline4">Product Catalog</h2>
                    <p className="forge-typography--subtitle1-secondary">Use the NIC Product Catalog to find products and to start building your solution.</p>
                    <div className="nic-console-product-catalog-filter">
                        <p className="forge-typography--subtitle2-secondary">Filter products by categories:</p>
                        <ForgeChipSet type="choice" on-forge-chip-select={handleProductTagSelect}>
                            {
                                filterTagBadges.map((x, i) => {
                                    if (x.id === selectedTag) {
                                        return <ForgeChip type="choice" key={`product-tag-chip-${i}`} value={x.id} selected={true} style={{ "textDecoration": "bold" }} options={{ "selected": true }}>{x.text}</ForgeChip>
                                    } else {
                                        return <ForgeChip type="choice" key={`product-tag-chip-${i}`} value={x.id} selected={false}>{x.text}</ForgeChip>
                                    }
                                })
                            }
                        </ForgeChipSet>
                        {
                            selectedTag !== "all" && (
                                <span className="forge-typography--subtitle1-secondary">
                                    {selectedTagDescription}
                                </span>
                            )
                        }
                    </div>
                </div>
                <>
                    {
                        isLoading ? (
                            <>
                                {
                                    [...Array(2)].map((x, i) => (
                                        <ProductCardGroupSkeleton cardsInGroup={3} key={`product-card-group-skeleton-${i}`} />
                                    ))
                                }
                            </>
                        ) : (
                            <>
                                {
                                    products.length > 0 ? (
                                        <>
                                            {
                                                products.map((x, i) => (
                                                    <ProductCardGroup key={`product-card-group-${i}`} products={x} />
                                                ))
                                            }
                                        </>
                                    ) : (
                                        <div className="nic-console-catalog-products-not-found">
                                            <ForgePageState>
                                                <img src="https://cdn.forge.tylertech.com/v1/images/spot-hero/coming-soon-spot-hero.svg" alt="Coming soon" slot="graphic" />
                                                <div slot="title">No products found</div>
                                                <div slot="message">No products have been added to the catalog for that category yet. Please check back!</div>
                                            </ForgePageState>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </>
            </>
        </ConsolePageLayout>
    )
};

export default ProductCatalogPage;
