import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useOutletContext, useParams } from 'react-router-dom';
import loadable from '@loadable/component'

import MspServicesConfig from '../../../../config/MspServicesConfig';
import MspPageLayout from '../../../msp-admin/MspPageLayout';
import NotFound from '../../../common/containers/NotFound';


const ServiceHome = ({ userContext }) => {
  const SideNavRouteComponent = loadable((x) => import(`./${x.name}/${x.link}`));

  const { setBodyTitle, setBreadcrumbs, setActiveSideNavbarLabel, setSideNavServiceConfig, setPageExists } = useOutletContext();

  const location = useLocation();
  const { serviceName } = useParams();
  const [serviceConfig, setServiceConfig] = useState();
  const [serviceRoutes, setServiceRoutes] = useState();

  useEffect(() => {
    const activePath = location.pathname.split(`${serviceName}/`).slice(-1)[0];
    const serviceConfiguration = MspServicesConfig.MspServicesConfig[serviceName] || {sideNavbarConfig: []};
    setServiceConfig(serviceConfiguration);
    setSideNavServiceConfig(serviceConfiguration);
    const serviceLinks = serviceConfiguration.sideNavbarConfig.reduce((previous, current) => {
      if (current.sublinks) {
        previous = previous.concat(current.sublinks.map(x => ({ label: x.label, link: x })))
      } else {
        previous = previous.concat({ label: current.label, link: current });
      }
      return previous;
    }, []);

    if(serviceConfiguration.links) {
      const links = serviceConfiguration.links.map(link => {
        return {
          label: link.label,
          sideNavbarLabel: link.sideNavbarLabel,
          ancestors: link.ancestors,
          link: link,
        };
      })
      serviceLinks.push(...links);
    }

    const activeServiceLink = serviceLinks.find(x => {
      const regex = x.link.path.replace(/:[a-zA-Z]+/, ".+");
      return activePath.match(`^${regex}$`) !== null;
    });
    if(activeServiceLink) {
      setActiveSideNavbarLabel(activeServiceLink.sideNavbarLabel || activeServiceLink.label);
      setServiceRoutes(serviceLinks.map(x => (<Route path={x.link.path} key={x.link.path} element={<SideNavRouteComponent key={x.link.fileName} name={serviceConfiguration.serviceName} link={x.link.fileName} userContext={userContext} />} />)))

      setBodyTitle(false);
      const breadcrumbs = [
        { link: "/msp-admin/home", label: "MSP Admin" },
        { link: serviceConfiguration.serviceLandingPage, label: serviceConfiguration.serviceLabel, },        
      ];
      if(activeServiceLink.ancestors) {
        const ancestors = activeServiceLink.ancestors.map(ancestor => {
          return {
            label: ancestor.label,
            link: ancestor.path
          }
        });
        breadcrumbs.push(...ancestors);
      }
      breadcrumbs.push({label: activeServiceLink.label});
      setBreadcrumbs(breadcrumbs);
    } else {
      setPageExists(false);
    }
  }, [location, serviceName, setActiveSideNavbarLabel, setBreadcrumbs, setBodyTitle, setSideNavServiceConfig, setPageExists, userContext]);


  return (
    <MspPageLayout>
      <Routes>
        {serviceConfig && serviceRoutes}
        <Route element={NotFound} />
      </Routes>
    </MspPageLayout>
  );
};

export default ServiceHome;
