import { ForgeCard, ForgePageState } from '@tylertech/forge-react';
import MspPageLayout from '../MspPageLayout';

const MspAccessRequiredPage = () => (
    <MspPageLayout>
        <ForgeCard>
            <ForgePageState>
                <img src="https://cdn.forge.tylertech.com/v1/images/spot/access-spot.svg" alt="Access required graphic" slot="graphic" />
                <div slot="title">MSP Access Required</div>
                <div slot="message">
                    <p>You must have an account setup in MSP Account Service in order to use MSP Admin. If you already have an account then <a href="mailto:msp@egov.com">contact us</a> so that update your access.</p>
                </div>
                <div slot="action"></div>
            </ForgePageState>
        </ForgeCard>
    </MspPageLayout>
);

export default MspAccessRequiredPage;
