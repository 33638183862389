export const ConsolePages = [
    {
        title: "Console Home",
        description: "Console Home Page",
        path: "/home"
    },
    {
        title: "Product Catalog",
        description: "Product catalog home page",
        path: "/product-catalog"
    },
];

export default ConsolePages;
