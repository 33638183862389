import { ForgeCard } from "@tylertech/forge-react";

const ConsolePageLayout = ({ children }) => (
    <div className="nic-console-page-container">
        <ForgeCard style={{ "--forge-card-body-overflow": "true" }}>
            {children}
        </ForgeCard>
    </div>
);

export default ConsolePageLayout;
