/* eslint-disable no-undef */
import { Auth } from 'aws-amplify';
import { getUserContext, getBase64EncodedUserContext } from '../components/utility/LocalStorage';

const addCustomAuthorizationHeader = async () => {
  try {
    const session = await Auth.currentSession();
    let authToken = session.getIdToken().getJwtToken();
    let userContext = getUserContext();
    if (userContext.isFederated) {
      authToken = `${authToken}.${getBase64EncodedUserContext()}`;
    }
    return { Authorization: authToken };
  } catch (error) {
    await Auth.signOut();
  }
};

const addAuthorizationHeader = async () => {
  try {
    const session = await Auth.currentSession();
    let authToken = session.getIdToken().getJwtToken();
    return { Authorization: authToken };
  } catch (err) {
    await Auth.signOut();
  }
};
const config = {
  local: {
    Auth: {
      mandatorySignIn: true,
      region: 'us-west-2',
      userPoolId: 'us-west-2_vVnpPlutn',
      userPoolWebClientId: '711go037k59jfj7lh3ijq1l0jo',
      oauth: {
        domain: 'msp-console-dev.auth.us-west-2.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code',
        federationProviderName: 'Tyler-Okta',
      },
    },
    API: {
      endpoints: [
        {
          name: 'api',
          endpoint: 'https://api-dev.egov-msp.com',
          custom_header: addCustomAuthorizationHeader,
        },
        {
          name: 'api_public',
          endpoint: 'https://api-dev.egov-msp.com',
        },
        {
          name: 'get_account_context',
          endpoint: 'https://api-dev.egov-msp.com',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'console_api',
          endpoint: 'https://api-dev.egov-msp.com/nic-console-api.v1',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'admin_api',
          endpoint: 'https://api-dev.egov-msp.com/msp-admin-api.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'tenant_usage',
          endpoint: 'https://api-dev.egov-msp.com/tenantusageplans.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'service_analytics',
          endpoint: 'https://api-dev.egov-msp.com/serviceanalytics.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'direct_download',
          endpoint: ''
        }
      ],
    },
  },
  dev: {
    Auth: {
      mandatorySignIn: true,
      region: 'us-west-2',
      userPoolId: 'us-west-2_vVnpPlutn',
      userPoolWebClientId: '711go037k59jfj7lh3ijq1l0jo',
      oauth: {
        domain: 'msp-console-dev.auth.us-west-2.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://console-dev.egov.com/',
        redirectSignOut: 'https://console-dev.egov.com/',
        responseType: 'code',
        federationProviderName: 'Tyler-Okta',
      },
    },
    API: {
      endpoints: [
        {
          name: 'api',
          endpoint: 'https://api-dev.egov-msp.com',
          custom_header: addCustomAuthorizationHeader,
        },
        {
          name: 'api_public',
          endpoint: 'https://api-dev.egov-msp.com',
        },
        {
          name: 'get_account_context',
          endpoint: 'https://api-dev.egov-msp.com',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'console_api',
          endpoint: 'https://api-dev.egov-msp.com/nic-console-api.v1',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'admin_api',
          endpoint: 'https://api-dev.egov-msp.com/msp-admin-api.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'tenant_usage',
          endpoint: 'https://api-dev.egov-msp.com/tenantusageplans.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'service_analytics',
          endpoint: 'https://api-dev.egov-msp.com/serviceanalytics.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'direct_download',
          endpoint: '',
        }
      ],
    },
  },
  prod: {
    Auth: {
      mandatorySignIn: true,
      region: 'us-west-2',
      userPoolId: 'us-west-2_28FXvAb6k',
      userPoolWebClientId: '342d6tgj2mi9cir5ghf2dbe73g',
      oauth: {
        domain: 'msp-console.auth.us-west-2.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://console.egov.com/',
        redirectSignOut: 'https://console.egov.com/',
        responseType: 'code',
        federationProviderName: 'Tyler-Okta',
      },
    },
    API: {
      endpoints: [
        {
          name: 'api',
          endpoint: 'https://api.egov-msp.com',
          custom_header: addCustomAuthorizationHeader,
        },
        {
          name: 'api_public',
          endpoint: 'https://api.egov-msp.com',
        },
        {
          name: 'get_account_context',
          endpoint: 'https://api.egov-msp.com',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'console_api',
          endpoint: 'https://api.egov-msp.com/nic-console-api.v1',
          custom_header: addAuthorizationHeader,
        },
        {
          name: 'admin_api',
          endpoint: 'https://api.egov-msp.com/msp-admin-api.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'tenant_usage',
          endpoint: 'https://api.egov-msp.com/tenantusageplans.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'service_analytics',
          endpoint: 'https://api.egov-msp.com/serviceanalytics.v1',
          custom_header: addCustomAuthorizationHeader
        },
        {
          name: 'direct_download',
          endpoint: ''
        }
      ],
    },
  },

};
export default process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'dev'
  ? config[process.env.REACT_APP_STAGE]
  : config['local'];
