import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ForgeAutocomplete, ForgeAppBarSearch } from '@tylertech/forge-react';

import { searchConsolePages } from './ConsoleSearchProvider';

const ConsoleSearch = () => {
    const navigate = useNavigate();
    const searchAutocompleteRef = useRef();
    const searchInputRef = useRef();

    const performSearch = (searchTerm, options = { grouped: true }) => {
        return searchConsolePages(searchTerm, options);
    };

    const onSearchFilter = (e) => {
        const searchTerm = e;
        let results = [];

        if (searchTerm && searchTerm.length > 1) {
            results = results.concat(performSearch(e));
        }

        return results;
    }

    const handleOnChange = (e) => {
        if (e.detail) {
            e.target.value = "";
            navigate(e.detail);
        }
    }

    const optionBuilder = (option, filterText, listItem) => {
        const wrapperDiv = document.createElement('div');
        const titleDiv = document.createElement('div');
        titleDiv.classList.add('forge-typography--body1');
        titleDiv.textContent = option.label?.slice(0, 50);
        wrapperDiv.appendChild(titleDiv);

        if (option.description) {
            listItem.twoLine = true;
            const descriptionDiv = document.createElement('div');
            descriptionDiv.classList.add("forge-typography--subtitle2");
            descriptionDiv.style.whiteSpace = "nowrap";
            descriptionDiv.style.textOverflow = "ellipsis";
            descriptionDiv.style.overflow = "hidden";
            descriptionDiv.textContent = option.description.slice(0, 40);
            wrapperDiv.appendChild(descriptionDiv);
        }

        return wrapperDiv;
    };

    useEffect(() => {
        const onKeyDown = (e) => {
            const searchTerm = e.target.value;
            if (!searchTerm) {
                return;
            }

            if (e.key === 'Enter' && searchTerm.length > 0) {
                searchAutocompleteRef.current.closeDropdown();
                const searchResult = performSearch(searchTerm, { grouped: false });
                const state = {
                    searchResults: searchResult,
                    searchText: searchTerm
                };
                navigate("/search-results", { state })
            }
        };
        const searchInput = searchInputRef.current;
        searchInput.addEventListener("keydown", onKeyDown)
        return () => {
            searchInput.removeEventListener("keydown", onKeyDown);
        }
    }, [navigate, searchInputRef])

    return (<>
        <ForgeAutocomplete stateless={true} slot="center" ref={searchAutocompleteRef} optionBuilder={optionBuilder} style={{ "width": "100%" }} debounce={0} filter={onSearchFilter} filterOnFocus={false} highlightFirst={false} popupTarget="forge-app-bar-search" on-forge-autocomplete-change={handleOnChange}>
            <ForgeAppBarSearch global={false}>
                <input id="nic-console-search-input" type="text" ref={searchInputRef} aria-label="Search the console" placeholder="Search..."></input>
            </ForgeAppBarSearch>
        </ForgeAutocomplete>
    </>);
}

export default ConsoleSearch;
