import { ForgeToolbar, ForgeIconButton } from '@tylertech/forge-react';

const TermsOfUse = ({ showHeader = true, bodyClassName = "nic-console-terms-of-use-body", onDismiss }) => {
  return (
    <>
      {
        showHeader && (
          <ForgeToolbar>
            <h2 slot="start">NIC Sites Terms of Use</h2>
            <ForgeIconButton slot="end">
              <button type="button" className="tyler-icons" onClick={onDismiss}>close</button>
            </ForgeIconButton>
          </ForgeToolbar>
        )
      }

      <div className={bodyClassName}>
        <section className="forge-typography--body1">
          <h3>
            Last Updated: September 23, 2020
          </h3>
          <p>
            These Terms of Use (“Terms of Use”) govern your access to and use of services provided
            by NIC, Inc., and its affiliates (together, “NIC,” “we,” “us,” or “our”) on:
          </p>
          <ul>
            <li>NIC applications and websites</li>
            <li>The Gov2Go mobile application, and</li>
            <li>The Gov2Go website located at <a rel="noreferrer" target="_blank" href="https://www.getgov2go.com">www.getgov2go.com</a></li>
          </ul>
          <p>
            We refer to these as the "Services." We provide our apps to you on the Google Play Store,
            the Apple App Store, or through our and our partners' websites. These Terms of Use are a
            legal agreement between you and NIC. Additional or separate terms may apply to your use of
            other NIC applications, websites, or services. To the extent that the provisions of any
            additional terms conflict with these Terms of Use, the provisions of the additional terms
            will govern.
          </p>
          <p>
            THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION WHICH MAY BE ENFORCED BY THE PARTIES AND A WAIVER OF YOUR CLASS ACTION RIGHTS.
          </p>
          <p>
            By using, downloading or accessing the Services, you acknowledge that you: (i) have read and understood these Terms of Use; (ii) are at least eighteen (18) years old and have the legal authority to accept the Terms of Use; and (iii) agree to be legally bound by the Terms of Use. If you do not agree to these Terms of Use, please do not download, access, or use the Services. You should review these Terms of Use periodically as they may be revised from time to time by NIC, and your use afterwards signifies your acceptance of the changed terms.
            NIC may make changes to these Terms of Use from time to time. When these changes are made, NIC will make a new copy of the Terms of Use available on the NIC websites and any new additional terms will be made available to you from within, or through, the affected Services or with the contact information you have provided to NIC. You understand that you have the affirmative obligation to check the Terms of Use periodically for changes, and you hereby agree to periodically review the Terms of Use for such changes. You acknowledge and agree that if you use the Services after the date on which the Terms of Use have changed, NIC will treat your continued use as acceptance of the updated Terms of Use.
          </p>
          <ul>
            <li>
              <div><strong>GENERAL TERMS OF USE AND RESTRICTIONS OF USE</strong></div>
              <ol>
                <li>
                  <strong>Access</strong>. Subject to these Terms of Use, NIC grants you a limited, revocable,
                  nonexclusive, nontransferable personal right to access and make use of the
                  Services solely for your benefit and for no other commercial purpose. If you do not comply with these Terms of Use, NIC reserves the right to revoke your right to use and access the Services or limit your use and access to the Services
                </li>
                <li>
                  <strong>Children</strong>. The Services are not aimed at nor intended for
                  children under the age of eighteen (18). If you are under the age of
                  eighteen (18), please DO NOT USE the Services. NIC does not accept
                  registration from children under the age of eighteen (18) and no one under
                  the age of eighteen (18) may provide any information to or through the Sites.
                </li>
                <li>
                  <strong>Copyrights and Trademarks</strong>. All content and materials on the
                  Services, such as text, graphics, logos, button icons, images, audio clips,
                  digital downloads, data compilations, and software, are the property of NIC or
                  its third party licensors and protected by United States and international
                  copyright laws. All trademarks, trade dress, service marks, and trade names are
                  proprietary to NIC or its third party licensors. The content and materials are
                  intended for your personal, noncommercial use. You may make one copy of the
                  materials displayed on the Services for your personal, noncommercial use only.
                  No right, title or interest in any content or materials is transferred to you as
                  a result of any such copying. You may not reproduce, republish, upload, post,
                  transmit, modify, create derivative works from, sell, or distribute in any way
                  the content and materials on the Services.
                </li>
                <li>
                  <strong>Restrictions on Use</strong>. You agree to use the Services only for
                  lawful purposes and to follow all applicable laws and regulations of the United
                  States and any state in which you reside that are in effect and that may be in effect
                  in the future when using the Services. While using the Services, you will not:
                  <ul style={{ listStyle: "square" }}>
                    <li>Violate any laws, regulations, third party rights (including intellectual property rights), or NIC's policies;</li>
                    <li>If an App or Website allows you to post or upload content, post or upload any content that is libelous, defamatory, harmful, harassing, threatening, false, misleading, or discriminatory;</li>
                    <li>If an App or Website allows you to post or upload content, post, use, or upload any content that misappropriates any trade secret or infringes any intellectual property rights, rights of publicity, rights of privacy, or proprietary right of any party;</li>
                    <li>Affect us adversely or reflect negatively on any App, Website, NIC, our goodwill, name, or reputation;</li>
                    <li>Impersonate anyone or any entity or falsely state or misrepresent your affiliation with a person or entity;</li>
                    <li>Display advertising or commercial content;</li>
                    <li>Collect or store personal data about other users;</li>
                    <li>Use any computer code, data mining software, "robot," "bot," "spider," "scraper" or other automatic device, or program, algorithm or methodology having similar processes or functionality, or any manual process, to monitor or copy any of the Web pages, data or content found Services or accessed through the Services;</li>
                    <li>Transmit any material that contains software viruses, trojan horses, worms, time bombs, cancelbots, or other malicious code;</li>
                    <li>Take any action to use the Services in any manner that violates or is inconsistent with these Terms of Use or to circumvent, compromise or defeat any security measures implemented in any Services, including to gain unauthorized access to: (i) our Services; (ii) other users' accounts, names, passwords, personally identifiable information; or (iii) other computers, websites, or pages connected or linked to our Services;</li>
                    <li>Display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer, or transmit for any commercial purposes, all or any portion of the Services or use in-line or other linking to display content from the Services without our permission; or</li>
                    <li>Modify, disrupt, impair, alter, or interfere with the use, features, functions, operation, or maintenance of the Services or the rights or use and enjoyment of the Services by any other person, firm, or enterprise (collectively, “Person”); or collect, obtain, compile, transmit, reproduce, delete, revise, or display any material or information, whether personally identifiable or not, posted by or concerning any other Person in connection with use of the Services, unless you have obtained the express, prior permission of such other Person.</li>
                  </ul>
                </li>
                <li>
                  <strong>Registration and Account Information</strong>. In some cases, you must
                  provide an email address to NIC, or register and create an account, in order to use
                  the Services. To register and create an account (“Registered Account”), you must
                  create login credentials (a username and password) and provide personal information,
                  including your email address and mobile device or telephone number. As a registered
                  user, you must maintain confidentiality of your login credentials and will remain
                  responsible for all activities that occur under your Registered Account. Should you
                  become aware of or suspect any unauthorized use of your Registered Account, you will
                  immediately notify NIC. You will ensure that you exit from your Registered Account at
                  the end of each session when accessing the Services. In connection with the foregoing
                  and in consideration of your use of the Services, you agree to: (a) provide true,
                  accurate, current, and complete registration and account information about yourself as
                  prompted and required by the Services; and (b) provide any updates to your registration
                  and account information to keep it true, accurate, current, and complete.
                </li>
                <li>
                  <strong>Accuracy of Information</strong>. While NIC strives to provide accurate
                  information, we cannot and expressly do not guarantee that materials and information,
                  available through the Services, will be accurate, timely, or complete. You agree that
                  NIC is not responsible for any consequences arising from your reliance on the accuracy
                  of any materials and information available through the Services.
                </li>
                <li>
                  <strong>Modifications to the Sites</strong>. NIC reserves the right at any time and
                  from time to time to modify or discontinue, temporarily or permanently, the Services
                  (including any Website, any App, the Gov2Go Website, the Gov2Go App, and any part
                  thereof) with or without notice. You acknowledge and agree that (i) modifications may
                  result in a delay in computations for some of the features or components of the
                  Services, and (ii) NIC shall not be liable to you or to any third party for any
                  modification, suspension, or discontinuance of the Services.
                </li>
                <li>
                  <strong>Violation or Suspected Violation of Terms of Use</strong>. If you violate the
                  terms of these Terms of Use or NIC has reasonable grounds to suspect that you have
                  violated the terms of these Terms of Use, NIC has the right to suspend or terminate
                  your use of and access to the Services, including your Registered Account, and the
                  right to refuse any and all current or future use of the Services (or any part of the
                  Services).
                </li>
              </ol>
            </li>
            <li>
              <div><strong>PRIVACY</strong></div>
              In some cases, when you use and access the Services, by creating a Registered Account or
              otherwise, you will provide NIC with personal information. Our Privacy Policy explains
              how we treat and protect your personally identifiable information and data when you use
              and access the Sites. The terms and conditions of our Privacy Policy are located at <a rel="noreferrer" target="_blank" href="https://www.egov.com/policy/privacy-policy.html">https://www.egov.com/policy/privacy-policy.html</a> and
              are hereby expressly incorporated into these Terms of Use. You may also request a
              copy of our Privacy Policy by emailing <a href="mailto:privacy@egov.com">privacy@egov.com</a>.
            </li>
            <li>
              <div><strong>LINKS, THIRD PARTY INFORMATION, INFORMATION PROVIDERS, AND LISTED RESOURCES</strong></div>
              NIC or third parties may provide links to or incorporate information from other websites or resources on the Internet through the Services.
              NIC has no control over such websites or resources. As such, you agree that we are not responsible for the availability of other external
              websites or resources and are not responsible for any content, information, advertising, products, or materials on or available or
              incorporated from such websites or resources. You access and use the other linked websites, information, and resources at your own risk.
              Additionally, your correspondence or business dealings with, or participation in any promotions of information providers, vendors, and/or
              resources found on or through the Services, including payment and delivery of related goods or services, and any other terms, conditions,
              warranties, or representations associated with such dealings, are solely and exclusively between you and such information provider or resource.
              You acknowledge and agree that NIC shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such
              dealings or as the result of the presence of such information provider or resources found on or through the Services.
              We grant you a limited, revocable, and nonexclusive right to create a hyperlink to the home page of our Websites so long as (i) the link or
              your linking website does not portray NIC, our affiliates, or our products or services in a false, misleading, derogatory, or otherwise
              offensive matter; (ii) your linking website complies with all applicable law and does not otherwise violate these Terms of Use or the rights
              of others; and (iii) your website has, maintains and follows a privacy policy no less protective of user data than the privacy policy of our
              Websites. You may not use any NIC logo or other proprietary graphic or trademark as part of the link without express written permission. We may
              revoke this license at any time, with or without cause, in which case you agree to immediately remove the hyperlink. You may not link to any
              page other than the home page of the applicable Website. Any link to our Websites should be a full forward link that passes the client browser
              to our home page without barriers. The "back" button should return the visitor to the original site if the visitor wishes to back out.
            </li>
            <li>
              <div><strong>REVIEWS, COMMENTS, AND OTHER SUBMISSIONS</strong></div>
              We welcome your comments and feedback regarding the Services. Please contact us with any comments and feedback at the address listed below
              under Section XII (Notices) or using the contact information available on each App or Website, if and where available. We do not, however,
              accept confidential or proprietary information. All comments, feedback, suggestions, ideas, materials, information, and other submissions
              (e.g., postings or comments through the Services, chat, web forums, or other methods), disclosed, submitted, or offered to NIC using any App
              or Website or otherwise (collectively, “Submissions”) are not confidential and will become and remain our property. NIC shall not be liable
              for any use or disclosure of any Submissions. By providing a Submission, you assign to NIC of all of your rights, title, and interest and
              goodwill in and to the Submissions without compensation to you. Submissions by you must not violate any right of any third party, and must
              not contain any libelous, abusive, obscene, or otherwise unlawful material.
            </li>
            <li>
              <div><strong>DISCLAIMERS AND LIMITATION OF LIABILITY</strong></div>
              <div>
                THE SERVICES ARE PROVIDED BY NIC ON AN “AS IS” AND “AS AVAILABLE” BASIS. NIC MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                IMPLIED, WITH REGARDS TO THE SERVICES. TO THE FULLEST EXTENT PERMITTED BY LAW, NIC EXPRESSLY DISCLAIMS ALL WARRANTIES RELATING TO THE SERVICES,
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. NIC IS NOT RESPONSIBLE FOR ANY ERRORS, OMISSIONS, OR INCORRECT INFORMATION POSTED ON OR THROUGH ANY
                APP OR WEBSITE. NIC IS NOT RESPONSIBLE FOR THE RESULTS OF YOUR USE OF OR RELIANCE ON ANY INFORMATION IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY,
                OR OTHERWISE
              </div>
              <div>
                NIC USES REASONABLE EFFORTS TO MAINTAIN THE SERVICES BUT NIC IS NOT RESPONSIBLE FOR ANY DEFECTS OR FAILURES ASSOCIATED WITH THE SERVICES, RELATED
                TECHNOLOGY, ANY CONTENT POSTED USING SERVICES, ANY PART OF THE FOREGOING, OR ANY DAMAGES (SUCH AS LOST PROFITS OR OTHER CONSEQUENTIAL DAMAGES) THAT MAY
                RESULT FROM ANY SUCH DEFECTS OR FAILURES. THE SERVICES MAY BE INACCESSIBLE OR INOPERABLE FOR ANY REASON, INCLUDING WITHOUT LIMITATION: (A) EQUIPMENT
                MALFUNCTIONS; (B) PERIODIC MAINTENANCE PROCEDURES OR REPAIRS; OR (C) CAUSES BEYOND THE CONTROL OF NIC OR WHICH ARE NOT FORESEEABLE BY NIC.
                NEITHER NIC NOR ITS AFFILIATES SHALL BE LIABLE TO YOU FOR ANY DIRECT OR INDIRECT LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE, SPECIAL OR OTHERWISE),
                INJURY, CLAIM, OR LIABILITY OF ANY KIND WHATSOEVER BASED UPON OR RESULTING FROM YOUR USE OR INABILITY TO USE THE SERVICES OR ANY INFORMATION OR MATERIALS
                PROVIDED ON THE SERVICES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT WILL OUR MAXIMUM LIABILITY TO YOU EXCEED THE AMOUNT YOU PAID TO US FOR THE SUBJECT SERVICES OR
                $100, WHICHEVER IS GREATER.
              </div>
            </li>
            <li>
              <div><strong>INDEMNIFICATION</strong></div>
              If you use the Services in any way that results in damage to NIC, you will need to take responsibility for such damage. You agree to
              indemnify, defend, and hold harmless NIC, our affiliates and government partners, and our officers, directors, shareholders, and agents
              (collectively, the “Indemnified Parties”) from and against any and all third party claims, demands, obligations, damages, losses, liabilities,
              expenses, and costs, including reasonable attorneys' fees, arising from (a) your access or use of the Services; (b) breach of these Terms of Use;
              or (c) your infringement or misappropriation of any intellectual property or other rights of NIC or third parties
            </li>
            <li>
              <div><strong>EXPORT</strong></div>
              Unless otherwise specified, the materials and information contained in and/or on the Services are presented solely for users residing in
              the United States and are related to services or programs in the United States. In using and accessing the Services, you are responsible
              for compliance with local laws, if and to the extent local laws are applicable. Certain software or other materials that you may obtain
              through the Services may be further subject to export controls. You will comply with all applicable export and re-export restrictions, laws,
              and regulation and you will not transfer, encourage, assist, or authorize the transfer of any software or materials to a prohibited country or
              otherwise in violation of any applicable restriction, law, or regulation.
            </li>
            <li>
              <div><strong>TERMINATION</strong></div>
              These Terms of Use are effective unless and until terminated by NIC. You may terminate these terms at any time by discontinuing use of the
              Services and destroying all materials obtained from use of such Sites and all related documentation and all copies and installations thereof
              provided that Sections V and VI will survive any termination. Your access to the Services may be terminated immediately: (i) if, in our sole
              discretion, you fail to comply with any term or provision of these Terms of Use; (ii) NIC is required to do so by law (for example, where
              the provision of the Services to you is, or becomes, unlawful); (iii) a government partner with whom NIC offered the Services to you has
              terminated its relationship with NIC or ceased to offer the Services to you; (iv) NIC is transitioning to no longer providing the Services
              to users in the state or jurisdiction in which you reside or from which you use the Services; or (v) the provision of the Services to you by
              NIC is, in NIC's sole discretion, no longer commercially viable. Upon termination, you must cease use of the Services and destroy all materials
              obtained from your use of the Services and all copies thereof.
            </li>
            <li>
              <div><strong></strong>NOTIFICATION OF COPYRIGHT INFRINGEMENT</div>
              <div>
                If you believe that the Services contain elements that infringe your copyrights, please provide NIC's designated copyright agent with
                the following information in writing:
              </div>
              <ul style={{ listStyle: "circle" }}>
                <li>A physical or electronic signature of person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                <li>Identification of the copyrighted work claimed to have been infringed, or multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material;</li>
                <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and if available, an electronic mail address at which the complaining party may be contacted;</li>
                <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
              </ul>
              <div>
                By mail:<br />
                NIC DMCA Agent<br />
                c/o Stinson Leonard Street<br />
                Stinson IP Administrator<br />
                Suite 2900<br />
                Kansas City, MO 64106<br />
                Attention: NIC Designated Copyright Agent<br />
                By phone: (816) 842-8600<br />
                By fax: (816) 691-3495<br />
                By email: <a href="mailto:DMCA@stinsonleonard.com">DMCA@stinsonleonard.com</a><br />
                For additional information regarding this procedure, please reference 17 U.S.C. § 512.
              </div>
            </li>
            <li>
              <div><strong>DISPUTES</strong></div>
              Please contact NIC using the contact information provided below in Section XII (Notices) regarding any claim or controversy arising out of
              or relating to these Terms of Use, or any breach thereof, and we will attempt in good faith to resolve the claim, or we can agree to engage
              in mediation. Each party agrees to pay for its own expenses in engaging in mediation. Any claim, dispute, or controversy that cannot be resolved
              through mediation within thirty (30) days will be settled by arbitration as set forth below.
              <ol style={{ listStyle: "upper-alpha" }}>
                <li>
                  <strong>Arbitration</strong>. Except for claims or controversies for which NIC may pursue injunctive relief, any claim, dispute, or
                  controversy arising out of or relating to the Services, these Terms of Use, or the breach, termination, enforcement, interpretation, or
                  validity of these Terms of Use, will be resolved exclusively by binding arbitration administered by the American Arbitration Association
                  (“AAA”) before a single arbitrator. Payment of all filing, administration, and arbitrator fees will be governed by AAA's applicable rules.
                  The arbitrator's decision shall be controlled by these Terms of Use and any other agreements referenced herein. Unless you and NIC agree
                  otherwise, any arbitration hearings will take place in the county (or parish) of your residence. If the claim or controversy is for $10,000 or
                  less, NIC agrees that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator through a
                  telephonic hearing or by an in-person hearing as established by the AAA Rules. If the claim or controversy exceeds $10,000, the right to a hearing will be
                  determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the award of the arbitrator shall be accompanied by a statement of the
                  reasons upon which the award is based. <strong style={{ textDecoration: "none" }}>You understand that, in the absence of this provision, you would have had a right to litigate disputes through a court, including
                    the right to litigate claims on a class-wide or class-action basis, and that You have expressly and knowingly waived those rights and agreed to resolve any disputes through
                    binding arbitration in accordance with the provisions of this paragraph</strong>.
                </li>
                <li>
                  <strong>Limitation on Time to File</strong>. YOU AGREE THAT IF YOU DO NOT FILE A DEMAND TO ARBITRATE A DISPUTE WITHIN ONE (1) YEAR AFTER
                  THE DISPUTE ARISES, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                </li>
                <li>
                  <strong>Arbitration on an Individual Basis Only; Class Arbitration Waiver</strong>. YOU AGREE THAT, BY ACCEPTING THESE TERMS OF USE, YOU
                  AND NIC ARE EACH WAIVING THE RIGHT TO PARTICIPATE IN OR BRING A CLASS ACTION. NIC AND YOU AGREE TO ARBITRATE SOLELY ON AN INDIVIDUAL BASIS,
                  AND AGREE THAT THESE TERMS OF USE DO NOT PERMIT CLASS ARBITRATION OR ANY CLAIMS BROUGHT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                  REPRESENTATIVE ARBITRATION PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER
                  ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THESE TERMS OF USE EVIDENCE A TRANSACTION IN INTERSTATE COMMERCE, AND THUS THE FEDERAL ARBITRATION
                  ACT (“FAA”) GOVERNS THE INTERPRETATION AND ENFORCEMENT OF THIS PROVISION. IF FOR ANY REASON, A CLAIM PROCEEDS IN COURT RATHER THAN ARBITRATION, NIC AND
                  YOU WAIVE ANY RIGHT TO A JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION AGAINST EACH OTHER.
                </li>
                <li>
                  <strong>Applicable Law</strong>. Although the Services are accessible over the Internet and therefore available worldwide, the Services
                  and their content are intended for residents of the United States only. If you choose to access the Services from locations outside the
                  United States, such conduct is at your own risk and subject to the laws of the United States of America, which may differ from privacy laws
                  in your state or home country, and you are responsible for compliance with any local laws and regulations. Nothing in the Services should be
                  interpreted as a promotion or solicitation for any product of any nature that is not authorized by the laws and regulations of the country where
                  you are located. You agree that the laws of the State of Kansas, without giving effect to any principles of conflicts of law, govern these Terms of Use
                  and any dispute of any sort that may arise between you and us or any of our affiliates. NIC and you acknowledge that these Terms of Use evidence a
                  transaction involving interstate commerce. Notwithstanding the foregoing paragraph with respect to applicable substantive law, any arbitration conducted
                  pursuant to these Terms of Use shall be governed by the FAA, 9 U.S.C. §§ 1-16.
                </li>
                <li>
                  <strong>Conflict</strong>. If any part of this arbitration provision is deemed to be invalid, unenforceable or illegal, or otherwise
                  conflicts with the code of procedure established by the AAA, the balance of this arbitration provision shall remain in effect and shall
                  be construed in accordance with its terms as if the invalid, unenforceable, illegal, or conflicting provision were not contained herein.
                </li>
                <li>
                  <strong>Injunctive Relief</strong>. Notwithstanding anything to the contrary, NIC retains the right to seek injunctive relief if you use
                  the Services in violation of the access rights or the restrictions on use, infringe upon or misappropriate NIC's intellectual property
                  rights, or breach your obligation to maintain the confidentiality of NIC's confidential or proprietary information made available to you through the
                  Services.
                </li>
              </ol>
            </li>
            <li>
              <div><strong>MISCELLANEOUS</strong></div>
              <ol>
                <li>
                  <strong>Entire Agreement</strong>. These Terms of Use, together with our Privacy Policy and any additional terms, rules, or other
                  policies to which NIC refers, contain the entire agreement between you and NIC and supersedes any prior or contemporaneous agreements
                  concerning the subject matter hereof.
                </li>
                <li>
                  <strong>Waiver</strong>. No delay or omission by NIC or you in exercising any right under these Terms of Use will operate as a waiver
                  of that or any other right.
                </li>
                <li>
                  <strong>Modification</strong>. We reserve the right to modify, supplement, or replace the terms of these Terms of Use or any additional
                  terms that apply to the Services at any time, effective by notifying you as provided herein. If you do not want to agree to any to these
                  Terms of Use, you can terminate these terms at any time pursuant to Section VIII (Termination). You should review these Terms of Use regularly.
                  Your continued access or Use of the Services after the Terms of Use have been revised constitutes your express consent to the modified Terms of
                  Use.
                </li>
                <li>
                  <strong>Severability</strong>. If a particular term or condition is deemed not enforceable, invalid, or void, that term or condition
                  shall be deemed severable and not affect the validity and enforceability of any remaining terms or conditions.
                </li>
                <li>
                  <strong>Section Titles</strong>. The section titles in these Terms of Use are for convenience only and have no legal or contractual
                  effect.
                </li>
                <li>
                  <strong>Assignment</strong>. You may not assign or delegate any rights or obligations under these Terms of Use. Any purported
                  assignment and delegation shall be ineffective. NIC may freely assign or delegate all rights and obligations under these Terms of Use,
                  fully or partially without notice to you. NIC may also substitute, by way of unilateral novation, NIC for any third party that assumes
                  NIC's rights and obligations under these Terms of Use, effective upon notice to you.
                </li>
              </ol>
            </li>
            <li>
              <div><strong>NOTICES</strong></div>
              <div>
                Notices to you may be made via either email or regular mail. NIC may also provide notices of changes to these Terms of Use or other matters
                by displaying notices or links to notices to you generally on or through the Services. Official notices related to these Terms of Use must be
                sent to NIC at:
              </div>
              <div>
                NIC Inc.<br />
                25501 West Valley Parkway<br />
                Suite 300<br />
                Olathe, KS 66061<br />
                Attention: Legal Department
              </div>
            </li>
          </ul>
        </section>
      </div>
    </>
  )
}

export default TermsOfUse;
