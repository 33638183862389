import React, { useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import { ForgeButton, ForgeCard, ForgeIcon, ForgeScaffold, ForgeSkeleton, ForgeToolbar } from '@tylertech/forge-react';

import { Articles } from '../content/Articles';
import { groupArray } from '../../utility/Util';
import ProductButton from '../ProductButton';
import { ProductCardGroup, ProductCardGroupSkeleton } from '../ProductCard';
import { ProductsConfig } from '../../../config/ProductsConfig';

const featuredArticles = [].concat(Object.keys(Articles).slice(0, 3).map(x => Articles[x]));

const FeaturedProductCard = ({ product = {}, isLoading = true }) => (
    <ForgeCard className="nic-console-featured-card">
        <ForgeScaffold>
            <ForgeToolbar slot="header">
                <div slot="start">
                    {
                        isLoading ? (
                            <ForgeSkeleton text style={{ "width": "25%" }} />
                        ) : (
                            <h3 className="forge-typography--title">{product.name}</h3>
                        )
                    }
                </div>
            </ForgeToolbar>
            <div slot="body">
                {
                    isLoading ? (
                        <ForgeSkeleton text style={{ "width": "60%" }} />
                    ) : (
                        <p className="forge-typography--body1">{product.featuredDescription}</p>
                    )
                }
            </div>
            <ForgeToolbar slot="footer">
                <div slot="end">
                    {
                        isLoading ? (
                            <ForgeSkeleton button />
                        ) : (
                            <ProductButton product={product} buttonType="use" />
                        )
                    }
                </div>
            </ForgeToolbar>
        </ForgeScaffold>
    </ForgeCard>
);

const FeaturedCatalogCard = () => (
    <ForgeCard className="nic-console-featured-card">
        <ForgeScaffold>
            <ForgeToolbar slot="header">
                <div slot="start">
                    <h3 className="forge-typography--title">Product Catalog</h3>
                </div>
            </ForgeToolbar>
            <div slot="body">
                <p className="forge-typography--body1">Use the Product Catalog to find more products</p>
            </div>
            <ForgeToolbar slot="footer">
                <Link to={"/product-catalog"} slot="end">
                    <ForgeButton type="raised">
                        <button className="nic-console-product-use-button">Explore Products</button>
                    </ForgeButton>
                </Link>
            </ForgeToolbar>
        </ForgeScaffold>
    </ForgeCard>
);

const NewsCard = ({
    imageName = "news-spot",
    altTag = "news",
    headline,
    content,
    id }) => (
    <ForgeCard className="nic-console-news-card">
        <ForgeScaffold>
            <ForgeToolbar slot="header">
                <h3 className="forge-typography--title" slot="start">{headline}</h3>
            </ForgeToolbar>
            <div slot="body">
                <img
                    src={`https://cdn.forge.tylertech.com/v1/images/spot/${imageName}.svg`}
                    className="nic-console-news-image"
                    alt={altTag}
                />
                <p className="forge-typography--body1">{content}</p>
            </div>
            <ForgeToolbar slot="footer">
                <Link to={`/articles/${id}`} slot="end">
                    <ForgeButton type="raised">
                        <button type="button">Read More</button>
                    </ForgeButton>
                </Link>
            </ForgeToolbar>
        </ForgeScaffold>
    </ForgeCard>
);

const ConsoleHome = () => {

    const { setBodyTitle, setBreadcrumbs, setHideBackground, userFavoriteProducts } = useOutletContext();
    const [featuredProduct, setFeaturedProduct] = useState({});
    const [popularProducts, setPopularProducts] = useState([]);
    const [favoriteProducts, setFavoriteProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            const products = [].concat(Object.keys(ProductsConfig)).map(x => ProductsConfig[x]);
            setFeaturedProduct(products[0]);
            setPopularProducts(products.slice(0, 3));

            const favoriteProducts = []
                .concat(userFavoriteProducts)
                .map(x => products.find(y => y.id === x))
                .filter(x => x !== undefined);
            setFavoriteProducts(groupArray(favoriteProducts));
            setIsLoading(false);
        };

        setBodyTitle(false);
        setBreadcrumbs(false);
        setHideBackground(false);
        fetchProducts();
    }, [setBodyTitle, setBreadcrumbs, setHideBackground, userFavoriteProducts]);

    return (
        <>
            <div className="nic-console-featured-content">
                <div className="nic-console-featured-product-content">
                    <div className="nic-console-featured-headers">
                        <ForgeIcon name="add_chart" slot="icon"></ForgeIcon>
                        <h2 className="forge-typography--headline4">
                            Featured Product
                        </h2>
                    </div>
                    <FeaturedProductCard product={featuredProduct} isLoading={isLoading} />
                </div>
                <div className="nic-console-featured-explore-products">
                    <div className="nic-console-featured-headers nic-console-featured-explore-products-header">
                        <ForgeIcon name="explore" slot="icon"></ForgeIcon>
                        <h2 className="forge-typography--headline4">
                            Explore Products
                        </h2>
                    </div>
                    <FeaturedCatalogCard />
                </div>
            </div>

            <div className="nic-console-home-main-content">
                <div className="nic-console-home-favorite-products">
                    <h3 className="forge-typography--headline4 nic-console-main-content-header">Favorite Products</h3>
                    {
                        isLoading ? (
                            <ProductCardGroupSkeleton />
                        ) : (
                            favoriteProducts.length > 0 ? (
                                favoriteProducts.map((x, i) => (
                                    <ProductCardGroup
                                        key={`favorite-products-group-${i}`}
                                        products={x}
                                        isLoading={isLoading}
                                    />
                                ))
                            ) : (
                                <p className="forge-typography--subtitle2">Favorite a product to have it displayed here</p>
                            )
                        )
                    }
                </div>

                <div className="nic-console-spacer"></div>

                <div className="nic-console-home-popular-products">
                    <h3 className="forge-typography--headline4 nic-console-main-content-header">Popular Products</h3>
                    <ProductCardGroup products={popularProducts} isLoading={isLoading} />
                </div>

                <div className="nic-console-spacer"></div>

                <div className="nic-console-home-news">
                    <h3 className="forge-typography--headline4 nic-console-main-content-header">News & Updates</h3>
                    <div className="nic-console-news-container">
                        {
                            [].concat(featuredArticles).map((x, i) => (
                                <NewsCard
                                    key={`featured-article-${i}`}
                                    id={x.id}
                                    headline={x.headline}
                                    content={x.subtitle}
                                    imageName={x.imageName}
                                    altTag={x.imageCaption}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default ConsoleHome;
