import { Link } from "react-router-dom";

const Breadcrumb = ({ link, label }) => {
    const LabelComponent = () => <span>{label}</span>;
    return (
        <li>
            {
                link ? (
                    <Link to={link}>
                        <LabelComponent />
                    </Link>
                ) : (<LabelComponent />)
            }
        </li>
    );
};

const ConsoleBreadcrumbsNav = ({ breadcrumbs }) => (
    <div className="nic-console-breadcrumbs">
        <ul>
            {
                breadcrumbs.map((x, i) => <Breadcrumb key={`nic-console-breadcrumb-${i}`} {...x} />)
            }
        </ul>
    </div>
);

export default ConsoleBreadcrumbsNav;
