import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { API, Cache } from 'aws-amplify';

import { ForgeCard, ForgeIcon, ForgeInlineMessage, ForgeScaffold, ForgeSkeleton, ForgeTable, ForgeTooltip } from '@tylertech/forge-react';

import { groupArray, padGroup } from "../../utility/Util";
import MspPageLayout from '../MspPageLayout';

Cache.configure({
  defaultTTL: 900000
});

const InlineInfoMessage = ({ message = "" }) => (
  <div style={{ "maxWidth": "50%" }}>
    <ForgeInlineMessage theme="info">
      <ForgeIcon name="info" slot="icon"></ForgeIcon>
      <div>{message}</div>
    </ForgeInlineMessage>
  </div>
)

const MspDefaultServiceLimits = ({ isLoading = true, globalDefaultLimits = [] }) => (
  <div className="msp-admin-service-metrics-group">
    {
      isLoading ? (
        [...Array(2)].map((x, i) => (
          <ForgeCard className="msp-admin-service-metrics-card" key={`service-default-limit-loading-${i}`}>
            <div className="forge-card-header-container">
              <ForgeSkeleton text style={{ "width": "8rem", "margin": "2rem" }} />
              <ForgeSkeleton text className="msp-admin-keep-right" style={{ "width": "6rem", "margin": "1rem", }} />
            </div>
          </ForgeCard>
        ))
      ) : (
        [].concat(globalDefaultLimits).length > 0 ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="forge-typography--subtitle1">
                Your MSP service calls are subject to the following limits by default
              </p>
              <ForgeIcon name="info_outline" style={{ "--forge-icon-font-size": "3", "paddingLeft": "0.5rem", "paddingBottom": "0.25rem" }}></ForgeIcon>
              <ForgeTooltip>These defaults could be overwritten by service limits</ForgeTooltip>
            </div>
            <div className="msp-admin-service-metrics-card-group">
              {
                [].concat(globalDefaultLimits).map((x, i) => (
                  <ForgeCard className="msp-admin-service-metrics-card" key={`service-default-limit-${i}`}>
                    <div className="forge-card-header-container">
                      <h3 className="forge-typography--subtitle1-secondary">{x.name}</h3>
                      <p className="forge-typography--subtitle1 msp-admin-keep-right">{`${x.value} requests per second`}</p>
                    </div>
                  </ForgeCard>
                ))
              }
            </div>

          </div>
        ) : (
          <InlineInfoMessage message="Your tenant does not have default rates defined." />
        )
      )
    }
  </div>
)

const MspServiceLimits = ({
  serviceLimits = [],
  isLoading = true,
  globalDefaultLimits = {},
  groupSize = 2
}) => {

  const groupAndPadItems = (items = [], groupSize = 2) => (
    groupArray([].concat(items), groupSize)
      .map(x => padGroup(x, groupSize))
  );

  return (
    <div>
      <p className="forge-typography--subtitle1">
        These service limits are specific for your tenant and override any global default limits
      </p>
      {
        isLoading ? (
          groupAndPadItems([...Array(groupSize)]).map((group, i) => (
            <div key={`msp-loading-admin-service-quota-group-${i}`} className="msp-admin-service-card-group">
              {
                group.map((y, i) => (
                  <ForgeCard
                    key={`msp-loading-admin-service-quotas-card-${i}`}
                    style={{ "--forge-card-width": "30rem" }}>
                    <div className="forge-card-header-container">
                      <ForgeSkeleton text style={{ "width": "8rem", "height": "2rem", "margin": "2rem" }} />
                    </div>
                    <ForgeSkeleton text className="msp-admin-keep-right" style={{ "width": "10rem", "margin": "1rem", }} />
                  </ForgeCard>
                ))
              }
            </div>
          ))
        ) : (
          [].concat(serviceLimits).length > 0 ? (
            groupAndPadItems(serviceLimits, groupSize).map((group, i) => (
              <div key={`msp-admin-service-limit-group-${i}`} className="msp-admin-service-limit-card-group">
                {
                  [].concat(group)
                    .filter(x => x !== undefined)
                    .map((serviceLimit) => (
                      <MspServiceLimitsCard
                        key={`msp-admin-service-limit-card-${serviceLimit.service}}`}
                        service={serviceLimit.service}
                        limits={serviceLimit.limits}
                        globalDefaultLimits={globalDefaultLimits}
                      />
                    )
                    )
                }
              </div>
            ))
          ) : (
            <InlineInfoMessage message="No service limits were found for your tenant." />
          )
        )
      }
    </div>
  )
};

const MspServiceLimitsCard = ({ service, limits = [], globalDefaultLimits = [] }) => {

  const columnConfigurations = [
    { property: "method", header: "Method" },
    { property: "path", header: "Path" },
    { property: "rateLimit", header: "Rate Limit" },
    { property: "burstLimit", header: "Burst Limit" },
  ];

  const prettyServiceName = {
    "ADDRESS.V2": "Address v2",
    "AUTH": "Auth",
    "ENTITY": "Entity",
    "MAP.V1": "Map",
    "NOTIFICATION": "Notification",
    "NOTIFICATION.V2": "Notification v2",
    "SHOPPINGCART": "Shopping Cart"
  };

  const defaultRateLimit = globalDefaultLimits.find(x => x.name === "Rate Limit");
  const defaultBurstLimit = globalDefaultLimits.find(x => x.name === "Burst Limit");

  const limitsOrDefaults = (limits.length > 0) ? limits : [{
    method: "*",
    path: "*",
    rateLimit: `${defaultRateLimit.value} (Global Default)`,
    burstLimit: `${defaultBurstLimit.value} (Global Default)`
  }];

  return (
    <ForgeCard className="msp-admin-service-limits-card">
      <ForgeScaffold>
        <div className="forge-card-header-container" slot="header">
          <h3 className="forge-typography--subtitle1-secondary">{prettyServiceName[service]} Service</h3>
          <h4 className="forge-typography--subtitle2-secondary">The rate and burst limits displayed here are expressed in requests per second</h4>
        </div>
        <div slot="body">
          <ForgeTable
            columnConfigurations={columnConfigurations}
            data={limitsOrDefaults}>
          </ForgeTable>
        </div>
      </ForgeScaffold>
    </ForgeCard>
  )
};

const MspServiceLimitsPage = ({ userContext = {} }) => {
  const { setBodyTitle, setBreadcrumbs, setActiveSideNavbarLabel, setSideNavServiceConfig } = useOutletContext();
  const [isLoadingTenantServiceLimits, setIsLoadingTenantServiceLimits] = useState(true);
  const [tenantServiceLimits, setTenantServiceLimits] = useState({});
  const [hasFetchError, setHasFetchError] = useState(false);
  const cacheKeyBase = "msp-admin-service-limits";

  useEffect(() => {
    setBodyTitle(false);
    setSideNavServiceConfig(false);
    setActiveSideNavbarLabel('Service Limits');
    setBreadcrumbs([
      { link: "/msp-admin/home", label: "MSP Admin" },
      { label: "Service Limits" }
    ]);
  }, [setBodyTitle, setActiveSideNavbarLabel, setBreadcrumbs, setSideNavServiceConfig]);

  useEffect(() => {
    const fetchTenantServiceLimits = async () => {
      setIsLoadingTenantServiceLimits(true);
      try {
        const tenantId = userContext.tenantId;
        const cacheKey = `${cacheKeyBase}-${tenantId}`

        const cachedLimits = await Cache.getItem(cacheKey);
        if (cachedLimits == null) {
          const response = await API.get("tenant_usage", `/tenant/${tenantId}/usage-plan`);
          const serviceLimits = [].concat(Object.keys(response.serviceLimits)).map(x => ({
            service: x,
            limits: response.serviceLimits[x]
          })).sort((a, b) => {
            if (a.service > b.service) {
              return 1;
            } else if (a.service < b.service) {
              return -1;
            } else {
              return 0
            }
          });

          const tenantServiceLimits = {
            globalDefaultLimits: [
              { name: "Rate Limit", value: response.global.rateLimit },
              { name: "Burst Limit", value: response.global.burstLimit }
            ],
            serviceLimits: serviceLimits
          };

          Cache.setItem(cacheKey, tenantServiceLimits);
          setTenantServiceLimits(tenantServiceLimits);
        } else {
          setTenantServiceLimits(cachedLimits);
        }
      } catch (error) {
        console.error("Error loading tenant usage", error);
        setHasFetchError(true);
      }
      setIsLoadingTenantServiceLimits(false);
    };

    fetchTenantServiceLimits();
  }, [userContext]);

  return (
    <MspPageLayout>
      <ForgeCard style={{ "--forge-card-body-overflow": "true" }}>
        <h2 className="forge-typography--headline4">MSP Service Limits</h2>
        <p className="forge-typography--body1">
          Calls to MSP Services are subject to burst and rate limits. The limits listed on this page are specific to
          your tenant. The listed global limits apply to all MSP Service calls. In addition to global limits, your tenant
          may have service specific limits which override the listed global limits. Service-specific limits can be configured
          for your tenant for some or all of the service's endpoints. A service may display "*", which applies the given quota and limit to all of the service's endpoints; note that
          if the service specifies a specific endpoint's quota, that will override any global or service defaults.
        </p>
        <div className="msp-admin-spacer-small"></div>
        <div className="msp-admin-service-limits-defaults">
          <h3 className="forge-typography--headline5">
            Default Global Limits
          </h3>
          {
            !hasFetchError ? (
              <MspDefaultServiceLimits
                isLoading={isLoadingTenantServiceLimits}
                globalDefaultLimits={tenantServiceLimits.globalDefaultLimits}
              />
            ) : (
              <InlineInfoMessage message="Your tenant's global default limits could not be fetched at this time. Please try again later." />
            )
          }
        </div>
        <div className="msp-admin-spacer-small"></div>
        <div className="msp-admin-service-limits">
          <h3 className="forge-typography--headline5">Service Limits</h3>
          {
            !hasFetchError ? (
              <MspServiceLimits
                isLoading={isLoadingTenantServiceLimits}
                serviceLimits={tenantServiceLimits.serviceLimits}
                globalDefaultLimits={tenantServiceLimits.globalDefaultLimits}
              />
            ) : (
              <InlineInfoMessage message="Your tenant service limits could not be fetched at this time. Please try again later." />
            )
          }
        </div>

      </ForgeCard>
    </MspPageLayout>
  )
};

export default MspServiceLimitsPage;
