export const Articles = {
    "welcome-to-nic-console": {
        id: "welcome-to-nic-console",
        headline: "Announcing the new console",
        subtitle: "Learn more about the features added in the newly launched console",
        publishedDate: "09/26/2022",
        markdownFile: "welcome-to-nic-console.md",
        imageName: "announcement-1-spot",
        imageCaption: "announcement"
    }
};
