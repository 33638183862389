import React from 'react';

import { ForgePageState } from '@tylertech/forge-react';

function NotFound() {
    return (
        <div slot="body">
            <ForgePageState>
                <img src="https://cdn.forge.tylertech.com/v1/images/spot-hero/404-error-spot-hero.svg" alt="" slot="graphic" />
                <div slot="title">Page not found...</div>
                <div slot="message">The page you're looking for. It might have been removed or you may have mistyped the URL</div>
            </ForgePageState>
        </div>
    );
}

export default NotFound;
