import React from 'react';
import PropTypes from 'prop-types';

import { ForgeSkeleton } from '@tylertech/forge-react';

const MspSkeletonList = ({ size = 0 }) => (
    <>
        {
            [...Array(size)].map((x, i) => <ForgeSkeleton list-item key={`forge-skeleton-list-${i}`}/>)
        }
    </>
);

MspSkeletonList.propTypes = {
    size: PropTypes.number
}

export default MspSkeletonList;
