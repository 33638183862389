import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Joi from 'joi-browser';

import { ForgeCard, ForgeDialog, ForgeInlineMessage, ForgeTextField, ForgeToolbar } from '@tylertech/forge-react';
import TermsOfUse from '../TermsOfUse';
import LoadingButton from '../LoadingButton';
import OAuthButton from '../auth/OAuthButton';
import Validate from '../../utility/Validate';

const Login = (props) => {
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ invalidValues: {} });
  const [authError, setAuthError] = useState(props.authError);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validationSchema = {
    username: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case 'any.empty':
              return { message: 'Required' };
            default:
              return { message: '' };
          }
        });
      }),
    password: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case 'any.empty':
              return { message: 'Required' };
            default:
              return { message: '' };
          }
        });
      }),
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(Validate(values, validationSchema));
    setIsSubmitting(true);
  };

  useEffect(() => {

    const signIn = async () => {
      setIsSubmitting(true);
      try {
        await Auth.signIn(values.username, values.password);
      } catch (e) {
        setIsSubmitting(false);
        let error = null;
        if (e.message === 'User does not exist.' || e.message === 'Incorrect username or password.') {
          error = 'Incorrect username or password.';
        } else {
          error = 'An error has occurred while attempting to login. Please try again later.';
        }
        setAuthError(error);
      }
    };

    if (isSubmitting) {
      const noErrors = Object.keys(errors).filter(key => key !== "invalidValues").length === 0;
      if (noErrors) {
        signIn();
      } else {
        setIsSubmitting(false);
      }
    }
  }, [errors, values.username, values.password, isSubmitting]);

  return (
    <>
      <ForgeToolbar slot="body-header">
        <h1 slot="start" className="forge-typography--title">Login</h1>
      </ForgeToolbar>
      <div slot="body" style={{ 'paddingLeft': '16px', display: 'flex', flexDirection: 'column' }}>
        <div className="nic-console-background"></div>
        <div style={{ flex: "1 1 0", marginTop: "25px" }}>
          <div className="nic-console-login-card">
            <ForgeCard>
              {
                authError && (
                  <ForgeInlineMessage theme="warning">
                    <div>
                      <p className="forge-typography--body1">
                        {
                          authError.message || authError
                        }
                      </p>
                    </div>
                  </ForgeInlineMessage>
                )
              }
              <div>
                <p className="forge-typography--body1">Login using your MSP Account Service credentials</p>
                <form noValidate onSubmit={handleSubmit}>
                  <ForgeTextField invalid={errors.username}>
                    <input name="username" type="text" placeholder="username" autoCapitalize="none" value={values.username} onChange={handleChange} />
                    <label htmlFor="username" slot="label">Username</label>
                    {errors && <span slot="helper-text">{errors.username}</span>}
                  </ForgeTextField>
                  <ForgeTextField invalid={errors.password}>
                    <input name="password" type="password" placeholder="password" value={values.password} onChange={handleChange} />
                    <label htmlFor="password" slot="label">Password</label>
                    {errors && <span slot="helper-text">{errors.password}</span>}
                  </ForgeTextField>
                  <LoadingButton
                    className="nic-console-login-button"
                    type="submit"
                    isLoading={isSubmitting}
                    text="Login"
                    loadingText="Logging In..."
                  />
                </form>
                <div className="nic-console-text-separator">
                  <h5><span>OR</span></h5>
                </div>
                <div className="nic-console-center">
                  <OAuthButton />
                </div>
              </div>
            </ForgeCard>
          </div>
        </div>
      </div>

      <Footer />
    </>

  );
};

const Footer = () => {
  const year = new Date().getFullYear();
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const onDismissTermsOfUse = () => {
      setShowTermsOfUse(false)
  }
  return (
      <div slot="footer" className="nic-console-footer">
        <ul>
          <li>
            © {year} NIC Inc. All Rights Reserved
          </li>
        </ul>
        <ul>
          <li>
            <a rel="noreferrer" href="/" onClick={(event) => {event.preventDefault(); setShowTermsOfUse(true)}}>Terms of Use</a>
          </li>
        </ul>
        <ForgeDialog open={showTermsOfUse} onDismiss={onDismissTermsOfUse}>
          <TermsOfUse onDismiss={onDismissTermsOfUse}/>
        </ForgeDialog>
      </div>
  )
}

export default Login;
