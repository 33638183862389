import {
  defineAppBarComponent,
  defineAppBarHelpButtonComponent,
  defineAppBarMenuButtonComponent,
  defineAppBarProfileButtonComponent,
  defineAppBarSearchComponent,
  defineAutocompleteComponent,
  defineBadgeComponent,
  defineBannerComponent,
  defineButtonComponent,
  defineButtonToggleComponent,
  defineButtonToggleGroupComponent,
  defineCardComponent,
  defineChipComponent,
  defineChipFieldComponent,
  defineChipSetComponent,
  defineCircularProgressComponent,
  defineDatePickerComponent,
  defineDateRangePickerComponent,
  defineDialogComponent,
  defineDividerComponent,
  defineDrawerComponent,
  defineExpansionPanelComponent,
  defineFilePickerComponent,
  defineFloatingActionButtonComponent,
  defineIconComponent,
  defineIconButtonComponent,
  defineInlineMessageComponent,
  defineLabelValueComponent,
  defineListComponent,
  defineListItemComponent,
  defineModalDrawerComponent,
  defineOpenIconComponent,
  defineOptionComponent,
  definePageStateComponent,
  definePaginatorComponent,
  defineRadioComponent,
  defineScaffoldComponent,
  defineSelectComponent,
  defineSelectDropdownComponent,
  defineSkeletonComponent,
  defineSwitchComponent,
  defineTabComponent,
  defineTabBarComponent,
  defineTableComponent,
  defineTextFieldComponent,
  defineTimePickerComponent,
  defineToastComponent,
  defineToolbarComponent,
  defineTooltipComponent,
  defineViewComponent,
  defineViewSwitcherComponent,
  defineColorPickerComponent,
} from "@tylertech/forge";

export const defineComponents = () => {
  defineAppBarComponent();
  defineAppBarHelpButtonComponent();
  defineAppBarMenuButtonComponent();
  defineAppBarProfileButtonComponent();
  defineAppBarSearchComponent();
  defineAutocompleteComponent();
  defineBadgeComponent();
  defineBannerComponent();
  defineButtonComponent();
  defineButtonToggleComponent();
  defineButtonToggleGroupComponent();
  defineCardComponent();
  defineChipComponent();
  defineChipFieldComponent();
  defineChipSetComponent();
  defineCircularProgressComponent();
  defineDatePickerComponent();
  defineDateRangePickerComponent();
  defineDialogComponent();
  defineDividerComponent();
  defineDrawerComponent();
  defineExpansionPanelComponent();
  defineFilePickerComponent();
  defineFloatingActionButtonComponent();
  defineIconComponent();
  defineIconButtonComponent();
  defineInlineMessageComponent();
  defineLabelValueComponent();
  defineListComponent();
  defineListItemComponent();
  defineModalDrawerComponent();
  defineOpenIconComponent();
  defineOptionComponent();
  definePageStateComponent();
  definePaginatorComponent();
  defineRadioComponent();
  defineScaffoldComponent();
  defineSelectComponent();
  defineSelectDropdownComponent();
  defineSkeletonComponent();
  defineSwitchComponent();
  defineTabComponent();
  defineTabBarComponent();
  defineTableComponent();
  defineTextFieldComponent();
  defineTimePickerComponent();
  defineToastComponent();
  defineToolbarComponent();
  defineTooltipComponent();
  defineViewComponent();
  defineViewSwitcherComponent();
  defineColorPickerComponent();
};
