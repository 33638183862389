import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from "aws-amplify";
import AmplifyConfig from "./config/AmplifyConfig";
import { BrowserRouter } from "react-router-dom";
import { Buffer } from 'buffer';
import { install } from 'ga-gtag';

import { ForgeAppBar, ForgeAppBarMenuButton, ForgeCircularProgress, ForgeScaffold } from '@tylertech/forge-react';
import "@tylertech/forge/dist/forge.css";

import ConsoleHeader from './components/nic-console/ConsoleHeader';
import { defineComponents } from './components/common/ForgeComponentRegistry';
import { getAccountContext } from './components/nic-console/auth/GetAccountContext';
import Login from './components/nic-console/containers/Login';
import { registerIcons } from './components/common/IconRegistry';
import Routes from './Routes';
import RouteChangeObserver from './components/utility/RouteChangeObserver';

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const SIDE_NAV_BREAKPOINT = 1260;
const NAV_HEADER_BREAKPOINT = 1260;

const HUB_CHANNEL_AUTH = "auth";
const HUB_CHANNEL_NIC_CONSOLE_USER_CONTEXT = "nic-console:user-context";

const AUTH_STATE_LOADING = "loading";
const AUTH_STATE_NO_LOGIN = "signIn";
const AUTH_STATE_SIGN_IN_FAILURE = "signIn_failure";
const AUTH_STATE_SIGNED_IN = "signedIn";

const NO_ACCESS_MESSAGE = "You do not have access to the console";

defineComponents();
registerIcons();

install(MEASUREMENT_ID, {
  'send_page_view': false
});

Amplify.configure(AmplifyConfig);

const App = (props) => {

  const [authState, setAuthState] = useState(AUTH_STATE_LOADING);
  const [authError, setAuthError] = useState();
  const [userContext, setUserContext] = useState();
  const [sideNavOpen, setSideNavOpen] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [drawerType, setDrawerType] = useState("dismissable");

  useEffect(() => {

    const authHubListenerCallback = async (data) => {
      switch (data.payload.event) {
        case AUTH_STATE_NO_LOGIN: {
          try {
            let accountContext = await getAccountContext(data.payload.data);
            if (accountContext) {
              setAuthState(AUTH_STATE_SIGNED_IN);
              setUserContext(accountContext.userContext);
              setAuthError(false);
            } else {
              // no access
              handleAuthError(NO_ACCESS_MESSAGE)
            }
          } catch (error) {
            handleAuthError();
          }
          break;
        }
        case AUTH_STATE_SIGN_IN_FAILURE: {
          handleAuthError(data.payload.data);
          break;
        }
        default:
          break;
      }
    };

    Hub.listen(HUB_CHANNEL_AUTH, authHubListenerCallback);

    const userContextHubListenerCallback = (data) => {
      if ('updateUserContext' === data.payload.event) {
        const updatedContext = data.payload.data.userContext;
        localStorage.setItem('userContext', Buffer.from(JSON.stringify(updatedContext)).toString('base64'));
        setUserContext(updatedContext);
      }
    };

    Hub.listen(HUB_CHANNEL_NIC_CONSOLE_USER_CONTEXT, userContextHubListenerCallback);

    Auth.currentAuthenticatedUser()
      .then(async (data) => {
        try {
          let accountContext = await getAccountContext(data);
          if (accountContext) {
            setAuthState(AUTH_STATE_SIGNED_IN);
            setUserContext(accountContext.userContext);
            setAuthError(false);
          } else {
            handleAuthError(NO_ACCESS_MESSAGE);
          }
        } catch (error) {
          handleAuthError();
        }
      })
      .catch(() => {
        resetAuthState();
      });

    window.addEventListener('resize', handleOnWindowResize);
    handleOnWindowResize();
    return () => {
      window.removeEventListener("resize", handleOnWindowResize)
      Hub.remove(HUB_CHANNEL_AUTH, authHubListenerCallback);
      Hub.remove(HUB_CHANNEL_NIC_CONSOLE_USER_CONTEXT, userContextHubListenerCallback);
    }
  }, []);

  const signOut = () => {
    setAuthState(AUTH_STATE_LOADING);

    Auth.signOut()
      .then(() => {
        resetAuthState();
      })
      .catch(() => {
        resetAuthState();
      });
    localStorage.clear();
  }

  const resetAuthState = () => {
    setAuthState(AUTH_STATE_NO_LOGIN);
    setAuthError(false);
    setUserContext(undefined);
  }

  const handleAuthError = (message) => {
    setAuthError(message);
    setAuthState(AUTH_STATE_NO_LOGIN);
    setUserContext(undefined);
  }

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  }

  const handleOnWindowResize = () => {
    setShowSearch(window.innerWidth > NAV_HEADER_BREAKPOINT);

    if (window.innerWidth <= SIDE_NAV_BREAKPOINT) {
      setDrawerType("modal");
      setSideNavOpen(false);
    } else {
      setDrawerType("dismissable");
      setSideNavOpen(true);
    }
  }

  const closeDrawer = () => {
    setSideNavOpen(false);
  }

  return (
    <BrowserRouter>
      <RouteChangeObserver>
        <div className="App">
          <ForgeScaffold style={{ height: "100vh" }}>
            <ForgeAppBar slot="header">
              {
                authState === AUTH_STATE_SIGNED_IN && (
                  <ForgeAppBarMenuButton slot="start" onClick={toggleSideNav}></ForgeAppBarMenuButton>
                )
              }
              <ConsoleHeader authState={authState} signOut={signOut} userContext={userContext} showSearch={showSearch} />
            </ForgeAppBar>
            {authState === AUTH_STATE_LOADING && (
              <div>
                <ForgeCircularProgress></ForgeCircularProgress>
              </div>
            )}
            {authState === AUTH_STATE_NO_LOGIN && <Login authState={authState} authError={authError} {...props} />}
            {
              authState === AUTH_STATE_SIGNED_IN && (
                <Routes childProps={{
                  authState,
                  userContext,
                  signOut,
                  sideNavOpen,
                  drawerType,
                  closeDrawer
                }} />
              )
            }
          </ForgeScaffold>
        </div>
      </RouteChangeObserver>
    </BrowserRouter>
  );
}

export default App;
