import React from 'react';
import { Link } from 'react-router-dom';

import {
  ForgeExpansionPanel,
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon,
  ForgeToolbar,
} from '@tylertech/forge-react';

import { handleSideNavLinkClick } from '../utility/Util';
import SideNavbarLink from './SideNavbarLink';
import { MspServicesConfig } from '../../config/MspServicesConfig';

const SideNavbarContent = ({ service, activeSideNavbarLabel, closeDrawer }) => {

  const ServicesLinksList = () => (
    Object.keys(MspServicesConfig)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0
        }
      })
      .map((x, i) => {
        const { serviceLandingPage, serviceLabel, sideNavLogo } = MspServicesConfig[x];
        return (
          <Link
            key={`msp-admin-sidenav-service-link${i}`}
            to={`${serviceLandingPage}`}
            onClick={() => handleSideNavLinkClick(closeDrawer)}
          >
            <ForgeListItem>
              <ForgeIcon name={sideNavLogo} slot="leading"></ForgeIcon>
              {serviceLabel}
            </ForgeListItem>
          </Link>
        )
      })
  );

  return (
    <>
      <ForgeToolbar className="msp-admin-sidebar-header" slot="header">
        <ForgeList slot="center">
          <Link to="/msp-admin/home" onClick={() => handleSideNavLinkClick(closeDrawer)}>
            <ForgeListItem>
              <ForgeIcon name="home" slot="leading"></ForgeIcon>
              MSP Admin
            </ForgeListItem>
          </Link>
        </ForgeList>
      </ForgeToolbar>
      {
        service ? (
          <>
            <div className="msp-admin-sidebar-sublinks">
              <p className="forge-typography--body1">{service.serviceLabel}</p>
              <ForgeList>
                {
                  service.sideNavbarConfig &&
                  service.sideNavbarConfig.map((sideNavbarLink, i) => (
                    <SideNavbarLink
                      key={`service-${i}`}
                      serviceName={service.serviceName}
                      sideNavbarLink={sideNavbarLink}
                      activeSideNavbarLabel={activeSideNavbarLabel}
                      isLastItem={(service.sideNavbarConfig.length - 1) === i}
                      closeDrawer={closeDrawer}
                    />
                  ))
                }
              </ForgeList>
            </div>
          </>
        ) : (
          <div className="msp-admin-sidebar-services-nav">
            <div className="msp-admin-sidebar-sublinks">
              <ForgeExpansionPanel open={false}>
                <ForgeListItem slot="header">
                  <span className="forge-typography--body1">Services</span>
                  <ForgeOpenIcon slot="trailing" />
                </ForgeListItem>
                <ForgeList>
                  <ServicesLinksList />
                </ForgeList>
              </ForgeExpansionPanel>
            </div>
          </div>
        )
      }
      <div className="msp-admin-sidebar-sublinks">
        <ForgeExpansionPanel open={true}>
          <ForgeListItem slot="header">
            <span className="forge-typography--body1">Insights</span>
            <ForgeOpenIcon slot="trailing" />
          </ForgeListItem>
          <ForgeList>
            <Link
              to={'/msp-admin/service-usage'}
              onClick={() => handleSideNavLinkClick(closeDrawer)}
            >
              <ForgeListItem active={'Service Usage' === activeSideNavbarLabel} selected={'Service Usage' === activeSideNavbarLabel}>
                <ForgeIcon name="show_chart" slot="leading"></ForgeIcon> Service Usage
              </ForgeListItem>
            </Link>
            <Link
              to={'/msp-admin/service-limits'}
              onClick={() => handleSideNavLinkClick(closeDrawer)}
            >
              <ForgeListItem active={'Service Limits' === activeSideNavbarLabel} selected={'Service Limits' === activeSideNavbarLabel}>
                <ForgeIcon name="bar_chart" slot="leading"></ForgeIcon> Service Limits
              </ForgeListItem>
            </Link>
            <Link
              to={`/msp-admin/notifications-quotas`}
              onClick={() => handleSideNavLinkClick(closeDrawer)}
            >
              <ForgeListItem active={'Notifications Quotas' === activeSideNavbarLabel} selected={'Notifications Quotas' === activeSideNavbarLabel}>
                <ForgeIcon name="sms" slot="leading"></ForgeIcon> Notifications Quotas
              </ForgeListItem>
            </Link>
          </ForgeList>
        </ForgeExpansionPanel>
      </div>
      <ForgeToolbar className="msp-admin-sidebar-footer" slot="footer" inverted={true}>
        <ForgeList slot="center" static={true}>
          <Link to="/home" onClick={() => handleSideNavLinkClick(closeDrawer)}>
            <ForgeListItem>
              <ForgeIcon name="home" slot="leading"></ForgeIcon> Console Home
            </ForgeListItem>
          </Link>
        </ForgeList>
      </ForgeToolbar>
    </>
  );
}
export default SideNavbarContent;
