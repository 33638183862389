import React from 'react';
import { Link } from 'react-router-dom';

import {
    ForgeDrawer,
    ForgeIcon,
    ForgeList,
    ForgeListItem,
    ForgeModalDrawer,
    ForgeToolbar
} from '@tylertech/forge-react';

import { handleSideNavLinkClick } from '../utility/Util';
import { ProductsConfig } from '../../config/ProductsConfig'

const ConsoleSideNav = ({ sideNavOpen, drawerType = "dismissable", closeDrawer, userFavoriteProducts = [] }) => {

    const userFavorites = userFavoriteProducts
        .map(x => ProductsConfig.find(y => x === y.id))
        .map(x => ({
            name: x.name,
            productType: x.productType,
            link: 'internal' === x.productType ? x.internalUrl : x.externalUrl,
        })).filter(x => x.link);

    const DrawerContent = () => (
        <>
            <ForgeToolbar slot="header" style={{ '--forge-toolbar-padding': '0' }}>
                <ForgeList slot="center" style={{ 'width': '100%' }}>
                    <Link to="/home" onClick={() => handleSideNavLinkClick(closeDrawer)}>
                        <ForgeListItem>
                            <ForgeIcon name="home" slot="leading"></ForgeIcon>
                            Home
                        </ForgeListItem>
                    </Link>
                </ForgeList>
            </ForgeToolbar>
            <ForgeList>
                <Link to="/product-catalog" onClick={() => handleSideNavLinkClick(closeDrawer)}>
                    <ForgeListItem>
                        <ForgeIcon name="search" slot="leading"></ForgeIcon>
                        Product Catalog
                    </ForgeListItem>
                </Link>
            </ForgeList>
            {
                userFavorites.length > 0 && (
                    <>
                        <p className="forge-typography--body1">Favorite Products</p>
                        <ForgeList>
                            {
                                userFavorites.map((x, i) => {
                                    return "internal" === x.productType ? (
                                        <Link to={x.link} key={`favorite-link-${i}`} onClick={() => handleSideNavLinkClick(closeDrawer)}>
                                            <ForgeListItem>
                                                {x.name}
                                            </ForgeListItem>
                                        </Link>
                                    ) : (
                                        <a target="_blank" key={`favorite-link-${i}`} rel="noopener noreferrer" href={x.link}>
                                            <ForgeListItem>
                                                <ForgeIcon name="arrow_top_right" slot="trailing"></ForgeIcon>
                                                {x.name}
                                            </ForgeListItem>
                                        </a>
                                    )
                                })
                            }
                        </ForgeList>
                    </>
                )
            }
        </>
    );

    return (
        drawerType === "dismissable" ? (
            <ForgeDrawer open={sideNavOpen}>
                <DrawerContent />
            </ForgeDrawer>
        ) : (
            <ForgeModalDrawer open={sideNavOpen}>
                <DrawerContent />
            </ForgeModalDrawer>
        )
    );
}

export default ConsoleSideNav;
