import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { API, Cache } from 'aws-amplify';

import { ForgeCard } from '@tylertech/forge-react';

import { MetricCard, MetricCardSkeleton } from '../MetricCard';
import MspPageLayout from '../MspPageLayout';

Cache.configure({
  defaultTTL: 900000
});

const NotificationsSmsSection = ({ tenantId }) => {
  const [isLoadingNotificationsSmsLimits, setIsLoadingNotificationsSmsLimits] = useState(true);
  const [notificationsSmsLimits, setNotificationsSmsLimits] = useState([]);
  const cacheKeyBase = "msp-admin-sms-limits";

  useEffect(() => {
    const getNotificationsSmsLimits = async () => {
      setIsLoadingNotificationsSmsLimits(true);
      try {
        const cacheKey = `${cacheKeyBase}-${tenantId}`;
        const cachedLimits = await Cache.getItem(cacheKey);
        if (cachedLimits == null) {
          const response = await API.get('admin_api', '/sms-limits');
          const limits = []
            .concat({ name: "Monthly Send Quota", limitValue: `${formatNumber(response.monthlyQuotaLimit)} messages` })
            .concat({ name: "Current Monthly Usage", limitValue: `${formatNumber(response.currentMonthlyUsage)} messages` });
          setNotificationsSmsLimits(limits);
          Cache.setItem(cacheKey, limits);
        } else {
          setNotificationsSmsLimits(cachedLimits);
        }
      } catch (error) {
        console.error("Error retrieving notifications sms limits", error);
        setNotificationsSmsLimits([]);
      }
      setIsLoadingNotificationsSmsLimits(false);
    };

    getNotificationsSmsLimits();
  }, [tenantId]);

  return (
    <LimitsSection
      sectionName="Notifications SMS Messages"
      limits={notificationsSmsLimits}
      description="The following quotas & limits are applied to your tenant when sending SMS messages using Notifications Service"
      loadingCardsSize={2}
      isLoading={isLoadingNotificationsSmsLimits}
    />
  )
};

const NotificationsEmailSection = ({ tenantId }) => {
  const [notificationsEmailLimits, setNotificationsEmailLimits] = useState([]);
  const [isLoadingNotificationsEmailLimits, setIsLoadingNotificationsEmailLimits] = useState(false);
  const cacheKeyBase = "msp-admin-email-limits"

  useEffect(() => {
    const getNotificationsEmailLimits = async () => {
      setIsLoadingNotificationsEmailLimits(true);
      try {
        const cacheKey = `${cacheKeyBase}-${tenantId}`;
        const cachedLimits = await Cache.getItem(cacheKey);
        if (cachedLimits == null) {
          const response = await API.get('admin_api', '/email-limits');
          const limits = []
            .concat({ name: "Max Send Per 24 Hours", limitValue: `${formatNumber(response.max24HourSend)} Emails` })
            .concat({ name: "Max Send Per Second", limitValue: `${formatNumber(response.maxSendRate)} Emails` })
            .concat({ name: "Bounce Limit Per Hour", limitValue: `${formatNumber(response.bounceLimit)} Emails` });
          setNotificationsEmailLimits(limits);
          Cache.setItem(cacheKey, limits);
        } else {
          setNotificationsEmailLimits(cachedLimits);
        }
      } catch (error) {
        setNotificationsEmailLimits([]);
        console.error("Error retrieving notifications email limits", error);
      }
      setIsLoadingNotificationsEmailLimits(false);
    };

    getNotificationsEmailLimits();

  }, [tenantId]);

  return (
    <LimitsSection
      sectionName="Notifications Emails"
      limits={notificationsEmailLimits}
      description="The following quotas & limits are applied to your tenant when sending Emails using MSP Notifications Service"
      loadingCardsSize={3}
      isLoading={isLoadingNotificationsEmailLimits}
    />
  )
}

const LimitsSection = ({
  sectionName = "",
  description = "",
  limits = [],
  isLoading = true,
  loadingCardsSize = 2
}) => (
  <>
    <h3 className="forge-typography--headline5">{sectionName}</h3>
    <p className="forge-typography--subtitle1">{description}</p>
    <div className="msp-admin-service-card-group">
      {
        isLoading ? (
          <>
            {
              [...Array(loadingCardsSize)].map((x, i) => (
                <MetricCardSkeleton key={`msp-${sectionName}-loading-limit-card-${i}`} />
              ))
            }
          </>
        ) : (
          <>
            {
              limits.map((x, i) => (
                <MetricCard
                  key={`msp-${sectionName}-limit-card-${i}`}
                  name={x.name}
                  metricValue={x.limitValue}
                />
              ))
            }
          </>
        )
      }
    </div>
  </>
);

const formatNumber = (n = 0) => {
  let formatted = typeof n == "string" ? +n : n;
  return isNaN(formatted) ? 0 : formatted.toLocaleString();
};

const MspNotificationsQuotas = ({ userContext = {} }) => {
  const { setBodyTitle, setBreadcrumbs, setActiveSideNavbarLabel, setSideNavServiceConfig } = useOutletContext();

  useEffect(() => {
    setBodyTitle(false);
    setSideNavServiceConfig(false)
    setActiveSideNavbarLabel('Notifications Quotas');
    setBreadcrumbs([
      { link: "/msp-admin/home", label: "MSP Admin" },
      { label: "Notifications Quotas" }
    ]);
  }, [setBodyTitle, setActiveSideNavbarLabel, setBreadcrumbs, setSideNavServiceConfig]);

  return (
    <MspPageLayout>
      <ForgeCard>
        <h2 className="forge-typography--headline4">Notifications Quotas</h2>
        <p className="forge-typography--body1">
          Some Notification Service functionality is subject to additional quotas; these are in addition to any Notifications Service
          limits defined for your tenant. Please note that the data displayed on this page may be cached or could be in the process of being updated.
        </p>
        <div className="msp-admin-spacer-small"></div>
        <NotificationsSmsSection tenantId={userContext.tenantId} />
        <div className="msp-admin-spacer"></div>
        <NotificationsEmailSection tenantId={userContext.tenantId} />
      </ForgeCard>
    </MspPageLayout>
  )
}

export default MspNotificationsQuotas;
