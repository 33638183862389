import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { API, Hub } from 'aws-amplify';

import {
    ForgeCard,
    ForgeDrawer,
    ForgeModalDrawer,
    ForgeSkeleton,
    ForgeToast,
    ForgeToolbar
} from '@tylertech/forge-react';

import ConsoleBreadcrumbsNav from '../../nic-console/ConsoleBreadcrumbsNav';
import MspAccessRequiredPage from "./MspAccessRequiredPage";
import MspAccountsDropdown from '../MspAccountsDropdown';
import MspPageLayout from "../MspPageLayout";
import NotFound from "../../common/containers/NotFound";
import SideNavbarContent from "../SideNavbarContent";
import SkeletonBodyHeader from "../../common/SkeletonBodyHeader";
import { ServiceCardGroupSkeleton } from "../ServiceCard";

const SkeletonBody = () => (
    <MspPageLayout>
        <ForgeCard style={{ "height": "75vh" }}>
            <div>
                <ServiceCardGroupSkeleton />
                <div className="msp-admin-spacer" />
                <ServiceCardGroupSkeleton />
            </div>
        </ForgeCard>
    </MspPageLayout>
);

const MspAdminLayout = ({ childProps }) => {

    const { userContext, sideNavOpen, drawerType, closeDrawer } = childProps;
    const [accountList, setAccountList] = useState();
    const [sideNavServiceConfig, setSideNavServiceConfig] = useState();
    const [activeSideNavbarLabel, setActiveSideNavbarLabel] = useState();
    const [bodyTitle, setBodyTitle] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState(false);
    const [showSwitchedUserMessage, setShowSwitchedUserMessage] = useState(false);
    const [hasMspAccess, setHasMspAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [pageExists, setPageExists] = useState(true);

    const emitUpdateUserContextEvent = (userContext) => {
        Hub.dispatch('nic-console:user-context', {
            event: 'updateUserContext',
            data: {
                userContext: userContext
            },
            message: 'User has switched user'
        });
    };

    const handleUserContextUpdate = (event) => {
        // eslint-disable-next-line eqeqeq
        let updatedContext = accountList?.find(x => x.accountId == event.detail);
        updatedContext['isFederated'] = true;
        emitUpdateUserContextEvent(updatedContext);
        setShowSwitchedUserMessage(true);
    };

    useEffect(() => {
        const getTenantAccounts = async () => {
            setIsLoading(true);
            let tenantInfo;
            try {
                tenantInfo = await API.get('get_account_context', `/account.v1/msp/account-context`);
            } catch (error) {
                tenantInfo = false;
            }

            if (tenantInfo && [].concat(tenantInfo).length >= 1) {
                const currentContextAccountId = userContext?.accountId;

                // accountId is only added by call to account service so check if we have set this yet
                if (currentContextAccountId) {

                    // if for some reason the current account id no longer comes from account service
                    if (tenantInfo.find(x => x.accountId === currentContextAccountId) === undefined) {
                        setHasMspAccess(false);
                        setAccountList([]);
                    }
                } else {
                    // user hasn't set a msp account service account yet
                    const initialAccount = tenantInfo[0];
                    initialAccount['isFederated'] = true;
                    emitUpdateUserContextEvent(initialAccount);
                }
                setHasMspAccess(true);
                setAccountList(tenantInfo);
            } else {
                setAccountList([]);
                // if user logs in with AD but doesn't have tenant info they cannout use MSP admin
                setHasMspAccess(!userContext?.isFederated);
            }
            setIsLoading(false);
        };

        getTenantAccounts();
    }, [userContext]);

    return (
        <>
            {pageExists && (
                <>
                    <div slot="body-left" className="msp-side-nav">
                        {
                            isLoading ? (
                                <>
                                    <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                                    <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                                    <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                                </>
                            ) : (
                                <>
                                    {
                                        hasMspAccess && (
                                            drawerType === "dismissable" ? (
                                                <ForgeDrawer open={sideNavOpen}>
                                                    <SideNavbarContent
                                                        closeDrawer={closeDrawer}
                                                        activeSideNavbarLabel={activeSideNavbarLabel}
                                                        service={sideNavServiceConfig}
                                                    />
                                                </ForgeDrawer>
                                            ) : (
                                                <ForgeModalDrawer open={sideNavOpen}>
                                                    <SideNavbarContent
                                                        closeDrawer={closeDrawer}
                                                        activeSideNavbarLabel={activeSideNavbarLabel}
                                                        service={sideNavServiceConfig}
                                                    />
                                                </ForgeModalDrawer>
                                            )
                                        )
                                    }
                                </>
                            )
                        }
                    </div>

                    {
                        isLoading ? (
                            <SkeletonBodyHeader />
                        ) : (
                            hasMspAccess && (bodyTitle || breadcrumbs) && (
                                <ForgeToolbar slot="body-header" style={{ '--forge-toolbar-height': 'auto' }}>
                                    <div slot="start" style={{ alignSelf: 'center' }}>
                                        {
                                            bodyTitle && <p className="msp-admin-body-title">{bodyTitle}</p>
                                        }
                                        {
                                            breadcrumbs && <ConsoleBreadcrumbsNav breadcrumbs={breadcrumbs} />
                                        }
                                    </div>
                                    <div slot="end">
                                        {
                                            userContext && (
                                                <MspAccountsDropdown
                                                    accounts={accountList}
                                                    userContext={userContext}
                                                    onUserContextUpdate={handleUserContextUpdate}
                                                />
                                            )
                                        }
                                    </div>
                                </ForgeToolbar>
                            )
                        )
                    }

                    <div slot="body" style={{ 'paddingLeft': '16px', display: 'flex', flexDirection: 'column' }}>
                        <div className="nic-console-background"></div>
                        <div style={{ flex: "1 1 0", marginTop: "25px" }}>
                            {
                                isLoading ? (
                                    <SkeletonBody />
                                ) : (
                                    <>
                                        {
                                            hasMspAccess ? (
                                                <Outlet context={{
                                                    setBodyTitle,
                                                    setBreadcrumbs,
                                                    setSideNavServiceConfig,
                                                    setActiveSideNavbarLabel,
                                                    setPageExists
                                                }} />
                                            ) : (
                                                <MspAccessRequiredPage />
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            (hasMspAccess && userContext) && <ForgeToast options={{ "message": `You have successfully switched to user ${userContext.username} under tenant ${userContext.tenantId}` }} open={showSwitchedUserMessage} onDismiss={() => setShowSwitchedUserMessage(false)}></ForgeToast>
                        }
                    </div>
                </>
            )}
            {!pageExists && (
                <NotFound />
            )}
        </>
    )
}

export default MspAdminLayout;
