import { ForgeSkeleton, ForgeToolbar } from '@tylertech/forge-react';

const SkeletonBodyHeader = () => (
    <ForgeToolbar slot="body-header">
        <div className="nic-console-product-loading-header" slot="start">
            <ForgeSkeleton text style={{ "width": "15rem", "marginRight": "1rem" }} />
        </div>
    </ForgeToolbar>
)

export default SkeletonBodyHeader;
