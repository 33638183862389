import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

const CustomMarkdownAnchor = ({ href, children }) => {
    if (href.startsWith("/")) {
        return <Link to={href}>{children}</Link>;
    } else if (href.startsWith("#")) {
        return <a href={href}>{children}</a>;
    } else {
        return (
            <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        );
    }
};

const MarkdownContent = ({ markdown }) => (
    <ReactMarkdown
        children={markdown}
        components={{
            a: CustomMarkdownAnchor,
            p: ({children}) => <p className="forge-typography--body1">{children}</p>
        }}
    />
)

export default MarkdownContent;
