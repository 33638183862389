import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';

const InlineInfoMessage = ({ message = "" }) => (
  <div style={{ "maxWidth": "50%" }}>
    <ForgeInlineMessage theme="info">
      <ForgeIcon name="info" slot="icon"></ForgeIcon>
      <div>{message}</div>
    </ForgeInlineMessage>
  </div>
);

export default InlineInfoMessage;
