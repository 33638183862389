/* eslint-disable react/display-name */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConsoleHome from './components/nic-console/containers/ConsoleHome';
import ConsoleLayout from './components/nic-console/ConsoleLayout';
import ConsoleSearchResultsPage from './components/nic-console/containers/ConsoleSearchResultsPage';
import MspAdminLayout from './components/msp-admin/containers/MspAdminLayout';
import MspHome from './components/msp-admin/containers/MspHome';
import MspNotificationsQuotas from './components/msp-admin/containers/MspNotificationsQuotas';
import MspServiceLandingPage from './components/msp-admin/containers/MspServiceLandingPage';
import MspServiceLimitsPage from './components/msp-admin/containers/MspServiceLimitsPage';
import MspServiceUsagePage from './components/msp-admin/containers/MspServiceUsagePage';
import NotFound from './components/common/containers/NotFound';
import ProductCatalogPage from './components/nic-console/containers/ProductCatalogPage';
import ProductPage from './components/nic-console/containers/ProductPage';
import ServiceHome from './components/msp-admin/containers/service/ServiceHome';
import ViewArticle from './components/nic-console/containers/ViewArticle';

const routes = ({ childProps }) => (
    <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<ConsoleLayout childProps={childProps} />}>
            <Route path="/home" element={<ConsoleHome />} />
            <Route path="/product-catalog" element={<ProductCatalogPage />} />
            <Route path="/product-catalog/:productId" element={<ProductPage />} />
            <Route path="/articles/:articleId" element={<ViewArticle />} />
            <Route path="/search-results" element={<ConsoleSearchResultsPage />} />
            <Route index element={<ConsoleHome />} />
        </Route>
        <Route path="/msp-admin" element={<MspAdminLayout childProps={childProps} />}>
            <Route path="home" element={<MspHome />} />
            <Route path="services/:serviceName" element={<MspServiceLandingPage />} />
            <Route path="services/:serviceName/*" element={<ServiceHome userContext={childProps.userContext} />} />
            <Route path="notifications-quotas" element={<MspNotificationsQuotas userContext={childProps.userContext}/>} />
            <Route path="service-limits" element={<MspServiceLimitsPage userContext={childProps.userContext} />} />
            <Route path="service-usage" element={<MspServiceUsagePage userContext={childProps.userContext} />} />
        </Route>
    </Routes>
);

export default routes;
