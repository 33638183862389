import * as JsSearch from 'js-search';

import { ProductsConfig } from '../../config/ProductsConfig';
import { ConsolePages } from '../../config/ConsolePages';
import { Articles } from './content/Articles';
import { MspServicesConfig } from '../../config/MspServicesConfig';

const consolePageSearch = new JsSearch.Search('value');
consolePageSearch.addIndex('label');
consolePageSearch.addIndex('description');
consolePageSearch.addIndex('extendedDescription');
consolePageSearch.addIndex('tags');

let consolePageDocuments = [];

// add console pages
consolePageDocuments = consolePageDocuments.concat([].concat(ConsolePages).map(x => ({
    value: x.path,
    label: x.title,
    description: x.description,
    sectionTitle: 'Console Pages'
})));

// add product catalog pages
consolePageDocuments = consolePageDocuments.concat([].concat(ProductsConfig).map(x => ({
    value: `/product-catalog/${x.id}`,
    label: `Product Catalog: View ${x.name}`,
    description: x.description,
    extendedDescription: x.catalogProfile?.description,
    tags: x.tags,
    sectionTitle: "Product Catalog"
})));

// add articles
consolePageDocuments = consolePageDocuments.concat([].concat(Object.keys(Articles).map(x => Articles[x])).map(x => ({
    value: `/articles/${x.id}`,
    label: `View Article - ${x.headline}`,
    description: x.subtitle,
    sectionTitle: "Articles"
})));

// add msp admin pages
consolePageDocuments = consolePageDocuments.concat(Object.keys(MspServicesConfig).reduce((results, serviceKey) => {
    const service = MspServicesConfig[serviceKey];
    results.push({
        label: `MSP Admin - ${service.serviceLabel} Home`,
        description: service.serviceDescription,
        value: service.serviceLandingPage,
        tags: [
            "msp"
        ],
        sectionTitle: "MSP Admin"
    });

    //add msp service specific pages
    results = results.concat(service.sideNavbarConfig.reduce((previous, current) => {
        const createSublinkResult = (sublink) => ({
            label: `${service.serviceLabel} - ${sublink.label}`,
            value: `/msp-admin/services/${service.serviceName}/${sublink.path}`,
            description: '',
            tags: [
                "msp",
                service.serviceLabel
            ],
            sectionTitle: "MSP Admin"
        });

        return previous.concat(current.sublinks ? (
            current.sublinks.map(y => createSublinkResult(y))
        ) : (createSublinkResult(current)));

    }, []));
    return results;
}, []));

// manually adds other msp admin pages
consolePageDocuments = consolePageDocuments.concat([
    {
        label: 'MSP Admin - Service Limits',
        value: '/msp-admin/service-limits',
        description: 'MSP Admin Service Limits',
        tags: [
            "msp",
            "quotas",
            "limits",
            "services"
        ],
        sectionTitle: "MSP Admin"
    },
    {
        label: 'MSP Admin - Notifications Quotas',
        value: '/msp-admin/notifications-quotas',
        description: 'MSP Admin Notifications Quotas',
        tags: [
            "msp",
            "quotas",
            "limits",
            "notifications",
            "sms",
            "emails"
        ],
        sectionTitle: "MSP Admin"
    }
])

consolePageSearch.addDocuments(consolePageDocuments);

const searchConsolePages = (x, options = { grouped: false }) => {
    const results = consolePageSearch.search(x);

    return !options.grouped ? results : results.reduce((previous, current) => {
        const section = previous.find(x => x.text === current.sectionTitle);
        if (section) {
            section.options = section.options.concat(current);
        } else {
            previous = previous.concat({
                text: current.sectionTitle,
                options: [].concat(current)
            });
        }
        return previous;
    }, []);
};

export { searchConsolePages }
