export const ProductsConfig = [
    {
        "id": "msp-admin",
        "name": "MSP Admin",
        "description": "Use various and manage various MSP services",
        "featuredDescription": "MSP Admin allows you to interact with various MSP services",
        "internalUrl": "/msp-admin/home",
        "productType": "internal",
        "catalogProfile": {
            "description": "This description is different than the one used above, which is for the cards.",
            "productLogo": "msp.svg",
            slackChannel: {
                channel: "msp-users",
                channelId: "C7UJDF6PM"
            },
            "supportContact": {
                "name": "Deb Kilpatrick",
                "email": "Deb.Kilpatrick@egov.com",
            },
            "relatedProducts": [

            ]
        },
        "tags": [
            "code-service-builder",
            "identity",
            "foundation"
        ]
    },
    {
        "id": "access-gov",
        "name": "AccessGov",
        "description": "Citizen portal that provides easy access to state, county, and city government",
        "productType": "external",
        "externalUrl": "https://accessgov.com/",
        "catalogProfile": {
            "productLogo": "access-gov.png",
            "description": "Platform that offers unified management of, better integration with, and cross-sale of NIC's exceptional vertical market products. The goal is to offer partners a single place to manage all of their eGovernment services and their constituents a single place to engage with them.  The true value of the platform is derived from the products it delivers.  AccessGov not only unifies NICs disconnected products, but also brings several new products to market including AccessGov Sites (AccessGov's flagship product), the first NIC self-services citizen-engagement and web form product.",
            "slackChannel": {
                channel: "accessgov-users",
                channelId: "C01LAATN97F"
            },
            "supportContact": {
                "name": "Joshua Morasko",
                "email": "Josh.Morasko@egov.com",
            },
            "relatedProducts": [
                {
                    label: 'AccessGov Engage',
                    id: 'access-gov-engage'
                },
                {
                    label: 'AccessGov Enterprise',
                    id: 'access-gov-enterprise'
                }
            ]
        },
        "tags": [

        ]
    },
    {
        "id": "access-gov-engage",
        "name": "AccessGov Engage",
        "description": "Allows partners to create portals to engage constituents",
        "productType": "external",
        "externalUrl": "https://accessgov.com/",
        "catalogProfile": {
            "productLogo": "access-gov.png",
            "description": "Partners can create contact pages, web content, and rich web forms, and index their existing PDF forms and third-party services, offering a single point for citizens to find what they are looking for.",
            "slackChannel": {
                channel: "accessgov-users",
                channelId: "C01LAATN97F"
            },
            "supportContact": {
                "name": "Joshua Morasko",
                "email": "Josh.Morasko@egov.com",
            },
            "relatedProducts": [
                {
                    label: 'AccessGov',
                    id: 'access-gov'
                },
                {
                    label: 'AccessGov Enterprise',
                    id: 'access-gov-enterprise'
                }
            ]
        },
        "tags": [

        ]
    },
    {
        "id": "access-gov-enterprise",
        "name": "AccessGov Enterprise",
        "description": "Uses AccessGov Sites to partner intranets and employee portals",
        "productType": "external",
        "externalUrl": "https://accessgov.com/",
        "catalogProfile": {
            "productLogo": "access-gov.png",
            "description": "Enterprise also adds Active Directory integration and workflow and document storage based on the selected tier.",
            "supportContact": {
                "name": "Joshua Morasko",
                "email": "Josh.Morasko@egov.com",
            },
            "slackChannel": {
                channel: "accessgov-users",
                channelId: "C01LAATN97F"
            },
            "relatedProducts": [
                {
                    label: 'AccessGov',
                    id: 'access-gov'
                },
                {
                    label: 'AccessGov Engage',
                    id: 'access-gov-engage'
                }
            ]
        },
        "tags": [

        ]
    },
    {
        "id": "ach-express",
        "name": "ACH Express",
        "description": "Provides ACH processing services",
        "productType": "external",
        "externalUrl": "https://achexpress.cdc.nicusa.com/achx",
        "catalogProfile": {
            "description": "ACH Express provides ACH processing services.",
            "slackChannel": {
                channel: "achx-users",
                channelId: "CNW6W065N"
            },
            "supportContact": {
                "name": "Mia Stine",
                "email": "Mia.Stine@egov.com",
            },
            "relatedProducts": [
                {
                    label: 'CCP',
                    id: 'ccp'
                },
                {
                    label: "CDB",
                    id: "cdb"
                }
            ]
        },
        "tags": [
            "payments"
        ]
    },
    {
        "id": "app-engine",
        "name": "AppEngine",
        "description": "Provides a low-code application development platform",
        "productType": "external",
        "externalUrl": "https://appengine.egov.com/apps/Account/Login",
        "catalogProfile": {
            "productLogo": "app-engine.png",
            "description": "By using AppEngine, NIC portals can reduce development, maintenance, and support costs involved in bringing new revenue generating projects to market.",
            "slackChannel": {
                channel: "appengine-users",
                channelId: "C0D9X3KEJ"
            },
            "supportContact": {
                "name": "Indira Yadali",
                "email": "Indira Yadali@egov.com",
            },
            "relatedProducts": [
                {
                    label: "MSP Admin",
                    id: "msp-admin"
                },
                {
                    label: "OnTheGo",
                    id: "onthego"
                }
            ]
        },
        "tags": [
            "code-service-builder"
        ]
    },
    {
        "id": "ccp",
        "name": "CCP",
        "description": "Fully hosted PCI compliant checkout pages for credit card and eCheck",
        "catalogProfile": {
            "description": "Provides an easy to use checkout module that alleviates the major costs incurred by our partners in meeting the obligations of securing their customer's data. With CCP, NIC provides a fully hosted set of checkout pages for credit card and eCheck that are PCI compliant",
            "slackChannel": {
                channel: "ccp-users",
                channelId: "C0DA11ZUH",
            },
            "supportContact": {
                "name": "Lauren Barney",
                "email": "lauren.barney@egov.com"
            },
            "relatedProducts": [
                {
                    label: 'ACH Express',
                    id: 'ach-express'
                },
                {
                    label: "CDB",
                    id: "cdb"
                }
            ]
        },
        "tags": [
            "payments"
        ]
    },
    {
        id: "cdb",
        name: "CDB",
        description: "Provides customer management for NIC portal bulk-data subscribers",
        catalogProfile: {
            description: "Implemented for all NIC Portals to manage subscribers who purchase bulk data and pay via a monthly invoice. CDB tracks transactions, fees, and receivables. It also manages reporting, customer setup, and customer authentication.",
            slackChannel: {
                channel: "cdb-users",
                channelId: "C0LAGMH1P"
            },
            supportContact: {
                "name": "Mia Stine",
                "email": "Mia.Stine@egov.com",
            },
            relatedProducts: [
                {
                    label: "ACH Express",
                    id: "ach-express"
                },
                {
                    label: "CCP",
                    id: "ccp"
                },
            ]
        },
        tags: [
            "payments"
        ]
    },
    {
        id: "elp",
        name: "ELP",
        description: "Provides statewide consolidation of licensing and permitting applications and renewals",
        productType: "external",
        externalUrl: "https://apps.lnpweb.com/authenticate/elp",
        catalogProfile: {
            description: "ELP provides agency-level administration and configuration of licenses & permits Unique Citizen level profile accessible by all license types/Agencies, profile available across multiple NIC enterprise products Cloud-based infrastructure with application components leveraging reusable MSP (Microservice Platform) and CCP (Common Checkout Page)/TPE (Transaction Processing Engine) products",
            productLogo: "elp.png",
            slackChannel: {
                channel: "elp-users",
                channelId: "CJ16CTM3M",
            },
            supportContact: {
                name: "Jessica Ehrlich",
                email: "Jessica.Ehrlich.egov.com"
            },
            relatedProducts: [
                {
                    label: "MSP Admin",
                    id: "msp-admin"
                },
                {
                    label: "CCP",
                    id: "ccp"
                },
                {
                    label: 'OneOutdoor',
                    id: "oneoutdoor"
                },
                {
                    label: 'NIC Licensing Solution',
                    id: "nic-licensing-solution"
                }
            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "event-registration",
        name: "Event Registration",
        description: "Enables users to easily create, organize and manage a variety of events",
        productType: "external",
        externalUrl: "https://events.egov.com/eventreg/ns/login.htm",
        catalogProfile: {
            description: "The Enterprise Event Registration application is an online event management solution that enables users to easily create, organize and manage a variety of events. The system automates key tasks such as registration, reporting, payment acceptance and event management. The application is a multi-tenant solution allowing each portal to leverage the core Event Registration functionality with styling and content that is customizable for each portal.",
            slackChannel: {
                channel: "event-reg",
                channelId: "C1EE4081W",
            },
            supportContact: {
                name: "Jamie Hennen",
                email: "jamie.hennen@egov.com"
            },
            relatedProducts: [
            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "gov2go",
        name: "Gov2Go",
        description: "Provides citizens with access to their government services",
        productType: "external",
        externalUrl: "https://portaladmin.getgov2go.com",
        catalogProfile: {
            description: "Gov2Go was designed to provide citizens with access to their government services where and when they need it: at home or on the go. Along with some national services, Gov2Go currently works with 16 states and countless localities to deliver services like vehicle tag renewals, property taxes, park permits, and professional licenses. With features like one-click payments, shopping carts to complete multiple government transactions at once, personalized deadline reminders, and single sign-on with existing state credentials, Gov2Go helps make sure agencies are collecting fees on time and with ease.",
            productLogo: "gov2go.png",
            slackChannel: {
                channel: "gov2go-users",
                channelId: "C0DA10ZDG",
            },
            supportContact: [
                {
                    name: "Katie Freeman",
                    email: "Katie.Freeman@egov.com"
                },
                {
                    name: "Hannah Howard",
                    email: "Hanna.Howard@egov.com"
                }
            ],
            relatedProducts: [
                {
                    label: 'Gov2Go Pay',
                    id: 'gov2go-pay'
                }
            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "gov2go-pay",
        name: "Gov2GoPay",
        description: "Allows users to create an account where they can store payment information",
        productType: "external",
        externalUrl: "https://gov2gopay-admin.egov.com/",
        catalogProfile: {
            description: "The Gov2Go Pay application is an enterprise-wide service that allows users to create an account where they can store payment information. This allows them to quickly complete online transactions without having to enter the payment information each time. The system is secure and has been developed with a fully responsive design allowing it to display on computers, tablets, and phones. The application can be accessed directly through the Portal site, the main site of Gov2Go Pay where users can create an account, add addresses and payment information, and view their transaction history. It can also be accessed from CCP, portal, and partner applications to allow users to pay with an existing Gov2Go Pay account, or the user may sign up for a new account and provide the payment information prior to completing the transaction.",
            productLogo: "gov2go-pay.png",
            slackChannel: {
                channel: "gov2go-users",
                channelId: "C0DA10ZDG"
            },
            supportContact: [
                {
                    name: "Katie Freeman",
                    email: "Katie.Freeman@egov.com"
                },
                {
                    name: "Hannah Howard",
                    email: "Hanna.Howard@egov.com"
                }
            ],
            relatedProducts: [
                {
                    label: "Gov2Go",
                    id: "gov2go"
                },
            ]
        },
        tags: [
            "payments",
            "citizen-engagement"
        ]
    },
    {
        id: "nic-licensing-solution",
        name: "NIC Licensing Solution",
        catalogProfile: {
            description: "NIC Licensing solutions address the unmet needs of the rapidly evolving cannabis regulatory industry. Our comprehensive platform includes a citizen and regulator portal, patient, physician, and caregiver registry, business licensing and employee credentialing, all custom built for government and fully configurable to meet the unique needs of each state.",
            productLogo: "nic-licensing-solution.png",
            slackChannel: {
                channel: "nls-updates",
                channelId: "nls-updates"
            },
            supportContact: {
                name: "Liz Kotowski",
                email: "Elizabeth.Kotowski@egov.com"
            },
            relatedProducts: [
                {
                    label: "ELP",
                    id: "elp"
                },
                {
                    label: "OneOutdoor",
                    id: "oneoutdoor"
                }
            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "otc",
        name: "OTC",
        description: "PCI compliant system for taking point of sale payments",
        productType: "external",
        externalUrl: "https://otc.cdc.nicusa.com/nics/login.aspx",
        catalogProfile: {
            description: "NIC provides OTC as a full service module that includes integration to credit card swipe devices as well as check imagers and scanners. The system includes a dynamic page rendering engine that allows for customized input fields based on the agency and service. OTC also provides a backend administration module for reporting, customer service, and setup of cashiers, services, and pricing.",
            slackChannel: {
                channel: "otc-users",
                channelId: "C0T42R92N"
            },
            supportContact: {
                name: "Lauren Barney",
                email: "lauren.barney@egov.com"
            },
            relatedProducts: [
                {
                    label: "OnTheGo Pay",
                    id: "onthego-pay"
                },
            ]
        },
        tags: [
            "payments",
        ]
    },
    {
        id: "oneoutdoor",
        name: "OneOutdoor",
        description: "Recreational and commercial licensing system",
        catalogProfile: {
            description: "OneOutdoor platform offers a best-of-breed solution to meet the essential needs of the outdoor agency decision-makers by combining proven technology, revenue growth strategies, and government know-how. OneOutdoor features a user-friendly interface and can be configured to solve agency problems. The platform is flexible and scalable to deliver consistent results and widely trusted for licensing and permits, lotteries and draws, wildlife management, enforcement, safety education, secure payments, and management and administration. The high-performance licensing solution includes hunting, commercial and recreational fishing, watercraft, and recreational vehicle registration and renewals.",
            productLogo: "oneoutdoor.png",
            slackChannel: {
                channel: "oneoutdoor_customer",
                channelId: "C01LNJDDW2H"
            },
            supportContact: {
                name: "James Nielsen",
                email: "james.nielsen@egov.com"
            },
            relatedProducts: [
                {
                    label: "ELP",
                    id: "elp"
                },
                {
                    label: "NIC Licensing Solution",
                    id: "nic-licensing-solution"
                },

            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "onthego",
        name: "OnTheGo",
        description: "Mobile inspection form creation and management",
        productType: "external",
        externalUrl: "https://nic-mobileinspections.com/login/auth",
        catalogProfile: {
            description: "Streamlines form creation, improves form usability, and enhances form data management for both online and offline transactions.",
            productLogo: "onthego.png",
            slackChannel: {
                channel: "onthego-users",
                channelId: "C617VE7ML"
            },
            supportContact: {
                name: "Lauren Barney",
                email: "lauren.barney@egov.com"
            },
            relatedProducts: [
                {
                    label: "AppEngine",
                    id: "appengine"
                },
            ]
        },
        tags: [
            "code-service-builder"
        ]
    },
    {
        id: "onthego-pay",
        name: "OnTheGo Pay",
        description: "PCI Compliant point-of-sale payments",
        productType: "external",
        externalUrl: "https://onthegopay.egov.com/",
        catalogProfile: {
            description: "OnTheGo Pay is a fully PCI compliant system for taking payment at the point of sale. NIC provides OTC as a full service module that includes integration to credit card swipe devices as well as check imagers and scanners. The system includes a dynamic page rendering engine that allows for customized input fields based on the agency and service. OTC also provides a backend administration module for reporting, customer service, and setup of cashiers, services, and pricing",
            productLogo: "onthego.png",
            slackChannel: {
                channel: "otg-pay-users",
                channelId: "C0P9K3LQ3"
            },
            supportContact: {
                name: "Lauren Barney",
                email: "lauren.barney@egov.com"
            },
            relatedProducts: [
                {
                    label: "OTC",
                    id: "otc"
                },
                {
                    label: "OnTheGo",
                    id: "onthego"
                },
            ]
        },
        tags: [
            "payments",
        ]
    },
    {
        id: "rxgov",
        name: "RxGov",
        description: "NIC's PDMP platform",
        productType: "external",
        externalUrl: "https://mdpdmp.rxgov.com/login",
        catalogProfile: {
            description: "RxGov consolidates disparate information representing treatment options, education, enforcement and regulatory requirements, and prescriber patterns and volumes. Take control of your PDMP data. NIC's RxGov™ is a completely re-imagined PDMP platform",
            productLogo: "rxgov.png",
            slackChannel: {
                channel: "rxgov-inc-triage",
                channelId: "CS5V5CMGT"
            },
            supportContact: {
                name: "Tom Grimes",
                email: "tom.grimes@egov.com"
            },
            relatedProducts: [
            ]
        },
        tags: [
            "citizen-engagement"
        ]
    },
    {
        id: "campground-reservations-service",
        name: "Campground Reservations Service",
        description: "Campground reservations service",
        productType: "external",
        externalUrl: "https://camp.exploremoreil.com/admin/login",
        catalogProfile: {
            description: "Campground Reservations Service is a comprehensive solution which handles complex rules, high traffic, multiple user interfaces, and roles, and still offers an exemplary user experience. The solution is comprised of four main components. Public interface: allows campers to book and manage existing campground reservations, to print reservation permits as needed, to edit or change any reservation details, and to cancel reservations. Call center interface: allows call center employees to take reservations over the phone and to make changes to permits. This robust interface allows super administrators to put sites on hold in case a site is unavailable due to an emergency and create reservations that have special criteria. Administrators can also print batches of permits to be mailed to campers. Administrative interface: allows park rangers to view incoming campers and keep track of available sites. With more than a dozen custom reports, the interface gives park rangers all the tools they need to successfully manage visits to the park. Database: stores and tracks updates made through both the public and call center interfaces.",
            productLogo: "campground-reservations-service.png",
            slackChannel: {
                channel: "oneoutdoor-reservations",
                channelId: "CUBBMLBHD"
            },
            supportContact: {
                name: "Abhishek Mangal",
                email: "abhishek.mangal@egov.com"
            },
            relatedProducts: [
                {
                    label: "OneOutdoor",
                    id: "oneoutdoor"
                },
            ]
        },
        tags: [
            "citizen-engagement",
        ]
    },
    {
        id: "tpe-devops-admin",
        name: "TPE DevOps Admin",
        description: "Provides devops merchant administration",
        catalogProfile: {
            description: "Provides devops merchant administration",
            slackChannel: {
                channel: "tpe-admin-users",
                channelId: "CMM172FSQ"
            },
            supportContact: {
                name: "Erin Poole",
                email: "erin.poole@egov.com"
            },
            relatedProducts: [

            ]
        },
        tags: [
            "foundation"
        ]
    },
    {
        id: "telegov",
        name: "TeleGov",
        description: "Provides secure scheduling for state enterprises and local municipalities",
        productType: "external",
        externalUrl: "https://telegov.egov.com/groups",
        catalogProfile: {
            description: "NIC's TeleGov can help with scheduling needs across state enterprises and local municipalities. From DMV appointments to COVID-19 vaccinations, TeleGov is the one-stop, secure scheduling tool for government. The Software as a Service (SaaS) solution features back-office and front-end easy-to-use tools, including self-service forms, automatic reminders and payment processing, to manage inbound government agency traffic and workflows",
            productLogo: "telegov.png",
            slackChannel: {
                channel: "telegov-users",
                channelId: "C016PQDBCSW"
            },
            supportContact: {
                name: "Brian Muldowney",
                email: "Brian.Muldowney@egov.com"
            },
            relatedProducts: [
            ]
        },
        tags: [
            "citizen-engagement",
        ]
    },
    {
        id: "vital-records",
        name: "Vital Records",
        description: "Solution for the ordering, reviewing, and delivery of vital records",
        productType: "external",
        externalUrl: "https://vitalrecords.egov.com/CDC.VitalRecordsMVC.Web.Admin/Login.aspx",
        catalogProfile: {
            description: "Vital Records is a complete end-to-end online solution for the ordering, reviewing, and delivery of vital records. Customers can submit requests for birth, marriage, divorce, civil union, domestic partnership, and death certificates. Vital Records provides a document upload feature for supporting documentation",
            slackChannel: {
                channel: "vr-release",
                channelId: "C94SN9WPQ"
            },
            supportContact: {
                name: "Santino Sosa",
                email: "santino.sosa@egov.com"
            },
            relatedProducts: [
            ]
        },
        tags: [
            "citizen-engagement",
        ]
    },
    {
        id: "wallet-api",
        name: "Wallet API",
        description: "Provides TPE payemnt functionality to client applications",
        productType: "external",
        externalUrl: "https://beastharnessclient.azurewebsites.net/",
        catalogProfile: {
            description: "The Wallet API service has two primary functions: To keep client applications out of PCI scope and to enable the client application to process TPE payment transactions. Client applications authorized to work with the Wallet API are required to perform user authentication (email verification, etc.). The Wallet API does not authenticate users. Security is provided through authorization control implemented with the client application.",
            slackChannel: {
                channel: "wallet-users",
                "channelId": "CMPB023CP"
            },
            supportContact: {
                name: "Lauren Barney",
                email: "lauren.barney@egov.com"
            },
            relatedProducts: [

            ]
        },
        tags: [
            "payments"
        ]
    },
    {
        id: "your-pass-now",
        name: "Your Pass Now",
        description: "Provides the ability to purchase passes, permits, and licenses for recreational activities",
        catalogProfile: {
            description: "Your Pass Now provides the ability to electronically purchase passes, permits, and licenses for recreational activities on public land, such as national parks and forests. YourPassNow is a touchless, cloud-based park reservation platform that allows visitors to federal and state public lands to make payments and purchase their entry passes and recreation permits online",
            productLogo: "your-pass-now.jpg",
            slackChannel: {
                channel: "yourpassnow-users",
                channelId: "C01QXV50HQV"
            },
            relatedProducts: [
                {
                    label: "OneOutdoor",
                    id: "oneoutdoor"
                },
            ]
        },
        tags: [
            "citizen-engagement",
        ]
    }
];
