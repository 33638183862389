import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { API, Hub } from 'aws-amplify';

import { ForgeSkeleton, ForgeToolbar } from '@tylertech/forge-react';

import ConsoleBreadcrumbsNav from './ConsoleBreadcrumbsNav';
import ConsoleSideNav from './ConsoleSideNav';
import SkeletonBodyHeader from '../common/SkeletonBodyHeader';

const HUB_CHANNEL_NIC_CONSOLE_USER_FAVORITES = "nic-console:user-favorites";

const ConsoleLayout = ({ childProps }) => {

    const { sideNavOpen, drawerType, closeDrawer } = childProps;
    const [bodyTitle, setBodyTitle] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hideBackground, setHideBackground] = useState(false);
    const [userFavoriteProducts, setUserFavoriteProducts] = useState([]);

    useEffect(() => {

        const handleUserFavoriteUpdate = async ({ payload }) => {
            const params = {
                body: {
                    favorite: payload.data.favorite
                }
            };

            if ("addFavorite" === payload.event) {
                await API.post('console_api', '/favorites', params)
            } else if ("deleteFavorite" === payload.event) {
                await API.del('console_api', '/favorites', params);
            } else {
                return;
            }

            const userFavorites = await API.get('console_api', '/favorites');
            setUserFavoriteProducts(userFavorites);
        }
        Hub.listen(HUB_CHANNEL_NIC_CONSOLE_USER_FAVORITES, handleUserFavoriteUpdate);

        const fetchUserFavorites = async () => {
            setIsLoading(true);
            const userFavorites = await API.get('console_api', '/favorites');
            setUserFavoriteProducts(userFavorites);
            setIsLoading(false);
        }
        fetchUserFavorites();

        return () => {
            Hub.remove(HUB_CHANNEL_NIC_CONSOLE_USER_FAVORITES, handleUserFavoriteUpdate)
        }
    }, []);

    return (
        <>
            <div slot="body-left" className="nic-console-side-nav">
                {
                    isLoading ? (
                        <>
                            <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                            <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                            <ForgeSkeleton text style={{ "width": "15rem", "margin": "1rem" }} />
                        </>
                    ) : (
                        <ConsoleSideNav
                            sideNavOpen={sideNavOpen}
                            drawerType={drawerType}
                            closeDrawer={closeDrawer}
                            userFavoriteProducts={userFavoriteProducts}
                        />
                    )
                }
            </div>

            {
                isLoading ? (
                    <SkeletonBodyHeader />
                ) : (
                    <>
                        {
                            bodyTitle && (
                                <ForgeToolbar slot="body-header">
                                    <h1 slot="start" className="forge-typography--title">{bodyTitle}</h1>
                                </ForgeToolbar>
                            )
                        }
                    </>
                )
            }

            <div slot="body" style={{ 'paddingLeft': '16px', display: 'flex', flexDirection: 'column' }}>
                {
                    !hideBackground && <div className="nic-console-background"></div>
                }

                {
                    breadcrumbs && (
                        <div className="nic-console-navigation-container">
                            <div className="nic-console-navigation-left">
                                <ConsoleBreadcrumbsNav breadcrumbs={breadcrumbs} />
                            </div>
                        </div>
                    )
                }

                <div style={{ flex: "1 1 0", marginTop: "25px" }}>
                    <Outlet context={{
                        setBreadcrumbs,
                        setBodyTitle,
                        setHideBackground,
                        userFavoriteProducts
                    }} />
                </div>
            </div>
        </>
    )
}

export default ConsoleLayout;
