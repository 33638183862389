import React from "react";

import { ForgeIcon, ForgeLabelValue, ForgeOption, ForgeSelect } from "@tylertech/forge-react";

const MspAccountsDropdown = ({ accounts = [], userContext = {}, onUserContextUpdate }) => {
    const accountsGroupedByTenants = accounts.reduce((previous, current, i) => {
        const tenantId = current.tenantId;
        const existingGroup = previous.find(x => x.tenantId === tenantId);

        if (existingGroup) {
            existingGroup.accounts = existingGroup.accounts.concat(current);
        } else {
            previous = previous.concat({
                tenantId: tenantId,
                accounts: [].concat(current)
            })
        }

        return previous;
    }, []);

    return (
        <>
            {
                accountsGroupedByTenants.length > 0 ? (
                    <>
                        <ForgeSelect label="Switch Account" value={'' + userContext.accountId} on-change={onUserContextUpdate}>
                            <ForgeIcon name="account_group" className="tyler-icons" slot="leading"></ForgeIcon>
                            {
                                accountsGroupedByTenants.map((tenant, tenantIndex) => (
                                    <React.Fragment key={`nic-console-switch-account-grouping-${tenantIndex}`}>
                                        {
                                            tenant.accounts.map((account, accountIndex) => <ForgeOption key={`nic-console-switch-account-option-${accountIndex}`} value={'' + account.accountId} label={`${account.username} (${account.tenantId})`} />)
                                        }
                                        {
                                            ((accountsGroupedByTenants.length - 1) !== tenantIndex) && (<ForgeOption disabled={true} divider={true}></ForgeOption>)
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </ForgeSelect>
                    </>
                ) : (
                    <ForgeLabelValue style={{ margin: '8px' }}>
                        <ForgeIcon name="account_group" className="tyler-icons" slot="icon"></ForgeIcon>
                        <span slot="label">Tenant</span>
                        <span slot="value">{userContext.tenantId}</span>
                    </ForgeLabelValue>
                )
            }
        </>
    )

};

export default MspAccountsDropdown;
