import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';

import {
    ForgeList,
    ForgeListItem,
    ForgeSkeleton,
    ForgeCard,
    ForgeButton,
    ForgeTooltip,
    ForgeBadge,
    ForgeIcon,
    ForgeScaffold,
    ForgeToolbar
} from '@tylertech/forge-react';

import MspPageLayout from '../MspPageLayout';
import MspSkeletonList from '../MspSkeletonList';
import { MspServicesConfig } from '../../../config/MspServicesConfig';
import { ServiceCardGroupSkeleton } from '../ServiceCard';

const FeaturedActionCards = ({ service }) => (
    service.featuredActions.map((featActions, i) => (
        <ForgeCard key={`featured-action-card-${i}`} className="msp-admin-card msp-featured-action-card">
                <ForgeScaffold>
                    <ForgeToolbar slot="header">
                        <div slot="start">
                            <h2 className="forge-typography--title">{featActions.label}
                                {
                                    featActions.highlight && (
                                        <ForgeBadge theme="success" className="msp-admin-keep-right">Updated</ForgeBadge>
                                    )
                                }
                            </h2>
                        </div>
                    </ForgeToolbar>
                    <p className="forge-typography--body2" tabIndex="0" slot="body">
                        {featActions.description}
                    </p>
                    <ForgeToolbar slot="footer">
                        <Link to={featActions.path} slot="end">
                            <ForgeButton type="raised" slot="end">
                                <button type="button">Use</button>
                            </ForgeButton>
                        </Link>
                    </ForgeToolbar>
                </ForgeScaffold>
            </ForgeCard>
    ))
);

const MspServiceLandingPage = () => {
    const { serviceName } = useParams();
    const { setBodyTitle, setBreadcrumbs, setSideNavServiceConfig, setActiveSideNavbarLabel, setPageExists } = useOutletContext();
    const [service, setService] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setBodyTitle(false);
        setActiveSideNavbarLabel('');
        setIsLoading(true);
        const selectedService = MspServicesConfig[serviceName]
        if (selectedService) {
            setService(selectedService)
            setSideNavServiceConfig(selectedService);
            setBreadcrumbs([
                { link: "/msp-admin/home", label: "MSP Admin" },
                { label: selectedService.serviceLabel }
            ])
        } else {
            setPageExists(false);
        }
        setIsLoading(false);

    }, [service, serviceName, setBreadcrumbs, setBodyTitle, setSideNavServiceConfig, setActiveSideNavbarLabel, setPageExists])

    return (
        <>
            <MspPageLayout>
                <ForgeCard>
                    <div className="msp-service-landing-right">
                        {
                            isLoading ? (
                                <ForgeSkeleton avatar />
                            )
                                : (
                                    <>
                                        <img src={service.landingPageLogo} alt={service.serviceName} />
                                        <h3 className="forge-typography--headline4">{service.serviceLabel} Service</h3>
                                    </>
                                )
                        }
                        {
                            isLoading ? (
                                <MspSkeletonList size={2} />
                            ) : (
                                <ForgeList className="msp-service-landing-actions">
                                    <a target="_blank" rel="noopener noreferrer" href={service.documentationUrl}>
                                        <ForgeListItem>
                                            <span className="text">Documentation</span>
                                            <ForgeIcon name="open_in_new" className="tyler-icons" slot="trailing"></ForgeIcon>
                                        </ForgeListItem>
                                        <ForgeTooltip>
                                            <span>View documentation and service information in API Marketplace</span>
                                        </ForgeTooltip>
                                    </a>
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:msp@egov.com">
                                        <ForgeListItem>
                                            <ForgeIcon name="help_outline" className="tyler-icons" slot="trailing"></ForgeIcon>
                                            <span className="text">Report an issue</span>
                                        </ForgeListItem>
                                    </a>
                                </ForgeList>
                            )
                        }
                    </div>
                    <div className="msp-service-landing-left">
                        <div>
                            <h3 className="forge-typography--headline5">Description</h3>
                            {
                                isLoading ? (
                                    <>
                                        <ForgeSkeleton text />
                                        <ForgeSkeleton text />
                                        <ForgeSkeleton text />
                                    </>
                                ) : (
                                    <p className="forge-typography--body1">{service.serviceDescription || "No description"}</p>
                                )
                            }
                            <div className="msp-admin-spacer"></div>
                        </div>
                        <div>
                            <h3 className="forge-typography--headline5">Featured Actions</h3>
                            <div className="msp-service-landing-featured">
                                {
                                    isLoading ? (
                                        <ServiceCardGroupSkeleton cardsInGroup={3} key={'skeleton-loading-cards'} />
                                    ) : (
                                        <FeaturedActionCards service={service} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </ForgeCard>
            </MspPageLayout>
        </>
    )
}

export default MspServiceLandingPage;
