import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";

import { ForgeCard } from '@tylertech/forge-react';

import { groupArray } from '../../utility/Util';
import MspPageLayout from '../MspPageLayout';
import MspServicesConfig from '../../../config/MspServicesConfig';
import { ServiceCardGroup, ServiceCardGroupSkeleton  } from '../ServiceCard';

const MspHome = () => {

  const { setBodyTitle, setBreadcrumbs, setSideNavServiceConfig, setActiveSideNavbarLabel } = useOutletContext();
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setBodyTitle("MSP Admin");
    setBreadcrumbs(false);
    setSideNavServiceConfig(false);
    setActiveSideNavbarLabel('');
    const serviceConfig = MspServicesConfig.MspServicesConfig;
    const servicesByGroup = groupArray(
      Object.keys(serviceConfig)
        .map(x => serviceConfig[x])
        .sort((a, b) => {
          if (a.serviceName > b.serviceName) {
            return 1;
          } else if (a.serviceName < b.serviceName) {
            return -1;
          } else {
            return 0
          }
        })
    );
    setServices(servicesByGroup);
    setIsLoading(false);

  }, [setBodyTitle, setBreadcrumbs, setSideNavServiceConfig, setActiveSideNavbarLabel]);

  return (
    <MspPageLayout>
      <ForgeCard>
        <h2 className="forge-typography--headline4">Services</h2>
        <p className="forge-typography--subtitle1">
          Use and explore MSP Services.
        </p>
        {
          isLoading ? (
            <>
              {
                [...Array(3)].map((x, i) => (
                  <ServiceCardGroupSkeleton cardsInGroup={3} key={`service-card-group-skeleton-${i}`} />
                ))
              }
            </>
          ) : (
            <>
              {
                services.map((x, i) => (
                  <ServiceCardGroup key={`service-card-group-${i}`} services={x} />
                ))
              }
            </>
          )
        }
      </ForgeCard>
    </MspPageLayout>
  );
};

export default MspHome;
