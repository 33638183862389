import { ForgePageState } from "@tylertech/forge-react";

const GenericServerErrorPageState = ({ style }) => (
  <ForgePageState style={style}>
    <img
      src="https://cdn.forge.tylertech.com/v1/images/spot-hero/500-error-spot-hero.svg"
      alt="Broken down car."
      slot="graphic"
    />
    <div slot="title">Oops, something went wrong!</div>
    <div slot="message">An internal error occurred. Please try again later.</div>
  </ForgePageState>
);

const ResultsNotFoundPageState = ({
  style,
  title = "No results found.",
  message = "Sorry, no results found."
}) => (
  <ForgePageState style={style}>
    <img
      src="https://cdn.forge.tylertech.com/v1/images/spot-hero/no-search-results-spot-hero.svg"
      alt="Illustration of a telescope and moon."
      slot="graphic"
    />
    <div slot="title">{title}</div>
    <div slot="message">{message}</div>
  </ForgePageState>
);

export { GenericServerErrorPageState, ResultsNotFoundPageState };
