import { IconRegistry as TylerForgeIconRegistry } from '@tylertech/forge';

import {
  tylIconAccountCircle,
  tylIconAdd,
  tylIconAddChart,
  tylIconAddCircleOutline,
  tylIconAddLink,
  tylIconAddLocation,
  tylIconArrowBack,
  tylIconArrowUpward,
  tylIconAttachFile,
  tylIconAutoFixHigh,
  tylIconBarChart,
  tylIconCancel,
  tylIconPhoneIphone,
  tylIconCheckCircle,
  tylIconClear,
  tylIconClose,
  tylIconComputer,
  tylIconContactMail,
  tylIconContactPage,
  tylIconContentCopy,
  tylIconControlPoint,
  tylIconCreate,
  tylIconDangerous,
  tylIconDelete,
  tylIconEdit,
  tylIconExplore,
  tylIconError,
  tylIconFavorite,
  tylIconFavoriteBorder,
  tylIconFileDownload,
  tylIconHelpOutline,
  tylIconHome,
  tylIconInfo,
  tylIconInfoOutline,
  tylIconImage,
  tylIconKeyboard,
  tylIconKeyboardArrowLeft,
  tylIconKeyboardArrowRight,
  tylIconList,
  tylIconLocationOn,
  tylIconLocationSearching,
  tylIconLock,
  tylIconLogin,
  tylIconMap,
  tylIconMail,
  tylIconOpenInNew,
  tylIconOutgoingMail,
  tylIconPhonelinkRing,
  tylIconPreview,
  tylIconRefresh,
  tylIconRemove,
  tylIconRemoveCircleOutline,
  tylIconSearch,
  tylIconShowChart,
  tylIconSmartphone,
  tylIconSms,
  tylIconVpnKey,
  tylIconWarning,
  tylIconAddComment,
  tylIconShoppingCart,
  tylIconNotificationsActive,
  tylIconPerson,
  tylIconUploadFile
} from '@tylertech/tyler-icons/standard';

import {
  tylIconAccountGroup,
  tylIconAccountKey,
  tylIconAccountMultiple,
  tylIconAccountPlus,
  tylIconAndroid,
  tylIconApple,
  tylIconApplication,
  tylIconArrowTopRight,
  tylIconBookCross,
  tylIconCardAccountDetails,
  tylIconCellphoneMessage,
  tylIconCellphoneText,
  tylIconCertificate,
  tylIconChecklist,
  tylIconClipboardText,
  tylIconClipboardTextMultiple,
  tylIconEarth,
  tylIconEmailAlert,
  tylIconEmailCheck,
  tylIconEmailMultiple,
  tylIconEye,
  tylIconEyeOff,
  tylIconFileMultiple,
  tylIconKeyChain,
  tylIconKeyVariant,
  tylIconLink,
  tylIconMagnifyPlusOutline,
  tylIconMessageCog,
  tylIconMessageTextClock,
  tylIconPhoneCheck,
  tylIconSlack,
  tylIconFileUpload
} from '@tylertech/tyler-icons/extended';

import {
  tylIconTylerTalkingTLogo
} from '@tylertech/tyler-icons/custom';

export const registerIcons = () => {
  TylerForgeIconRegistry.define([
    tylIconAccountCircle,
    tylIconAdd,
    tylIconAddCircleOutline,
    tylIconAddComment,
    tylIconAddLink,
    tylIconAddLocation,
    tylIconAccountGroup,
    tylIconAccountKey,
    tylIconAccountMultiple,
    tylIconAccountPlus,
    tylIconAddChart,
    tylIconAndroid,
    tylIconApple,
    tylIconApplication,
    tylIconArrowBack,
    tylIconArrowTopRight,
    tylIconArrowUpward,
    tylIconAttachFile,
    tylIconAutoFixHigh,
    tylIconBarChart,
    tylIconBookCross,
    tylIconCancel,
    tylIconPhoneIphone,
    tylIconCardAccountDetails,
    tylIconCellphoneMessage,
    tylIconCellphoneText,
    tylIconCertificate,
    tylIconCheckCircle,
    tylIconChecklist,
    tylIconClear,
    tylIconClipboardText,
    tylIconClipboardTextMultiple,
    tylIconClose,
    tylIconComputer,
    tylIconContactMail,
    tylIconContactPage,
    tylIconContentCopy,
    tylIconControlPoint,
    tylIconCreate,
    tylIconDangerous,
    tylIconDelete,
    tylIconEarth,
    tylIconEdit,
    tylIconExplore,
    tylIconEmailAlert,
    tylIconEmailCheck,
    tylIconEmailMultiple,
    tylIconError,
    tylIconEye,
    tylIconEyeOff,
    tylIconFavorite,
    tylIconFavoriteBorder,
    tylIconFileDownload,
    tylIconFileMultiple,
    tylIconHelpOutline,
    tylIconHome,
    tylIconInfo,
    tylIconInfoOutline,
    tylIconImage,
    tylIconKeyboard,
    tylIconKeyChain,
    tylIconKeyVariant,
    tylIconKeyboardArrowLeft,
    tylIconKeyboardArrowRight,
    tylIconLink,
    tylIconList,
    tylIconLocationOn,
    tylIconLocationSearching,
    tylIconLock,
    tylIconLogin,
    tylIconMagnifyPlusOutline,
    tylIconMail,
    tylIconMap,
    tylIconMessageCog,
    tylIconMessageTextClock,
    tylIconNotificationsActive,
    tylIconOpenInNew,
    tylIconOutgoingMail,
    tylIconPerson,
    tylIconPhoneCheck,
    tylIconPhonelinkRing,
    tylIconPreview,
    tylIconRefresh,
    tylIconRemove,
    tylIconRemoveCircleOutline,
    tylIconSearch,
    tylIconShoppingCart,
    tylIconShowChart,
    tylIconSlack,
    tylIconSmartphone,
    tylIconSms,
    tylIconTylerTalkingTLogo,
    tylIconVpnKey,
    tylIconWarning,
    tylIconUploadFile,
    tylIconFileUpload
  ]);
};
