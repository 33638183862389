import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import gtag from 'ga-gtag';

const RouteChangeObserver = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        gtag('event', 'page_view', {
            page_location: location.pathname
        })
    }, [location])
    return (
        <>
            {children}
        </>
    );
};

export default RouteChangeObserver
