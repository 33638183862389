module.exports.MspServicesConfig = {
    "account": {
        "serviceName": "account",
        "serviceLabel": "Account",
        "serviceDescription": "Provides the ability to manage accounts which are used to authenticate & authorize with MSP.",
        "serviceLandingPage": "/msp-admin/services/account/",
        "servicePage": "/msp-admin/services/account/createAccount",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-account",
        "serviceLogo": "account_circle",
        "sideNavLogo": "account_circle",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/id-spot.svg",
        "featuredActions": [
            {
                "label":"Create Accounts",
                "description":"Create an account authorzied to access MSP APIs",
                "path": "createAccount"
            },
            {
                "label":"Create API Key",
                "description":"Make an API Key to access MSP APIs",
                "path": "createApiKey"
            },
            {
                "label": "Generate Token",
                "description": "Verify auth credentials and generate a token",
                "path": "authenticate"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "View Accounts",
                "path": "getAccounts",
                "icon": "account_multiple",
                "iconSet": "extended",
                "fileName": "ListAccounts.js"
            },
            {
                "label": "Create Account",
                "path": "createAccount",
                "icon": "account_plus",
                "iconSet": "extended",
                "fileName": "CreateAccount.js"
            },
            {
                "label": "View API Keys",
                "path": "getApiKeys",
                "icon": "key_chain",
                "iconSet": "extended",
                "fileName": "ListApiKeys.js"
            },
            {
                "label": "Create API Key",
                "path": "createApiKey",
                "icon": "vpn_key",
                "fileName": "CreateApiKey.js"
            },
            {
                "label": "Generate Token",
                "path": "authenticate",
                "icon": "login",
                "fileName": "GenerateToken.js"
            }
        ]
    },
    "address-v2": {
        "serviceName": "address-v2",
        "serviceLabel": "Address",
        "serviceDescription": "Provides address verification, zip code lookups and city/state lookups.",
        "serviceLandingPage": "/msp-admin/services/address-v2/",
        "servicePage": "/msp-admin/services/address-v2/verifyAddress",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-address-v2",
        "serviceLogo": "add_location",
        "sideNavLogo": "add_location",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/map-2-spot.svg",
        "featuredActions": [
            {
                "label": "Verify Address",
                "description": "Verify an address according to USPS and international formats",
                "path": "verifyAddress"
            },
            {
                "label": "City/County Lookup",
                "description": "Look up city and county percentages by zipcode",
                "path": "GetCityCounty"
            },
            {
                "label": "Geocode",
                "description": "Return the longitude and latitude of a given address",
                "path": "geocode"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Verify Address",
                "path": "verifyAddress",
                "icon": "contact_page",
                "fileName": "VerifyAddress.js"
            },
            {
                "label": "City County Lookup",
                "path": "GetCityCounty",
                "icon": "location_searching",
                "fileName": "GetCityCounty.js"
            },
            {
                "label": "Geocode",
                "path": "geocode",
                "icon": "location_on",
                "fileName": "Geocode.js"
            }
        ]
    },
    "autocomplete": {
        "serviceName": "autocomplete",
        "serviceLabel": "Autocomplete",
        "serviceDescription": "Storage and retrieval of autocomplete suggestions",
        "serviceLandingPage": "/msp-admin/services/autocomplete/",
        "servicePage": "/msp-admin/services/autocomplete/CreateSuggestions",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-autocomplete",
        "serviceLogo": "auto_fix_high",
        "sideNavLogo": "auto_fix_high",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/search-lists-spot.svg",
        "featuredActions": [
            {
                "label": "Create Suggestions",
                "description": "Create your list of autocomplete suggestions",
                "path": "CreateSuggestions"
            },
            {
                "label": "Get Suggestions",
                "description": "Choose a key and return the suggestions associated with it",
                "path": "GetSuggestions"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Create Suggestions",
                "path": "CreateSuggestions",
                "icon": "create",
                "fileName": "CreateSuggestion.js"
            },
            {
                "label": "Try Suggestions",
                "path": "GetSuggestions",
                "icon": "keyboard",
                "fileName": "GetSuggestion.js"
            }
        ]
    },
    "map": {
        "serviceName": "map",
        "serviceLabel": "Map Service",
        "serviceDescription": "Provides map tile functionality.",
        "serviceLandingPage": "/msp-admin/services/map/",
        "servicePage": "/msp-admin/services/map/createAppClient",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-map",
        "serviceLogo": "map",
        "sideNavLogo": "map",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/map-1-spot.svg",
        "featuredActions": [
            {
                "label": "Create App Client",
                "description": "Create app client to access Map Service",
                "path": "createAppClient"
            },
            {
                "label": "Manage App Clients",
                "description": "Edit and delete App Clients",
                "path": "listAppClients"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Create App Client",
                "path": "createAppClient",
                "icon": "computer",
                "fileName": "CreateAppClient.js"
            },
            {
                "label": "View App Clients",
                "path": "listAppClients",
                "icon": "magnify_plus_outline",
                "iconSet": "extended",
                "fileName": "ListAppClients.js"
            }
        ]
    },
    "phone-verification": {
        "serviceName": "phone-verification",
        "serviceLabel": "Phone Verification",
        "serviceDescription": "Provides the ability to verify end user phone numbers via SMS verification codes.",
        "serviceLandingPage": "/msp-admin/services/phone-verification/",
        "servicePage": "/msp-admin/services/phone-verification/phoneVerification",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-phone-verification-v2",
        "serviceLogo": "smartphone",
        "sideNavLogo": "smartphone",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/mobile-app-spot.svg",
        "featuredActions": [
            {
                "label": "Initiate Verification",
                "description": "Send an SMS verification code to a phone number",
                "path": "phoneVerification"
            },
            {
                "label": "Confirm Phone",
                "description": "Confirm a phone number with the verification code",
                "path": "phoneConfirmation"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Initiate Verification",
                "path": "phoneVerification",
                "icon": "phone_check",
                "iconSet": "extended",
                "fileName": "InitiatePhoneVerification.js"
            },
            {
                "label": "Confirm Phone",
                "path": "phoneConfirmation",
                "icon": "check_circle",
                "fileName": "ConfirmPhone.js"
            }]
    },
    "event-logging": {
        "serviceName": "event-logging",
        "serviceLabel": "Event Logging",
        "serviceDescription": "Provides the capability for callers to log events that happen in their internal applications.",
        "serviceLandingPage": "/msp-admin/services/event-logging/",
        "servicePage": "/msp-admin/services/event-logging/createEventLog",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-event-logging",
        "serviceLogo": "add_comment",
        "sideNavLogo": "add_comment",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/generic-report-spot.svg",
        "featuredActions": [
            {
                "label": "Create Event Log",
                "description": "Create an event log for an application",
                "path": "createEventLog"
            },
            {
                "label": "Search Event Logs",
                "description": "Query for event logs based on application, date range, and external ID",
                "path": "searchEventLogs"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Create Event Log",
                "path": "createEventLog",
                "icon": "add",
                "fileName": "CreateEventLog.js"
            },
            {
                "label": "Search Event Logs",
                "path": "searchEventLogs",
                "icon": "search",
                "fileName": "SearchEventLogs.js"
            }
        ]
    },
    "password-security": {
        "serviceName": "password-security",
        "serviceLabel": "Password Security",
        "serviceDescription": "Provides the capability for callers to verify if a given password is in our database of compromised passwords.",
        "serviceLandingPage": "/msp-admin/services/password-security/",
        "servicePage": "/msp-admin/services/password-security/getPassword",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-password-security",
        "serviceLogo": "account_key",
        "sideNavLogo": "lock",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/personalized-spot.svg",
        "iconSet": "extended",
        "featuredActions": [
            {
                "label": "Verify Password",
                "description": "Check to see if a password is in our database of compromised passwords",
                "path": "getPassword"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Verify Password",
                "path": "getPassword",
                "icon": "lock",
                "fileName": "GetPassword.js"
            }
        ]
    },
    "shopping-cart": {
        "serviceName": "shopping-cart",
        "serviceLabel": "Shopping Cart",
        "serviceDescription": "Provides cross-catalog shopping cart functionality.",
        "serviceLandingPage": "/msp-admin/services/shopping-cart/",
        "servicePage": "/msp-admin/services/shopping-cart/createAppClient",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-shopping-cart",
        "serviceLogo": "shopping_cart",
        "sideNavLogo": "shopping_cart",
        "landingPageLogo":"https://cdn.forge.tylertech.com/v1/images/spot/cart-empty-spot.svg",
        "featuredActions": [
            {
                "label": "Create Catalog Config",
                "description": "Create a tenant specific catalog configuration",
                "path": "createCatalogConfig"
            },
            {
                "label": "Manage Catalog Configs",
                "description": "Edit or delete catalog configurations",
                "path": "searchCatalogConfig"
            },
            {
                "label": "Regenerate API Key",
                "description": "Regenerate a random API key for a specific catalog configuration",
                "path": "regenerateApiKey"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Create App Client",
                "path": "createAppClient",
                "icon": "application",
                "iconSet": "extended",
                "fileName": "CreateAppClient.js"
            },
            {
                "label": "View App Clients",
                "path": "searchAppClients",
                "icon": "magnify_plus_outline",
                "iconSet": "extended",
                "fileName": "ListAppClients.js"
            },
            {
                "label": "Create Catalog Config",
                "path": "createCatalogConfig",
                "icon": "book_cross",
                "iconSet": "extended",
                "fileName": "CreateCatalogConfig.js"
            },
            {
                "label": "View Catalog Configs",
                "path": "searchCatalogConfig",
                "icon": "checklist",
                "iconSet": "extended",
                "fileName": "ListCatalogConfigs.js"
            },
            {
                "label": "Regenerate Api Key",
                "path": "regenerateApiKey",
                "icon": "key_variant",
                "iconSet": "extended",
                "fileName": "RegenerateApiKey.js"
            }
        ]
    },
    "shortener": {
        "serviceName": "shortener",
        "serviceLabel": "Shortener",
        "serviceDescription": "Provides capabilities to shorten URLs to make links more manageable and promote sharing.",
        "serviceLandingPage": "/msp-admin/services/shortener/",
        "servicePage": "/msp-admin/services/shortener/list",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-shortener-v2",
        "serviceLogo": "link",
        "sideNavLogo": "link",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/developer-spot.svg",
        "featuredActions": [
            {
                "label": "View Short URLs",
                "description": "View short URLs, Original URLs, click count, and expiration date",
                "path": "list"
            },
            {
                "label": "Create Short URL",
                "description": "Create a short URL with an expiration date",
                "path": "create"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "View Short URLs",
                "path": "list",
                "icon": "list",
                "fileName": "ListShortUrls"
            },
            {
                "label": "Create Short URL",
                "path": "create",
                "icon": "add_link",
                "fileName": "CreateShortUrl"
            }
        ]
    },
    "notification-v2": {
        "serviceName": "notification-v2",
        "serviceLabel": "Notification",
        "serviceDescription": "Helps you send email and SMS messages",
        "serviceLandingPage": "/msp-admin/services/notification-v2/",
        "servicePage": "/msp-admin/services/notification-v2/getVerifiedSenders",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-notification-v2",
        "serviceLogo": "notifications_active",
        "sideNavLogo": "notifications_active",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/notifications-spot.svg",
        "iconSet": "standard",
        "featuredActions": [
            {
                "label": "Search Email Logs",
                "description": "Search for information about emails that have been requested to be sent",
                "path": "emailLogs",
                "highlight": true
            },
            {
                "label": "Search Bounced Emails",
                "description": "Search for a specific bounced email on the Bounced Emails page",
                "path": "bouncedEmails",
                "highlight": true
            },
            {
                "label": "View Verified Senders",
                "description": "View verified senders created for your tenant",
                "path": "getVerifiedSenders"
            },
            {
                "label": "Create Email Attachment",
                "description": "Add an attachment to send with emails",
                "path": "createEmailAttachment"
            },
            {
                "label": "Create Template",
                "description": "Create an email template",
                "path": "createTemplate"
            },
        ],
        "sideNavbarConfig": [
            {
                "label": "Email",
                "icon": "contact_mail",
                "sublinks": [
                    {
                        "label": "View Verified Senders",
                        "path": "getVerifiedSenders",
                        "fileName": "ListVerifiedSenders.js",
                        "iconSet": "extended",
                        "icon": "email_check"
                    },
                    {
                        "label": "Create Verified Sender",
                        "path": "createVerifiedSender",
                        "fileName": "CreateVerifiedSender.js",
                        "iconSet": "standard",
                        "icon": "contact_mail"
                    },
                    {
                        "label": "Send Email",
                        "path": "sendEmail",
                        "fileName": "SendEmail.js",
                        "iconSet": "standard",
                        "icon": "outgoing_mail"
                    },
                    {
                        "label": "Create Template",
                        "path": "createTemplate",
                        "fileName": "CreateTemplate.js",
                        "iconSet": "extended",
                        "icon": "clipboard_text"
                    },
                    {
                        "label": "View Templates",
                        "path": "getTemplates",
                        "fileName": "ListTemplates.js",
                        "iconSet": "extended",
                        "icon": "clipboard_text_multiple"
                    },
                    {
                        "label": "Create Email Attachment",
                        "path": "createEmailAttachment",
                        "fileName": "CreateEmailAttachment.js",
                        "iconSet": "standard",
                        "icon": "attach_file"
                    },
                    {
                        "label": "View Email Attachments",
                        "path": "listEmailAttachments",
                        "fileName": "ListEmailAttachments.js",
                        "iconSet": "extended",
                        "icon": "file_multiple"
                    },
                    {
                        "label": "Create Scheduled Email",
                        "path": "createScheduledEmail",
                        "fileName": "CreateScheduledEmail.js",
                        "iconSet": "extended",
                        "icon": "message_text_clock"
                    },
                    {
                        "label": "View Scheduled Emails",
                        "path": "listScheduledEmails",
                        "fileName": "ListScheduledEmails.js",
                        "iconSet": "extended",
                        "icon": "email_multiple"
                    },
                    {
                        "label": "Email Logs",
                        "path": "emailLogs",
                        "fileName": "EmailLogs.js",
                        "iconSet": "extended",
                        "icon": "email_check"
                    },
                    {
                        "label": "Bounced Emails",
                        "path": "bouncedEmails",
                        "fileName": "BouncedEmails.js",
                        "iconSet": "extended",
                        "icon": "email_alert"
                    },
                    {
                        "label": "Alert Preferences",
                        "path": "putAlertPreferences",
                        "fileName": "PutAlertPreferences.js",
                        "iconSet": "extended",
                        "icon": "message_cog"
                    },
                ]
            },
            {
                "label": "SMS",
                "iconSet": "extended",
                "icon": "cellphone_message",
                "sublinks": [
                    {
                        "label": "Send Sms",
                        "path": "sendSms",
                        "fileName": "SendSms.js",
                        "iconSet": "extended",
                        "icon": "cellphone_message"
                    },
                    {
                        "label": "Create Scheduled SMS",
                        "path": "createScheduledSms",
                        "fileName": "CreateScheduledSms.js",
                        "iconSet": "standard",
                        "icon": "phonelink_ring"
                    },
                    {
                        "label": "View Scheduled SMS",
                        "path": "listScheduledSmsMessages",
                        "fileName": "ListScheduledSmsMessages.js",
                        "iconSet": "extended",
                        "icon": "cellphone_text"
                    },
                    {
                        "label": "View SMS Status",
                        "path": "smsStatus",
                        "fileName": "SmsStatus.js",
                        "iconSet": "extended",
                        "icon": "cellphone_text"   
                    },
                    {
                        "label": "View Bullk SMS Status",
                        "path": "bulkSmsStatus",
                        "fileName": "BulkSmsStatus.js",
                        "iconSet": "extended",
                        "icon": "cellphone_text"  
                    },
                    {
                        "label": "View Message Senders",
                        "path": "listMessageSenders",
                        "fileName": "ListMessageSenders.js",
                        "iconSet": "extended",
                        "icon": "cellphone_text"  
                    }
                ]
            }
        ]
    },
    "profile": {
        "serviceName": "profile",
        "serviceLabel": "Profile",
        "serviceDescription": "Provides centralized user profile features",
        "serviceLandingPage": "/msp-admin/services/profile/",
        "servicePage": "/msp-admin/services/profile/identityProviders",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-profile-v1",
        "serviceLogo": "person",
        "sideNavLogo": "person",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/person-1-spot.svg",
        "iconSet": "standard",
        "featuredActions": [
            {
                "label": "Identity Providers",
                "description": "Manage registered identity providers",
                "path": "identityProviders"
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Identity Providers",
                "path": "identityProviders",
                "icon": "card_account_details",
                "iconSet": "extended",
                "fileName": "IdentityProviders.js"
            }
        ]
    },
    "mobile-pass": {
        "serviceName": "mobile-pass",
        "serviceLabel": "Mobile Pass",
        "serviceDescription": "Helps create mobile wallet passes",
        "serviceLandingPage": "/msp-admin/services/mobile-pass/",
        "servicePage": "/msp-admin/services/mobile-pass/certificates",
        "documentationUrl": "https://api-marketplace.egov-msp.com/service/nic-msp-mobile-pass",
        "serviceLogo": "card_account_details",
        "sideNavLogo": "card_account_details",
        "landingPageLogo": "https://cdn.forge.tylertech.com/v1/images/spot/id-spot.svg",
        "iconSet": "standard",
        "featuredActions": [
            {
                "label": "Certificates",
                "description": "Manage mobile pass certificates",
                "path": "certificates",
                "highlight": true
            },
            {
                "label": "Templates",
                "description": "Manage mobile pass templates",
                "path": "templates",
                "highlight": true
            },
            {
                "label": "Images",
                "description": "Manage mobile pass images",
                "path": "images",
                "highlight": true
            },
            {
                "label": "Issuers",
                "description": "Manage mobile pass issuers",
                "path": "issuers",
                "highlight": true
            }
        ],
        "sideNavbarConfig": [
            {
                "label": "Certificates",
                "path": "certificates",
                "icon": "certificate",
                "iconSet": "extended",
                "fileName": "Certificates.js"
            },
            {
                "label": "Templates",
                "path": "templates",
                "icon": "clipboard_text_multiple",
                "iconSet": "extended",
                "fileName": "Templates.js"
            },
            {
                "label": "Images",
                "path": "images",
                "icon": "image",
                "iconSet": "standard",
                "fileName": "Images.js"
            },
            {
                "label": "Issuers",
                "path": "issuers",
                "icon": "android",
                "iconSet": "standard",
                "fileName": "Issuers.js"
            }
        ],
        "links": [
            {
                "label": "Create Template",
                "sideNavbarLabel": "Templates",
                "path": "createTemplate",
                "fileName": "CreateTemplate.js",
                "ancestors": [
                    {
                        "label": "Templates",
                        "path": "/msp-admin/services/mobile-pass/templates"
                    }
                ]
            },
            {
                "label": "Preview Mobile Pass",
                "sideNavbarLabel": "Templates",
                "path": "templates/:templateId/preview",
                "fileName": "PassPreview.js",
                "ancestors": [
                    {
                        "label": "Templates",
                        "path": "/msp-admin/services/mobile-pass/templates"
                    }
                ]
            },
            {
                "label": "Update Template",
                "sideNavbarLabel": "Templates",
                "path": "templates/:templateId",
                "fileName": "UpdateTemplate.js",
                "ancestors": [
                    {
                        "label": "Templates",
                        "path": "/msp-admin/services/mobile-pass/templates"
                    }
                ]
            }
        ]
    }
}
