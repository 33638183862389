import { ForgeCard, ForgeScaffold, ForgeSkeleton, ForgeToolbar, ForgeTooltip } from "@tylertech/forge-react";

const MetricCard = ({
  name = "Metric",
  metricValue = "0",
  height = "100%",
  width = "15rem",
  tooltip
}) => (
  <div className="msp-admin-metric-card">
    <ForgeCard style={{ "--forge-card-height": height, "--forge-card-width": width }}>
      <ForgeScaffold>
        <ForgeToolbar slot="header">
          <h3 slot="start" className="forge-typography--subtitle1-secondary">{name}</h3>
        </ForgeToolbar>
        <div slot="footer">
          <p className="forge-typography--subtitle1 msp-admin-keep-right">{metricValue}</p>
        </div>
      </ForgeScaffold>
    </ForgeCard>
    {
      tooltip && <ForgeTooltip position="bottom">{tooltip}</ForgeTooltip>
    }
  </div>
);

const MetricCardSkeleton = () => (
  <ForgeCard style={{ "--forge-card-height": "100%" }}>
    <div>
      <ForgeSkeleton text style={{ "width": "12rem", "margin": "1rem" }} />
      <ForgeSkeleton text className="msp-admin-keep-right" style={{ "width": "5rem", "margin": "1rem" }} />
    </div>
  </ForgeCard>
);

export { MetricCard, MetricCardSkeleton };
