import { useState } from "react";
import { Link } from "react-router-dom";

import {
    ForgeAppBarHelpButton,
    ForgeDialog,
    ForgeDivider,
    ForgeIcon,
    ForgeIconButton,
    ForgeTab,
    ForgeTabBar,
    ForgeToolbar,
    ForgeView,
    ForgeViewSwitcher
} from "@tylertech/forge-react";

import AppBarAuthButtons from './AppBarAuthButtons';
import ConsoleSearch from './ConsoleSearch';
import TermsOfUse from "./TermsOfUse";

const ConsoleHeader = ({ authState, signOut, userContext, showSearch = true }) => (
    <>
        <ForgeIcon slot="logo" name="tyler_talking_t_logo"></ForgeIcon>
        <div slot="title" className="nic-console-app-bar-link">
            <Link to={"/home"}>
                <p>Console</p>
            </Link>
        </div>
        {
            authState === 'signedIn' && userContext && <>
                {showSearch && <ConsoleSearch />}
                <ConsoleHelpMenu />
                <AppBarAuthButtons authState={authState} signOut={signOut} userContext={userContext} />
            </>
        }

    </>
);

const ConsoleHelpMenu = () => {
    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const [aboutDialogActiveTab, setAboutDialogActiveTab] = useState(0);

    const HelpMenuOptions = [
        {
            label: "Help",
            value: "help",
            leadingIcon: 'help_outline',
            leadingIconType: 'component'
        },
        {
            label: "About",
            value: "about",
            leadingIcon: 'info_outline',
            leadingIconType: 'component'
        }
    ];

    const handleSelectHelpMenuItemEvent = (e) => {
        const selectedValue = e.detail.value;

        if (selectedValue === "about") {
            setShowAboutDialog(true);
        } else if (selectedValue === "help") {
            setShowHelpDialog(true);
        }
    }

    const handleOnDismissAboutDialog = () => {
        setAboutDialogActiveTab(0);
        setShowAboutDialog(false);
    }

    const handleOnDismissHelpDialog = () => {
        setShowHelpDialog(false);
    }

    const updateActiveView = (e) => {
        setAboutDialogActiveTab(e.detail.index);
    }

    return (
        <>
            <ForgeAppBarHelpButton slot="end" options={HelpMenuOptions} on-forge-menu-select={handleSelectHelpMenuItemEvent}></ForgeAppBarHelpButton>
            <ForgeDialog open={showAboutDialog} onDismiss={handleOnDismissAboutDialog}>
                <div>
                    <ForgeToolbar>
                        <h2 slot="start" className="forge-dialog__title">About the console</h2>
                        <ForgeIconButton slot="end">
                            <button
                                type="button"
                                className="tyler-icons"
                                onClick={handleOnDismissAboutDialog}
                            >
                                <ForgeIcon name="close"></ForgeIcon>
                            </button>
                        </ForgeIconButton>
                    </ForgeToolbar>
                    <div className="forge-dialog__body nic-console-about-dialog-body">
                        <div role="tablist">
                            <ForgeTabBar on-forge-tab-bar-activate={updateActiveView} activeTab={aboutDialogActiveTab}>
                                <ForgeTab>About</ForgeTab>
                                <ForgeTab>Terms of Use</ForgeTab>
                            </ForgeTabBar>
                            <ForgeDivider></ForgeDivider>
                            <ForgeViewSwitcher index={aboutDialogActiveTab}>
                                <ForgeView role="tabpanel">
                                    <div style={{ "height": "19rem", "width": "50rem" }}>
                                        <p className="forge-typography--body1">Created by NIC, Inc. </p>
                                    </div>
                                </ForgeView>
                                <ForgeView role="tabpanel">
                                    <div style={{ "height": "20rem", "width": "50rem" }}>
                                        <TermsOfUse showHeader={false} bodyClassName="nic-console-about-dialog-terms-of-use-body" />
                                    </div>
                                </ForgeView>
                            </ForgeViewSwitcher>
                        </div>
                    </div>
                    <div className="forge-dialog__footer nic-console-about-dialog-footer">
                        <p className="forge-typography--body1">Copyright © {new Date().getFullYear()} NIC Inc. All Rights Reserved</p>
                    </div>
                </div>
            </ForgeDialog>
            <ForgeDialog open={showHelpDialog} onDismiss={handleOnDismissHelpDialog}>
                <div>
                    <ForgeToolbar>
                        <h2 slot="start" className="forge-dialog__title">Need Help?</h2>
                        <ForgeIconButton slot="end">
                            <button
                                type="button"
                                className="tyler-icons"
                                onClick={handleOnDismissHelpDialog}
                            >
                                <ForgeIcon name="close"></ForgeIcon>
                            </button>
                        </ForgeIconButton>
                    </ForgeToolbar>
                    <div className="forge-dialog__body" style={{ "width": "50rem" }}>
                        <p>Our team can try to help you with any issues you are having. We also welcome feedback!</p>
                        <p>Get in touch with us via one of the following methods:</p>
                        <h3>Slack</h3>
                        <p>Reach out and chat on Slack in the <a href="https://nic-inc.slack.com/archives/C7UJDF6PM" target="_blank" rel="noopener noreferrer">#msp-users channel</a></p>
                        <h3>Email</h3>
                        <p>Email us at: <a href="mailto:msp@egov.com">msp@egov.com</a></p>
                    </div>
                </div>
            </ForgeDialog>
        </>
    )
};

export default ConsoleHeader;
