import { ForgeCard, ForgeScaffold, ForgeSkeleton, ForgeToolbar } from '@tylertech/forge-react';

import ProductButton from './ProductButton';
import { padGroup } from '../utility/Util';

const ProductCard = ({ product, isLoading = false }) => (
    <>
        {
            product && (
                <ForgeCard className="nic-console-product-card">
                    <ForgeScaffold>
                        <ForgeToolbar slot="header">
                            {
                                isLoading ? (
                                    <ForgeSkeleton text style={{ "width": "25%" }} />
                                ) : (
                                    <h3 slot="start" className="forge-typography--title">{product.name}</h3>
                                )
                            }
                        </ForgeToolbar>
                        <div className="forge-card-header-container" slot="body">
                            {
                                isLoading ? (
                                    <>
                                        <ForgeSkeleton text style={{ "width": "80%" }} />
                                        <ForgeSkeleton text style={{ "width": "80%" }} />
                                    </>
                                ) : (
                                    <p className="forge-typography--body2">
                                        {product.description}
                                    </p>
                                )
                            }
                        </div>
                        <ForgeToolbar slot="footer">
                            {
                                isLoading ? (
                                    <div style={{ "display": "flex", "flexDirection": "row" }}>
                                        <ForgeSkeleton button style={{ "margin": "0.5rem" }} />
                                        <ForgeSkeleton button style={{ "margin": "0.5rem" }} />
                                    </div>
                                ) : (
                                    <div slot="end">
                                        <ProductButton product={product} buttonType="view" />
                                        <ProductButton product={product} buttonType="use" />
                                    </div>
                                )
                            }
                        </ForgeToolbar>
                    </ForgeScaffold>
                </ForgeCard>
            )
        }
    </>
);

const ProductCardGroup = ({ products, isLoading }) => {
    const paddedGroup = padGroup(products);

    return (
        <div className="nic-console-product-card-group">
            {
                paddedGroup.map((x, i) => (
                    <ProductCard key={`product-card-${i}`} product={x} isLoading={isLoading} />
                ))
            }
        </div>
    );
};

const ProductCardGroupSkeleton = ({ numberInGroup: cardsInGroup = 3 }) => (
    <div className="nic-console-product-card-group">
        {
            [...Array(cardsInGroup)].map((x, i) => (
                <div className="nic-console-product-card" key={`loading-product-card-${i}`}>
                    <ForgeCard>
                        <ForgeSkeleton text style={{ "width": "20%" }}></ForgeSkeleton>
                        <ForgeSkeleton text style={{ "width": "80%" }}></ForgeSkeleton>
                        <ForgeSkeleton text style={{ "width": "80%" }}></ForgeSkeleton>
                        <div className="nic-console-spacer"></div>
                        <div className="nic-console-keep-right">
                            <div style={{ "display": "flex" }}>
                                <ForgeSkeleton button style={{ "margin": "0.5rem" }}></ForgeSkeleton>
                                <ForgeSkeleton button style={{ "margin": "0.5rem" }}></ForgeSkeleton>
                            </div>
                        </div>
                    </ForgeCard>
                </div>
            ))
        }
    </div>
);

export { ProductCard, ProductCardGroup, ProductCardGroupSkeleton };
