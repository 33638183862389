import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { API, Cache } from 'aws-amplify';

import {
  ForgeButton,
  ForgeCard,
  ForgeDivider,
  ForgeExpansionPanel,
  ForgeLabelValue,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon,
  ForgeOption,
  ForgeSelect,
  ForgeSkeleton,
  ForgeTable,
  ForgeTooltip
} from '@tylertech/forge-react';

import { GenericServerErrorPageState, ResultsNotFoundPageState } from '../PageState';
import InlineInfoMessage from "../InlineInfoMessage";
import { MetricCard, MetricCardSkeleton } from '../MetricCard';
import MspPageLayout from "../MspPageLayout";
import { useCallback } from 'react';

Cache.configure({
  defaultTTL: 900000
});

const serviceDisplayFormatConfig = {
  'msp-account-v1': {
    serviceName: "Account",
    resources: {}
  },
  'msp-address-v1': {
    serviceName: "Address v1",
    resources: {}
  },
  'msp-address-v2': {
    serviceName: "Address v2",
    resources: {}
  },
  'msp-auth-v1': {
    serviceName: "Auth",
    resources: {}
  },
  'msp-antivirus-v1': {
    serviceName: "Antivirus",
    resources: {}
  },
  'msp-autocomplete-v1': {
    serviceName: "Autocomplete",
    resources: {}
  },
  'msp-certificate-v1': {
    serviceName: "Certificate",
    resources: {}
  },
  'msp-document-v1': {
    serviceName: "Document",
    resources: {}
  },
  'msp-entity-v1': {
    serviceName: "Entity",
    resources: {}
  },
  'msp-event-logging-v1': {
    serviceName: "Event Logging",
    resources: {}
  },
  'msp-form-v1': {
    serviceName: "Form",
    resources: {}
  },
  'msp-image-v1': {
    serviceName: "Image",
    resources: {}
  },
  'msp-inspection': {
    serviceName: "Inspection",
    resources: {}
  },
  'msp-integration-v1': {
    serviceName: "Integration",
    resources: {}
  },
  'msp-map-v1': {
    serviceName: "Map",
    resources: {}
  },
  'msp-notification-v1': {
    serviceName: "Notification v1",
    resources: {}
  },
  'msp-notification-v2': {
    serviceName: "Notification v2",
    resources: {}
  },
  'msp-password-security-v1': {
    serviceName: "Password Security",
    resources: {}
  },
  'msp-payment-connector-v1': {
    serviceName: "Payment Connector",
    resources: {}
  },
  'msp-pdf-v1': {
    serviceName: "PDF v1",
    resources: {}
  },
  'msp-pdf-v2': {
    serviceName: "PDF v2",
    resources: {}
  },
  'msp-phone-verification-v1': {
    serviceName: "Phone Verification v1",
    resources: {}
  },
  'msp-phone-verification-v2': {
    serviceName: "Phone Verification v2",
    resources: {}
  },
  'msp-profile': {
    serviceName: "Profile",
    resources: {}
  },
  'msp-related-products-v1': {
    serviceName: "Related Products v1",
    resources: {}
  },
  'msp-related-products-v2': {
    serviceName: "Related Products v2",
    resources: {}
  },
  'msp-shopping-cart-v1': {
    serviceName: "Shopping Cart",
    resources: {}
  },
  'msp-shortener-v1': {
    serviceName: "Shortener v1",
    resources: {}
  },
  'msp-shortener-v2': {
    serviceName: "Shortener v2",
    resources: {}
  },
  'msp-trusted-data-v1': {
    serviceName: "Trusted Data",
    resources: {}
  },
  'msp-voucher-v1': {
    serviceName: "Voucher",
    resources: {}
  },
  'msp-wishlist-v1': {
    serviceName: "Wishlist",
    resources: {}
  },
  'msp-mobile-pass': {
    serviceName: "Mobile Pass",
    resources: {}
  },
  'msp-fraud-detector-v1': {
    serviceName: "Fraud Detector",
    resources: {}
  },
  'msp-sdn-lookup': {
    serviceName: "SDN Lookup",
    resources: {}
  }
};

const MspServiceUsagePage = ({ userContext = {} }) => {

  const {
    setActiveSideNavbarLabel,
    setBodyTitle,
    setBreadcrumbs,
    setSideNavServiceConfig
  } = useOutletContext();

  useEffect(() => {
    setBodyTitle(false);
    setSideNavServiceConfig(false);
    setActiveSideNavbarLabel("Service Usage");
    setBreadcrumbs([
      { link: "/msp-admin/home", label: "MSP Admin" },
      { label: "Service Usage" }
    ]);
  }, [setBodyTitle, setActiveSideNavbarLabel, setBreadcrumbs, setSideNavServiceConfig]);

  return (
    <MspPageLayout>
      <ForgeCard>
        <h2 className="forge-typography--headline4">Service Usage</h2>
        <p className="forge-typography--body1">
          Your tenant's monthly service usage is displayed below.
        </p>
        <MonthlyServiceUsage userContext={userContext} />
      </ForgeCard>
    </MspPageLayout>
  )
};

const MonthlyServiceUsage = ({ userContext }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [serviceUsage, setServiceUsage] = useState({});
  const [hasFetchError, setHasFetchError] = useState(false);
  const [serviceUsageDate, setServiceUsageDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });
  const [currentMonthSelected, setCurrentMonthSelected] = useState(true);

  useEffect(() => {
    const currentUsageDate = {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    };
    if ((currentUsageDate.month === serviceUsageDate.month)
      && (currentUsageDate.year === serviceUsageDate.year)) {
      setCurrentMonthSelected(true);
    } else {
      setCurrentMonthSelected(false);
    }
  }, [serviceUsageDate]);

  const fetchServiceUsageForSelectedDate = useCallback(async () => {
    setIsLoading(true);
    setHasFetchError(false);
    setServiceUsage({});
    const tenant = userContext.tenantId;
    const cacheKey = `msp-admin-service-usage-${tenant}-${serviceUsageDate.month}-${serviceUsageDate.year}`;
    const cachedUsage = await Cache.getItem(cacheKey);
    if (!cachedUsage) {
      const response = await API.get("service_analytics", `/monthly-usage/${tenant}`, {
        queryStringParameters: {
          month: serviceUsageDate.month,
          year: serviceUsageDate.year
        }
      });
      const summedByService = [].concat(response?.usage).reduce((previous, current) => {
        const serviceName = current.service;
        let existingService = previous[serviceName] || {
          service: serviceName,
          totalRequests: 0,
          usage: []
        };
        existingService.usage = existingService.usage.concat({
          resource: current.resource,
          requestCount: current.request_count
        });
        existingService.totalRequests += current.request_count;
        previous[current.service] = existingService;

        return previous;
      }, {});
      Cache.setItem(cacheKey, summedByService);
      setServiceUsage(summedByService);
    } else {
      setServiceUsage(cachedUsage);
    }
    setIsLoading(false);
  }, [userContext, serviceUsageDate]);

  const handleChangeMonth = async (e) => {
    e.preventDefault();
    setServiceUsageDate({
      month: e.detail.month,
      year: e.detail.year
    });
  };

  useEffect(() => {
    const fetchServiceUsage = async () => {
      try {
        await fetchServiceUsageForSelectedDate();
      } catch (error) {
        console.error("There was an issue retrieving service usage", error);
        setHasFetchError(true);
      }
    };

    fetchServiceUsage();
  }, [fetchServiceUsageForSelectedDate]);

  return (
    <div className="msp-admin-service-usage">
      <UsageDateSelect
        selectedValue={serviceUsageDate}
        handleChange={handleChangeMonth}
        isLoading={isLoading}
      />
      {
        !isLoading && currentMonthSelected && (
          <>
            <div className="msp-admin-spacer-small" />
            <InlineInfoMessage
              message="Usage for the current month is delayed by one day."
            />
          </>
        )
      }
      <div className="msp-admin-spacer-small" />
      <div className="msp-admin-service-usage-display">
        <div style={{ "width": "100%" }}>
          {
            isLoading ? (
              <>
                <MonthlyUsageSummarySkeleton />
                <div className="msp-admin-spacer-small" />
                <MonthlyUsageDetailsSkeleton />
              </>
            ) : (
              hasFetchError ? (
                <GenericServerErrorPageState />
              ) : (
                [].concat(Object.values(serviceUsage)).length > 0 ? (
                  <div>
                    <MonthlyUsageSummary serviceUsage={serviceUsage} />
                    <div className="msp-admin-spacer" />
                    <MonthlyUsageDetails serviceUsage={serviceUsage} />
                  </div>
                ) : (
                  <ResultsNotFoundPageState
                    title="Sorry, no usage found for that month."
                    message="Please select another month."
                  />
                )
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

const UsageDateSelect = ({ selectedValue, handleChange, isLoading }) => {

  const [options, setOptions] = useState([]);

  const createOptions = useCallback(() => {
    const FIRST_MONTH = new Date("2020-10");
    const dateTimeFormat = new Intl.DateTimeFormat("en-US", { month: "long" });
    const monthOptions = [];

    for (let d = new Date(); d > FIRST_MONTH; d.setMonth(d.getMonth() - 1)) {
      const monthName = dateTimeFormat.format(d);
      monthOptions.push({
        value: {
          month: d.getMonth() + 1,
          year: d.getFullYear()
        },
        label: `${monthName} ${d.getFullYear()}`
      });
    }

    return monthOptions;
  }, []);

  useEffect(() => {
    const monthOptions = createOptions();
    setOptions(monthOptions);
  }, [createOptions]);

  return (
    <>
      <ForgeSelect
        className="msp-admin-service-usage-month-select"
        value={selectedValue}
        label="Date"
        on-change={handleChange}
        disabled={isLoading}
        density="roomy"
      >
        {
          [].concat(options).map((x, i) => (
            <ForgeOption key={`usage-select-${i}`} value={x.value}>{x.label}</ForgeOption>
          ))
        }
      </ForgeSelect>
      <ForgeTooltip
        delay={1000}
        position="below"
      >
        Change the date to view usage for the selected month.
      </ForgeTooltip>
    </>
  )
};

const MonthlyUsageSummarySkeleton = ({ loadingCardsSize = 3 }) => (
  <div>
    <ForgeSkeleton text style={{ "width": "10rem", "height": "2rem" }} />
    <div className="msp-admin-service-usage-summary-metric-group">
      {
        [...Array(loadingCardsSize)].map((x, i) => (
          <MetricCardSkeleton
            key={`msp-usage-summary-skeleton-metric-card-${i}`}
          />
        ))
      }
    </div>
  </div>
);

const MonthlyUsageDetailsSkeleton = () => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <ForgeSkeleton text style={{ "width": "10rem", "height": "2rem" }} />
    <div style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
      <ForgeSkeleton text style={{ "width": "5rem", "height": "2rem", marginRight: "0.2rem" }} />
      <ForgeSkeleton text style={{ "width": "5rem", "height": "2rem", marginRight: "2rem" }} />
      <ForgeSkeleton text style={{ "width": "5rem", "height": "2rem", }} />
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {
        [].concat(...Array(5)).map((x, i) => (
          <ForgeSkeleton
            style={{ width: "75%", height: "2rem" }}
            key={`service-usage-detail-list-skeleton-${i}`}
            listItem
          />
        ))
      }
    </div>
  </div>
);

const MonthlyUsageSummary = ({ serviceUsage = {} }) => {

  const [usageSummary, setUsageSummary] = useState({});

  useEffect(() => {
    if (serviceUsage) {
      const summary = {
        totalRequests: 0,
        resourcesUsed: 0,
        servicesUsed: 0
      };

      [].concat(Object.values(serviceUsage)).forEach(x => {
        summary.totalRequests += x.totalRequests
        summary.resourcesUsed += x.usage.length;
      });

      summary.servicesUsed = [].concat(Object.values(serviceUsage)).length;
      setUsageSummary(summary);
    }
  }, [serviceUsage]);

  return (
    <div>
      <h3 className="forge-typography--headline5">
        Summary
      </h3>
      <div className="msp-admin-service-usage-summary-metric-group">
        <MetricCard
          name="Total Requests"
          metricValue={usageSummary?.totalRequests?.toLocaleString()}
          tooltip="Total number of requests to all services for the month"
        >
        </MetricCard>
        <MetricCard
          name="Services Used"
          metricValue={usageSummary?.servicesUsed?.toLocaleString()}
          tooltip="Total number of services used this month"
        >
        </MetricCard>
        <MetricCard
          name="Resources Used"
          metricValue={usageSummary?.resourcesUsed?.toLocaleString()}
          tooltip="Total number of resources used this month"
        >
        </MetricCard>
      </div>
    </div>
  );
};

const MonthlyUsageDetails = ({ serviceUsage = {} }) => {

  const [detailPanelsExpanded, setDetailPanelsExpanded] = useState(false);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [sortType, setSortType] = useState("totalRequests");

  useEffect(() => {
    const services =
      [].concat(Object.values(serviceUsage))
        .sort((a, b) => (sortType === "service") ? (
          b.service - a.service
        ) : (
          b.totalRequests - a.totalRequests
        ));
    setServiceDetails(services);
  }, [sortType, serviceUsage]);

  const toggleExpandCloseDetailPanels = () => {
    setDetailPanelsExpanded(!detailPanelsExpanded);
  };

  const formatServiceName = (serviceId) => {
    const format = serviceDisplayFormatConfig[serviceId];
    return format ? format.serviceName :
      ("" + serviceId).replace("msp-", "");
  };

  return (
    <div style={{ "display": "flex", "flexDirection": "column" }}>
      <h3 className="forge-typography--headline5">
        Details
      </h3>
      <div style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
        <SortDetailPanelsButtons
          sortType={sortType}
          setSortType={setSortType}
        />
        <ExpandCloseDetailPanelsButton
          buttonAction={toggleExpandCloseDetailPanels}
          panelsExpanded={detailPanelsExpanded}
        />
      </div>

      <div style={{ "display": "flex", "flexDirection": "column", "alignItems": "center" }}>
        <ForgeList className="msp-admin-service-usage-detail-service-list">
          {
            serviceDetails.map((x, i) => (
              <div key={`service-usage-item-service-${i}`}>
                <ForgeExpansionPanel className="msp-admin-service-usage-service-detail-panel" open={detailPanelsExpanded}>
                  <ForgeListItem slot="header">
                    <span className="forge-typography--headline5">
                      {formatServiceName(x?.service)}
                    </span>
                    <span className="forge-typography--headline5 msp-admin-service-usage-detail-service-list-item-total" slot="trailing">
                      {x?.totalRequests?.toLocaleString()}
                    </span>
                    <ForgeTooltip>Total requests for this service</ForgeTooltip>
                    <ForgeOpenIcon slot="trailing"></ForgeOpenIcon>
                  </ForgeListItem>
                  <ServiceUsageTable data={x.usage} serviceId={x.service} />
                </ForgeExpansionPanel>
                {
                  serviceDetails.length !== (i + 1) && <ForgeDivider />
                }
              </div>
            ))
          }
        </ForgeList>
      </div>
    </div>
  );
};

const SortDetailPanelsButtons = ({
  sortType,
  setSortType
}) => {

  const updateSortType = (selectedSortType) => {
    if (selectedSortType !== sortType) {
      setSortType(selectedSortType);
    }
  };

  return (
    <ForgeLabelValue align="center">
      <span slot="label">Sort By</span>
      <div slot="value">
        <div>
          <ForgeButton
            type={sortType === "totalRequests" ? "raised" : "outlined"}
            id="msp-admin-usage-detail-toggle-sort-totalRequests"
          >
            <button type="button" onClick={() => updateSortType("totalRequests")}>
              Requests
            </button>
          </ForgeButton>
          <ForgeButton
            type={sortType === "service" ? "raised" : "outlined"}
            id="msp-admin-usage-detail-toggle-sort-service"
          >
            <button type="button" onClick={() => updateSortType("service")}>
              Service
            </button>
          </ForgeButton>
        </div>
      </div>
    </ForgeLabelValue>
  );
};

const ExpandCloseDetailPanelsButton = ({
  panelsExpanded,
  buttonAction
}) => {
  const [buttonLabel, setButtonLabel] = useState();

  useEffect(() => {
    setButtonLabel(panelsExpanded ? "Close" : "Expand");
  }, [panelsExpanded]);

  return (
    <>
      <ForgeButton
        type="outlined"
        className="msp-admin-service-usage-expand-button"
      >
        <button
          type="button"
          aria-label={`${buttonLabel} all detailed service usage results`}
          onClick={buttonAction}
        >
          {`${buttonLabel} All`}
        </button>
      </ForgeButton>
      <ForgeTooltip>
        {`${buttonLabel} all detailed service usage results`}
      </ForgeTooltip>
    </>
  );
};

const ServiceUsageTable = ({ data = [], serviceId }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const sorted = sort({
      propertyName: "requestCount",
      direction: "DESC",
      data
    });
    setTableData(sorted);
  }, [data]);

  const columnConfigurations = [
    {
      property: "resource",
      header: "Resource",
      sortable: true,
      width: '80%',
      transform: (e) => {
        const displayFormat = serviceDisplayFormatConfig[serviceId];
        return displayFormat?.resources[e] || e;
      }
    },
    {
      property: "requestCount",
      header: "Requests",
      sortable: true,
      width: '20%',
      initialSort: true,
      transform: (e) => e.toLocaleString()
    },
  ];

  const sort = ({ propertyName, direction = "DESC", data = [] }) => (
    [].concat(data).sort((a, b) => {
      const aProp = '' + a[propertyName];
      const bProp = '' + b[propertyName];
      return direction === 'DESC' ? (
        bProp.localeCompare(aProp, undefined, { numeric: true })
      ) : (
        aProp.localeCompare(bProp, undefined, { numeric: true })
      );
    })
  )

  const handleTableSort = (e) => {
    const propertyName = columnConfigurations[e.detail.columnIndex].property;
    const direction = e.detail.direction;
    if (propertyName) {
      const sorted = sort({
        propertyName,
        direction,
        data: tableData
      });
      setTableData(sorted);
    }
  };

  return (
    <div
      style={{
        overflow: 'scroll',
        maxHeight: '20rem',
        marginBottom: '2rem',
        padding: '2rem 1rem'
      }}
    >
      <ForgeTable
        className="msp-admin-service-usage-table"
        columnConfigurations={columnConfigurations}
        data={tableData}
        on-forge-table-sort={handleTableSort}
      />
    </div>
  );
};

export default MspServiceUsagePage;
