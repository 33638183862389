import React from 'react';

import { ForgeCircularProgress, ForgeButton } from '@tylertech/forge-react';

const LoadingButton = ({
    isLoading = true,
    text = "Submit",
    loadingText = "Loading...",
    disabled = false,
    showSpinner = true,
    buttonType = "raised",
    className= "",
    ...props }) => (
    <ForgeButton className={`nic-console-loading-button ${className}`} type={buttonType}>
        <button className={className} disabled={disabled || isLoading} {...props}>
            <span>
                {!isLoading ? text : `${loadingText}`}

                {showSpinner && isLoading && <ForgeCircularProgress diameter="25" strokeWidth="8" />}
            </span>
        </button>
    </ForgeButton>
);

export default LoadingButton;
