import { Link } from 'react-router-dom';

import { ForgeBadge, ForgeChip, ForgeTooltip } from '@tylertech/forge-react';

export const productTags = [
    {
        id: "payments",
        color: "#33691e",
        text: "Payments",
        "description": "Activate flexible and secure payments across payment types and channels."
    },
    {
        id: "data-analytics",
        color: "#26a69a",
        text: "Data Analytics",
        description: "Validate digital performance and deliver meaningful data to inform government decision-making.",
    },
    {
        id: "identity",
        color: "#8e24aa",
        text: "Identity",
        description: "Leverage ID validation as the natural connecting point to deliver centralized citizen services at scale.",
    },
    {
        id: "foundation",
        color: "#1a237e",
        text: "Foundation",
        description: "The essential components that power the flexible digital government ecosystem.",
    },
    {
        id: "citizen-engagement",
        color: "#039be5",
        text: "Citizen Engagement",
        description: "Empower citizens with easy-to-access transactions, stored payments, reminders, and notifications.",
    },
    {
        id: "code-service-builder",
        color: "#d84315",
        text: "Code & Service Builder",
        description: "Rapidly build new services and integrate existing solutions and forms.",
    },
];

const ProductTagBadge = ({ color = "#283593", text = "" }) => (
    <ForgeBadge
        className="nic-console-product-tag"
        style={{ "--forge-badge-theme-default-strong-background": color }}
        strong={true}
    >
        {text}
    </ForgeBadge>
);

const ProductTag = ({ tag = "" }) => {
    const productTagProperties = productTags.find(x => x.id === tag);
    return productTagProperties ? (
        <>
            <ProductTagBadge color={productTagProperties.color} text={productTagProperties.text} />
            <ForgeTooltip>{productTagProperties.description}</ForgeTooltip>
        </>
    ) : (
        <ProductTagBadge text={tag} />
    )
};

export const ProductTagBadgeLinks = ({ tags = [] }) => (
    [].concat(tags).map((x, i) => {
        return (
            <Link to={`/product-catalog?tag=${encodeURIComponent(x)}`} key={`product-tag-${i}`}>
                <ProductTag tag={x} />
            </Link>
        );
    })
);

export const ProductTagChips = ({ selectedTag }) => {
    return (
        productTags.map((x, i) => {
            return <ForgeChip key={`product-tag-chip-${i}`} value={x.id} selected={selectedTag === x.id} >{x.text}</ForgeChip>
        })
    )
}
