import { Link, useLocation, useOutletContext } from "react-router-dom";

import { ForgeCard, ForgePageState, ForgeToolbar } from "@tylertech/forge-react";

import ConsolePageLayout from "../ConsolePageLayout";
import { useEffect } from "react";

const consoleBaseUrl = process.env.REACT_APP_CONSOLE_BASE_URL || "http://localhost:3000";

const ConsoleSearchResultsPage = () => {
    const location = useLocation();
    const { setBodyTitle, setBreadcrumbs, setHideBackground } = useOutletContext();
    const { searchResults, searchText } = location.state;

    useEffect(() => {
        setBodyTitle(false);
        setBreadcrumbs(false);
        setHideBackground(searchResults.length === 0);
    }, [setBodyTitle, setBreadcrumbs, setHideBackground, searchResults])

    return (
        <>
            {
                searchResults.length > 0 ? (
                    <ConsolePageLayout>
                        <div>
                            <div>
                                <h2 className="forge-typography--headline5">Search Results for: "{searchText}"</h2>
                            </div>
                            <div>
                                {
                                    searchResults.map((i, x) => (
                                        <ForgeCard className="msp-admin-card" key={`search-result-${i}`}>
                                            <ForgeToolbar slot="start">
                                                <h3 slot="start" className="forge-typography--subtitle1">{x.label}</h3>
                                            </ForgeToolbar>
                                            <div slot="body">
                                                <Link to={x.value}>{`${consoleBaseUrl}${x.value}`}</Link>
                                                <p className="forge-typography--body1">{x.description}</p>
                                            </div>
                                        </ForgeCard>
                                    ))
                                }
                            </div>
                        </div>
                    </ConsolePageLayout>
                ) : (
                    <div>
                        <ForgePageState>
                            <img src="https://cdn.forge.tylertech.com/v1/images/spot-hero/no-search-results-spot-hero.svg" alt="Not found" slot="graphic" />
                            <div slot="title">No results found.</div>
                            <div slot="message">No results were found for your search. Try again.</div>
                        </ForgePageState>
                    </div>
                )
            }
        </>
    )
}

export default ConsoleSearchResultsPage;
